import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import { Button, Input, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import CategoryService from 'src/services/categoryService'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import styled from 'styled-components'
import useSWR from 'swr'
import BodyContainer from '../other/bodyContainer'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import useIsAdmin from '../hooks/useIsAdmin'

const columns: newColumnsInterface[] = [
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Count',
    dataIndex: 'bookCount',
  },
]

const categoryService = new CategoryService()

interface TableProps {
  renderCreateButton: () => React.ReactNode
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  onRefresh?: () => void
}

export const categoryTableReqKey = `category-req`

export const SearchContainter = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CategoryTable = (props: TableProps) => {
  const { onRefresh } = props
  const isAdmin = useIsAdmin()
  const { renderOwnActionMenu, refetchRef } = props
  const { data, error, mutate } = useSWR<PaginationDataReponse<any[]>, any>(
    swrKeys.bookCategory(),
    null,
    getSwrDefaultConfig(),
  )

  const [showTrashed, setShowTrashed] = useState(false)

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await categoryService.getTrashed(reqParams)
          : await categoryService.getAll(reqParams)

        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [showTrashed, reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [showTrashed, reqParams])

  const renderTrashedTableActionMenu = useCallback(
    (record: any) => {
      let menus: TableActionMenuProps['menus'] = [
        {
          render: () => {
            return <div>Restore</div>
          },
          key: 'Restore',
          onClick: async () => {
            try {
              await categoryService.restore(record.record[getIdentifier()])
              refreshAction()
            } catch (e) {
              handleError(e)
            }
          },
        },
      ]

      return <TableActionMenu menus={menus} />
    },
    [showTrashed],
  )

  if (error) {
    return <div>error</div>
  }

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <BodyContainer style={{ padding: 20 }}>
      <TableSkeleton loading={!data}>
        <Table
          restProps={{
            rowKey: 'id',
            bordered: true,
            pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
              setReqParams({
                page,
                size: pageSize,
              })
            }),
          }}
          columnsVisibleControllerProps={{
            show: true,
          }}
          renderCreateButton={() => {
            return (
              <Space>
                {props.renderCreateButton()}
                <Button
                  onClick={() => {
                    refreshAction()
                  }}
                >
                  Refresh
                </Button>
              </Space>
            )
          }}
          newColumns={columns}
          newSources={data ? data.data : []}
          name="category-table"
          renderOwnActionMenu={
            !isAdmin
              ? null
              : (record) => {
                  return showTrashed
                    ? renderTrashedTableActionMenu(record)
                    : renderOwnActionMenu({ record, mutate, data: data.data })
                }
          }
          headerStyle={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          renderOwnSearchInput={() => {
            return <React.Fragment />
          }}
          // renderOwnSearchInput={() => (
          //   <SearchContainter>
          //     <Checkbox
          //       style={{ marginLeft: 8 }}
          //       onChange={async (e) => {
          //         const { checked } = e.target
          //         setShowTrashed(checked)
          //       }}
          //     >
          //       Trash
          //     </Checkbox>

          //     <Search
          //       enterButton
          //       style={{
          //         width: 350,
          //       }}
          //       loading={!data}
          //       onSearch={(value) => {
          //         setReqParams((old) => ({
          //           ...old,
          //           q: value,
          //         }))
          //       }}
          //     />
          //   </SearchContainter>
          // )}
        />
      </TableSkeleton>
    </BodyContainer>
  )
}

export default CategoryTable
