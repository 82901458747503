import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  DataResponse,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

const url = '/author'

class AuthorService implements BaseService<{}>, CRUDService<{}> {
  create(data: {}): Promise<void> {
    return getAxiosConfig().post(url, data)
  }

  update(args: { id: any; data: {} }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  changePassword(args: { id: any; data: {} }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/change-password/${id}`, data)
  }

  async updateUser(args: { data: {} }): Promise<DataResponse<any>>{
    const { data } = args
    const res = await getAxiosConfig().put(`user`, data)
    return res.data
  }

  delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }

  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  async authorGetAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(`book${url}`, {
      params,
    })
    return res.data as any
  }

  getTrashed(params?: PaginationRequestParams): Promise<PaginationDataReponse<{}>> {
    throw new Error('Method not implemented.')
  }
  restore(id: any): Promise<void> {
    throw new Error('Method not implemented.')
  }
}

export default AuthorService
