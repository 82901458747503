import React, { useState, useEffect } from 'react'
import { PhotoshopPicker, Color } from 'react-color'
import { Popover } from 'antd'
import styled from 'styled-components'
import './index.css'

interface ColorPickerProps {
  onAccept?: (value: string) => void
  value?: string
}

const DisplaySelectedColor = styled.div`
  border: 1px solid black;
  width: 10px;
  height: 10px;
  cursor: pointer;
`

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const { onAccept } = props
  const [value, setValue] = useState<Color>(props.value || '#000000')
  const [oldValue, setOldValue] = useState(value)
  const [pickerVisible, setPickerVisible] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
      setOldValue(props.value)
      setCurrentValue(props.value)
    }
  }, [props.value])

  return (
    <Popover
      overlayClassName={'customPopover'}
      content={
        <PhotoshopPicker
          color={currentValue}
          onChange={(value) => {
            setCurrentValue(value.hex)
          }}
          onAccept={() => {
            setOldValue(currentValue)
            setValue(currentValue)
            setPickerVisible(false)
            onAccept(currentValue as string)
          }}
          onCancel={() => {
            setValue(oldValue)
            setPickerVisible(false)
            setCurrentValue(oldValue)
          }}
        />
      }
      onOpenChange={(visible) => {
        setPickerVisible(visible)
      }}
      trigger="click"
      open={pickerVisible}
    >
      <DisplaySelectedColor
        style={{
          background: value as string,
        }}
      />
    </Popover>
  )
}

export default ColorPicker
