import { formatJSON } from '@/utils/check'
import { FileImageOutlined } from '@ant-design/icons'
import { DraggableModal } from 'ant-design-draggable-modal'
import { Button, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import ConfigurationService from 'src/services/configurationService'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import useSWR from 'swr'
import CodeEditor from '../input/CodeEditor'
import MountedModalProvider from '../modal/MountedModalProvider'
import BodyContainer from '../other/bodyContainer'

const configurationService = new ConfigurationService()

interface TableProps {
  renderCreateButton?: () => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
}

const ListConfiguration: React.FC<TableProps> = (props) => {
  const [recordValue, setRecordValue] = useState<any>(null)

  const columns: newColumnsInterface[] = [
    {
      dataIndex: 'id',
      title: 'Id',
    },
    {
      dataIndex: 'key',
      title: 'Key',
    },
    {
      dataIndex: 'value',
      title: 'Value',
      render: (_, data) => {
        return data.isMultiple ? (
          <>
            <Button
              onClick={() => {
                setRecordValue(data)
              }}
              icon={<FileImageOutlined />}
            />
          </>
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: data.value,
            }}
          />
        )
      },
    },
  ]
  const { renderCreateButton, refetchRef, renderOwnActionMenu } = props
  const { data: res, error, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.configuration(),
    null,
    getSwrDefaultConfig(),
  )

  const [reqParams, setReqParams] = useState<PaginationRequestParams>({
    page: 0,
    size: 10,
    sort: 'id,desc'
  })

  const [showTrashed, setShowTrashed] = useState(false)

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await configurationService.getTrashed(reqParams)
          : await configurationService.getAll(reqParams)

        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams, showTrashed])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [reqParams, showTrashed])

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        <DraggableModal
          open={Boolean(recordValue)}
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
          title={'Value'}
          footer={null}
          onCancel={() => {
            setRecordValue(null)
            // setFormData(null)
          }}
        >
          <CodeEditor
            value={formatJSON(JSON.stringify(recordValue?.value)) || ''}
            language={'json'}
            options={{
              automaticLayout: true,
              readOnly: true,
              minimap: {
                enabled: false,
              },
            }}
          />
        </DraggableModal>
      </MountedModalProvider>

      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!res}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(res?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  {renderCreateButton && renderCreateButton()}
                  <Button
                    onClick={() => {
                      refetch()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={res ? res.data : []}
            name="list-configration-table"
            renderOwnActionMenu={({ record }) => {
              return renderOwnActionMenu({ record, mutate, data: res.data })
            }}
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            renderOwnSearchInput={() => <React.Fragment />}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default ListConfiguration
