import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form, Select, InputNumber } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DraggableModalProps } from 'ant-design-draggable-modal'
import ModalFormContainer, { ModalFooter } from 'src/components/form/ModalForm'
import { Option } from 'antd/lib/mentions'
import { repeatFnOnObj } from '@/utils'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { PresetPriceDetailFormData } from 'src/services/presetPriceDetailService'

export interface PresetDurationDetail extends PresetPriceDetailFormData {
  id: string
}
export interface UpdatePresetDurationForm {
  name: string
  price: string
  duration: number
  durationType: string
  presetCode: any
}

export interface modalDurationInterface extends DraggableModalProps {
  onSubmit: (data: any) => Promise<void>
  formData: {
    name: string
    detail?: PresetDurationDetail[]
  }
}

export interface SubmitDuration {
  name: string
  detail: PresetDurationDetail[]
}

const DurationType = {
  type: ['Day', 'Week', 'Month', 'Year', 'Forever'],
}

const schema = yup.object().shape({
  name: yup.string().required('Book name is required'),
})

// const schemaDetail = yup.object().shape({
//   name: yup.string().required('Book name is required'),
//   duration: yup.string().required('Duration name is required'),
//   durationType: yup.string().required('DurationType name is required'),
//   price: yup.string().required('Price name is required'),
// })

const defaultValues = {
  name: '',
  detail: [
    {
      price: {
        value: 0,
        currency: 'USD',
      },
      duration: 0,
      durationType: 'Day',
      presetCode: '',
    },
  ],
}

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
}

const InputNumberStyle = styled.div`
  & > .ant-input-number {
    width: 100%;
  }
`

const DurationFormModel: React.FC<modalDurationInterface> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const [dataDetail, setDataDetail] = useState(formData.detail || [])

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<UpdatePresetDurationForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData.detail, dataDetail])

  const removeData = (record: any) => {
    const temp = dataDetail.filter((item) => {
      return item.id !== record.id
    })
    setDataDetail(temp)
  }

  const columnsDetail: newColumnsInterface[] = [
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (_, record) => record.duration + ' ' + record.durationType,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (value) => {
        return value.value + ' ' + value.currency
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (record) => {
        return (
          <Space size="middle">
            <a onClick={() => removeData(record)}>Delete</a>
          </Space>
        )
      },
    },
  ]

  return (
    <ModalFormContainer
      {...rest}
      initialWidth={1200}
      initialHeight={800}
      footer={null}
    >
      <Form {...layout}>
        <Form.Item
          label="Book Name"
          validateStatus={errors.name && 'error'}
          help={errors.name ? errors.name.message : ''}
          requiredMark={true}
        >
          <Controller render={() => <Input />} name="name" control={control} />
        </Form.Item>

        <Form.Item
          label="Duration"
          validateStatus={errors.duration && 'error'}
          help={errors.duration ? errors.duration.message : ''}
          requiredMark={true}
        >
          <InputNumberStyle>
            <Controller
              render={() => <InputNumber />}
              name="duration"
              control={control}
              rules={{ required: true }}
            />
          </InputNumberStyle>
        </Form.Item>

        <Form.Item
          label="Duration Type"
          validateStatus={errors.durationType && 'error'}
          help={errors.durationType ? errors.durationType.message : ''}
          requiredMark={true}
        >
          <Controller
            control={control}
            name="durationType"
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={onChange}
                value={value}
                placeholder="Please Select Duration"
              >
                {DurationType.type.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>

        <Form.Item
          label="Price"
          validateStatus={errors.price && 'error'}
          help={errors.price ? errors.price.message : ''}
          requiredMark={true}
        >
          <InputNumberStyle>
            <Controller
              render={() => <InputNumber />}
              name="price"
              control={control}
            />
          </InputNumberStyle>
        </Form.Item>
        <Button
          type={'primary'}
          onClick={async () => {
            const result = await trigger(['duration', 'durationType', 'price'])
            if (result) {
              const formValue = getValues()
              const parsedPresetDetail: PresetDurationDetail = {
                id: uuid(),
                duration: formValue.duration,
                durationType: formValue.durationType,
                price: {
                  value: parseFloat(formValue.price),
                  currency: 'USD',
                },
                // presetCode: formValue.presetCode,
              }
              setDataDetail((old) => {
                return [...old, parsedPresetDetail]
              })

              repeatFnOnObj(
                {
                  durationType: '',
                  duration: '',
                  price: '',
                },
                ({ key, value }) => setValue(key as any, value),
              )()
            }
          }}
        >
          Add Duration
        </Button>
        <TableSkeleton loading={!dataDetail}>
          <Table
            newColumns={columnsDetail}
            newSources={dataDetail ? dataDetail : []}
            name="duration-table"
            headerStyle={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          />
        </TableSkeleton>

        <div style={{ paddingBottom: 50 }} />

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                const formValue: SubmitDuration = {
                  name: data.name,
                  detail: dataDetail,
                }
                await onSubmit(formValue)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default DurationFormModel
