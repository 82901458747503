import { DashboardStats } from '@/components'
import { getIdentifier } from '@/utils'
import { UserOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { useRef, useState } from 'react'
import ChangeCustomerPasswordForm from 'src/components/customer/ChangeCustomerPasswordFormModal'
import CustomerTable from 'src/components/customer/UserTable'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import TableActionMenu, {
  TableActionMenuProps,
} from 'src/components/table/TableActionMenu'
import UserService from 'src/services/userService'
import handleError from 'src/utils/errorHelper'

const userService = new UserService()

const Index = () => {
  const refetchRef = useRef<Function>()

  const renderActionMenu = (args: any) => {
    const {
      record: { record },
    } = args
    const identifier = record.code
    const username = record.username

    let menus: TableActionMenuProps['menus'] = [
      {
        key: 'reset-password',
        render: () => {
          return <div>Change Password</div>
        },
        onClick: () => {
          setUsernameToChangePassword(username)
        },
      },
      {
        key: 'delete',
        render: () => {
          return (
            <Popconfirm
              title="Delete this user?"
              onConfirm={async () => {
                try {
                  console.log('Delete user by identifier=>', identifier)
                  await userService.delete(identifier)
                  refetchRef.current()
                } catch (e) {
                  handleError(e)
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              Delete User
            </Popconfirm>
          )
        },
      },
    ]
    return <TableActionMenu menus={menus} />
  }

  const [usernameToChangePassword, setUsernameToChangePassword] = useState<
    null | string
  >(null)

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        <ChangeCustomerPasswordForm
          title={'Reset User Password'}
          open={Boolean(usernameToChangePassword)}
          onSubmit={async (data) => {
            const { password } = data
            try {
              await userService.resetPassword({
                password,
                username: usernameToChangePassword,
              })
              setUsernameToChangePassword(null)
            } catch (e) {
              handleError(e)
            }
          }}
          onCancel={() => {
            setUsernameToChangePassword(null)
          }}
        />
      </MountedModalProvider>

      <DashboardStats
        service={userService}
        defaultProps={{
          prefix: <UserOutlined />,
        }}
      />

      <br />

      <CustomerTable
        refetchRef={refetchRef}
        renderCreateButton={() => null}
        renderOwnActionMenu={renderActionMenu}
      />
    </>
  )
}

export default withChangeHeader(Index, 'Customer Management')
