import { defaultPaginationRequestParams } from '@/constants'
import NotificationService from '@/services/notificationService'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import handleError from '@/utils/errorHelper'
import { getPaginationProps } from '@/utils/tableHelper'
import Table, {
  TableSkeleton,
  newColumnsInterface,
} from '@cubetiq/enhance-antd-table'
import { Space } from 'antd'
import Search from 'antd/es/input/Search'
import React, { useCallback, useEffect, useState } from 'react'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import useSWR from 'swr'
import { SearchContainter } from '../book/CategoryTable'
import BodyContainer from '../other/bodyContainer'
import Button from '../shared/Button'

interface TableProps {
  renderCreateButton?: () => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  onRefresh?: () => void
}

// Column of table
const columns: newColumnsInterface[] = [
  {
    dataIndex: 'date',
    title: 'Date',
  },
  {
    dataIndex: 'topic',
    title: 'User',
  },
  {
    dataIndex: 'notificationType',
    title: 'Type',
  },
  {
    dataIndex: 'description',
    title: 'Description',
  },
]

const service = new NotificationService()
const NotificationOfferTable: React.FC<TableProps> = (props) => {
  const { onRefresh } = props

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )
  const { renderCreateButton, refetchRef, renderOwnActionMenu } = props

  const { data, error, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.notificationOffer(),
    null,
    getSwrDefaultConfig(),
  )

  // Fetch data
  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        const res = await service.getAllOffer(reqParams)

        res.data && mutate(res)
      } catch (error) {
        handleError(error)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [reqParams])

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}

            // Button
            renderCreateButton={() => {
              return (
                <Space>
                  {/* Create */}
                  {renderCreateButton && renderCreateButton()}

                  {/* Refresh */}
                  <Button
                    onClick={() => {
                      refreshAction()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}

            // Columns
            newColumns={columns}
            newSources={data ? data.data : []}
            name="notification-offer-table"
            renderOwnActionMenu={({ record }) => {
              return renderOwnActionMenu({ record, mutate, data: data.data })
            }}
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            renderOwnSearchInput={() => (
              <SearchContainter style={{
                justifyContent: 'flex-end',
                paddingLeft: 10,
              }}>
                <Search
                  placeholder="Search Description"
                  enterButton
                  style={{
                    maxWidth: 350,
                    minWidth: 200,
                  }}
                  loading={!data}
                  onSearch={(value) => {
                    setReqParams((old) => ({
                      ...old,
                      q: value,
                    }))
                  }}
                />
              </SearchContainter>
            )}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default NotificationOfferTable
