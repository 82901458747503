import { DraggableModalProps } from 'ant-design-draggable-modal'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form, Select, Table } from 'antd'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import useSWR from 'swr'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import swrKeys from 'src/constants/swrKey'
import OtherService, { EnumType } from 'src/services/otherService'
import styled from 'styled-components'
import UploadComponent from '../other/UploadAvatar'

interface FormData {
  name: string
  paymentType: string
  image?: string
  accountNumber: string
  accountName: string
  link: string
}

export interface modalPaymentInterface extends DraggableModalProps {
  onSubmit: (data: FormData) => Promise<void>
  formData: FormData
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  accountNumber: yup.string().required('Account number is required'),
  accountName: yup.string().required('Account name is required'),
  paymentType: yup.string().required('Payment type is required'),
})

const defaultValues: FormData = {
  name: '',
  paymentType: '',
  accountNumber: '',
  accountName: '',
  link: '',
}

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`

const { Option } = Select
const otherService = new OtherService()
const PaymentMethodFormModal: React.FC<modalPaymentInterface> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  const { data: paymentMethodTypeOptions } = useSWR<any, any>(
    swrKeys.paymentMethodTypeEnum(),
    async () => {
      const res = await otherService.getEnum({
        type: EnumType.PAYMENT_METHOD_TYPE,
      })

      return res.data
    },
    getSwrDefaultConfig(),
  )

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={500}
      initialWidth={450}
      footer={null}
    >
      <StyledForm>
        <Form.Item
          label="Name"
          validateStatus={errors.name && 'error'}
          help={errors.name ? errors.name.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="name"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Type"
          validateStatus={errors.paymentType && 'error'}
          help={errors.paymentType ? errors.paymentType.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, ...props } }) => {
              return (
                <Select
                  onChange={(value) => {
                    onChange(value)
                  }}
                  {...props}
                  style={{ width: '100%' }}
                >
                  {paymentMethodTypeOptions &&
                    paymentMethodTypeOptions.map((item: any) => {
                      return (
                        <Option key={item[getIdentifier()]} value={item.value}>
                          {item.name}
                        </Option>
                      )
                    })}
                </Select>
              )
            }}
            name="paymentType"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Account Number"
          validateStatus={errors.accountNumber && 'error'}
          help={errors.accountNumber ? errors.accountNumber.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="accountNumber"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Account Name"
          validateStatus={errors.accountName && 'error'}
          help={errors.accountName ? errors.accountName.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="accountName"
            control={control}
          />
        </Form.Item>

        <Form.Item
          style={{ gridColumn: '1 / span 2' }}
          label="Link"
          validateStatus={errors.link && 'error'}
          help={errors.link ? errors.link.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="link"
            control={control}
          />
        </Form.Item>

        <Controller
          name="image"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <UploadComponent
                imageUrl={value}
                onRemove={() => {
                  onChange()
                }}
                onUpload={(file) => {
                  onChange(file.url)
                }}
              />
            )
          }}
        />

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </StyledForm>
    </ModalFormContainer>
  )
}

export default PaymentMethodFormModal
