import React, { useEffect, useMemo } from 'react'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { Button, Input, Space, Form, Select } from 'antd'
import { DraggableModalProps } from 'ant-design-draggable-modal'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ModalFormContainer, { ModalFooter } from 'src/components/form/ModalForm'
import UploadComponent from '../other/UploadAvatar'
import styled from 'styled-components'
import useSWR from 'swr'
import { getSwrDefaultConfig } from '@/utils'
import swrKeys from 'src/constants/swrKey'
import EnumService, { EnumType } from 'src/services/enumService'
import handleError from 'src/utils/errorHelper'
import TextEditor from '../other/TextEditor'
import AutoComplete from '../other/AutoComplete'
import { DeleteOutlined } from '@ant-design/icons'
import ConfigModal from '../other/configModal'

export interface modalProfileAuthorInterface extends DraggableModalProps {
  onSubmit: (data: any) => Promise<void>
  formData: {
    name: string
    configs?: string
  }
  isCreate?: boolean
}

const { Option } = Select

const bioLength = 100
const memoLength = 500

interface FormData {
  name: string
  bio: string
  email: string | null
  phone: string

  image?: string
  socialLinks: any[]
  salutation: string
  memo: string
  cover?: string
  configs?: string
}

const defaultValues: FormData = {
  name: '',
  bio: '',
  email: null,
  phone: '',
  image: null,
  socialLinks: [],
  salutation: '',
  memo: '',
  configs: `{
    "title": "",
    "description": "",
    "og:type": "website",
    "og:url": "",
    "og:title": "",
    "og:description": "",
    "og:image": "",
    "og:imageWidth": "",
    "og:imageHeight": "",
    "twitter:card": "",
    "twitter:url": "",
    "twitter:title": "",
    "twitter:description": "",
    "twitter:image": "",
    "site_name": ""
  }`,
}

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const StyledSpace = styled(Space)`
  width: 100%;
  align-items: flex-start;
  & > * {
    width: 100%;
  }
`

const SalutationSpace = styled(StyledSpace)`
  & > *:first-child {
    width: 150px;
  }
`

const enumService = new EnumService()

const SocialLinksSpace = styled(StyledSpace)`
  & > *:last-child {
    width: min-content;
  }
`

const AuthorFormModel: React.FC<modalProfileAuthorInterface> = (props) => {
  const { onSubmit, onOk, formData, isCreate, ...rest } = props

  const schema = useMemo(() => {
    let result: any = {
      name: yup.string().required('Author name is required'),
      email: yup.string().email('Invalid Email').nullable(),
      memo: yup
        .string()
        .max(memoLength, `Must be equal or less than ${memoLength} characters.`),
      bio: yup
        .string()
        .max(bioLength, `Must be equal or less than ${bioLength} characters.`),
      socialLinks: yup.array().of(
        yup.object().shape({
          type: yup.string().required('Type is Required'),
          link: yup.string().required('Link is Required'),
        }),
      ),
    }

    return yup.object().shape(result)
  }, [isCreate])
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const {
    fields: fieldSocialLinks,
    append: appendSocialLink,
    remove: removeSocialLink,
  } = useFieldArray({
    name: 'socialLinks',
    control,
  })

  const { data: salutationOptions = [], error } = useSWR<any, any>(
    swrKeys.salutation(),
    async () => {
      try {
        let res: any = await enumService.getByType(EnumType.SALUTATION)
        return res.data
      } catch (e) {
        handleError(e)
      }
    },
    getSwrDefaultConfig(),
  )

  useEffect(() => {
    if (formData) {
      reset({
        ...defaultValues,
        ...formData,
      })
    }
  }, [formData])

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={550}
      initialWidth={550}
      footer={null}
    >
      <Form {...layout}>
        <Form.Item
          label="Profile"
          validateStatus={errors.image && 'error'}
          help={<>{errors.image ? errors.image.message : ''}</>}
          requiredMark={true}
        >
          <Controller
            name={'image'}
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => {
              return (
                <UploadComponent
                  imageUrl={value}
                  onRemove={() => {
                    onChange()
                  }}
                  onUpload={(file) => {
                    onChange(file.url)
                  }}
                />
              )
            }}
          />
        </Form.Item>

        <SalutationSpace>
          <Form.Item
            label="Salutation"
            validateStatus={errors.salutation && 'error'}
            help={<>{errors.salutation ? errors.salutation.message : ''}</>}
            requiredMark={true}
          >
            <Controller
              name="salutation"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    onSelect={(value) => {
                      onChange(value)
                    }}
                  >
                    {salutationOptions.map((item: any) => (
                      <Option value={item.code} key={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )
              }}
            />
          </Form.Item>

          <Form.Item
            label="Author Name"
            validateStatus={errors.name && 'error'}
            help={<>{errors.name ? errors.name.message : ''}</>}
            requiredMark={true}
          >
            <Controller
              render={({ field }) => <Input {...field} />}
              name="name"
              control={control}
            />
          </Form.Item>
        </SalutationSpace>

        <StyledSpace>
          <Form.Item
            label="Email"
            validateStatus={errors.email && 'error'}
            help={<>{errors.email ? errors.email.message : ''}</>}
          >
            <Controller
              render={({ field }) => <Input {...field} />}
              name="email"
              control={control}
            />
          </Form.Item>

          <Form.Item
            label="Phone"
            validateStatus={errors.phone && 'error'}
            help={<>{errors.phone ? errors.phone.message : ''}</>}
          >
            <Controller
              render={({ field }) => <Input {...field} />}
              name="phone"
              control={control}
            />
          </Form.Item>
        </StyledSpace>

        <Form.Item
          label="Bio"
          validateStatus={errors.bio && 'error'}
          help={<>{errors.bio ? errors.bio.message : ''}</>}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <TextEditor
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              )
            }}
            name="bio"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Memo"
          validateStatus={errors.memo && 'error'}
          help={<>{errors.memo ? errors.memo.message : ''}</>}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <TextEditor
                  value={value}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              )
            }}
            name="memo"
            control={control}
          />
        </Form.Item>

        <Form.Item label="Configs">
          <Controller
            name="configs"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <ConfigModal
                  defaultValue={value}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              )
            }}
          />
        </Form.Item>

        <div>
          <label>Social Links</label>
        </div>

        <Button
          onClick={() => {
            appendSocialLink({
              type: '',
              link: '',
            })
          }}
        >
          Add Social Links
        </Button>

        {fieldSocialLinks.map((field, index) => {
          const _item: any = errors?.socialLinks?.[index]
          const errorType = _item?.type
          const errorLink = _item?.link

          return (
            <SocialLinksSpace key={field.id}>
              <Form.Item
                label="Type"
                validateStatus={errorType && 'error'}
                help={<>{errorType ? errorType.message : ''}</>}
              >
                <Controller
                  name={`socialLinks.${index}.type`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <AutoComplete
                        value={value}
                        onChange={onChange}
                        options={[
                          { label: 'Facbook', value: 'Facebook' },
                          { label: 'Instagram', value: 'Instagram' },
                        ]}
                      />
                    )
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Link"
                validateStatus={errorLink && 'error'}
                help={<>{errorLink ? errorLink.message : ''}</>}
              >
                <Controller
                  render={({ field }) => <Input {...field} />}
                  name={`socialLinks.${index}.link`}
                  control={control}
                />
              </Form.Item>

              <Button
                style={{
                  marginTop: 30,
                }}
                onClick={() => {
                  removeSocialLink(index)
                }}
                icon={<DeleteOutlined />}
              />
            </SocialLinksSpace>
          )
        })}

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                let dataToSubmit = { ...data }

                if (!dataToSubmit.email) {
                  delete dataToSubmit.email
                }

                await onSubmit(dataToSubmit)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default AuthorFormModel
