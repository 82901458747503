import React, { useEffect } from 'react'
import useSWR from 'swr'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import OtherService, { EnumType } from '../../services/otherService'
import swrKeys from 'src/constants/swrKey'
import { Select } from 'antd'
import useIsAuthor from '../hooks/useIsAuthor'
import useIsAdmin from '../hooks/useIsAdmin'

const otherService = new OtherService()

const { Option } = Select
const TransactionSelect: React.FC<{
  onChange?: (value: any) => void
  [index: string]: any
}> = (props) => {
  const isAuthor = useIsAuthor()
  const isAdmin = useIsAdmin()
  const { onChange, ...rest } = props
  const { data, mutate } = useSWR<any[], any>(
    [swrKeys.transactionTypeEnum(), isAuthor, isAdmin],
    async () => {
      const res = await otherService.getEnum({
        type: isAdmin ? EnumType.TRANSACTION_OPERATION_TYPE : EnumType.ITEM_TYPE,
      })
      return res.data
    },
    getSwrDefaultConfig(),
  )

  useEffect(() => {
    return () => {
      mutate(null)
    }
  }, [])

  if (!data) {
    return <React.Fragment />
  }

  return (
    <Select
      onChange={(value) => {
        onChange(value)
      }}
      {...rest}
      defaultValue={isAdmin ? 'BLOCK' : 'E_BOOK'}
      style={{ width: '200px' }}
    >
      {data &&
        data.map((item: any) => {
          return (
            <Option key={item[getIdentifier()]} value={item.value}>
              {item.name}
            </Option>
          )
        })}
    </Select>
  )
}

export default TransactionSelect
