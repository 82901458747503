import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

export interface PresetPriceFormData {
  name: string
  detail?: [
    {
      price: {
        value: number
        currency: string
      }
      duration: number
      durationType: string
      presetCode: any
    },
  ]
}

const url = '/preset-price'

class PresetPriceService implements BaseService<{}>, CRUDService<{}> {
  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/${id}`)
  }

  async getTrashed(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(`${url}/all/trash`, {
      params,
    })
    return res.data as any
  }

  create(data: PresetPriceFormData): Promise<void> {
    return getAxiosConfig().post(url, data)
  }

  update(args: { id: any; data: PresetPriceFormData }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }

  get(id: any): Promise<PresetPriceFormData> {
    return getAxiosConfig().get(`${url}/${id}`)
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }
}

export default PresetPriceService
