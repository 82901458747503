import React, { useEffect } from 'react'
import useLayoutHeaderContext from 'src/hooks/useLayoutHeaderContext'

const withChangeHeader = <T,>(Component: any, headerLabel: string) =>
  function Comp(props: T) {
    const { setHeaderLabel } = useLayoutHeaderContext()
    useEffect(() => {
      setHeaderLabel(headerLabel)
    }, [])
    return <Component {...props} />
  }

export default withChangeHeader
