import 'ant-design-draggable-modal/dist/index.css'
// import '@cubetiq/enhance-antd-table/dist/index.css'
import { createRoot } from 'react-dom/client'
import './App.less'
import App from './components/app'

const Main = () => {
  return (
    <>
      <App />
    </>
  )
}

const container = document.getElementById('root') ?? document.createElement('div')
const root = createRoot(container)
root.render(<Main />)
