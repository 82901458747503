import { BrowserRouter as Router } from 'react-router-dom'
import AuthContextProvider from '../../context/AuthContext'
import { BaseLayoutHeaderProvider } from '../../context/BaseLayoutHeaderContext'
import RouterView from '../route/routes'
import { ConfigProvider } from 'antd'
import ThemeProvider from '../theme'
import { ThemeContext } from 'styled-components'

const App = () => {
  return (
    <ThemeProvider>
      <ThemeContext.Consumer>
        {(value) => (
          <ConfigProvider
            theme={{
              token: value,
            }}
          >
            <BaseLayoutHeaderProvider>
              <Router>
                <AuthContextProvider>
                  <RouterView />
                </AuthContextProvider>
              </Router>
            </BaseLayoutHeaderProvider>
          </ConfigProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProvider>
  )
}

export default App
