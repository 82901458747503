import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form } from 'antd'
import { DraggableModalProps } from 'ant-design-draggable-modal'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ModalFormContainer, { ModalFooter } from 'src/components/form/ModalForm'
import ColorPicker from '../other/ColorPicker/ColorPicker'
import UploadComponent from '../other/UploadAvatar'

export interface modalCategoryInterface extends DraggableModalProps {
  onSubmit: (data: any) => Promise<void>
  formData: FormData
}

const schema = yup.object().shape({
  name: yup.string().required('Category name is required'),
})

interface FormData {
  name: string
  color: string
  avatar?: string
}

const defaultValues: FormData = {
  name: '',
  color: '#000000',
}

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const CategoryFormModel: React.FC<modalCategoryInterface> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={350}
      initialWidth={350}
      footer={null}
    >
      <Form {...layout}>
        <Form.Item
          label="Avatar"
          validateStatus={errors.avatar && 'error'}
          help={errors.avatar ? errors.avatar.message : ''}
          requiredMark={true}
        >
          <Controller
            name="avatar"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => {
              return (
                <UploadComponent
                  imageUrl={value}
                  onRemove={() => {
                    onChange()
                  }}
                  onUpload={(file) => {
                    onChange(file.url)
                  }}
                />
              )
            }}
          />
        </Form.Item>

        <Form.Item
          label="Category Name"
          validateStatus={errors.name && 'error'}
          help={errors.name ? errors.name.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => <Input {...field} />}
            name="name"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Color"
          validateStatus={errors.color && 'error'}
          help={errors.color ? errors.color.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <ColorPicker
                  onAccept={(value) => {
                    onChange(value)
                  }}
                  value={value}
                />
              )
            }}
            name="color"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default CategoryFormModel
