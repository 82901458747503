import { getAxiosConfig } from '@/utils'
import BaseService, {
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

export interface NotificationProps {
  topic: string
  notificationType: string
  description: string
  [x: string]: any
}
const url = '/notification'
export const url_customer = '/user/customer'
class NotificationService implements BaseService<{}> {
  getUrl(): string {
    return url
  }

  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}[]>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  // Offer
  async getAllOffer(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}[]>> {
    const res = await getAxiosConfig().get(`${url}/offer`, {
      params,
    })
    return res.data as any
  }

  async createOffer(data: NotificationProps): Promise<NotificationProps> {
    const res = await getAxiosConfig().post(`${url}/offer`, data)
    return res.data
  }

  async updateOffer(
    id: string | number,
    data: NotificationProps,
  ): Promise<NotificationProps> {
    const res = await getAxiosConfig().post(`${url}/offer/${id}`, data)
    return res.data
  }
  async deleteOffer(id: string | number): Promise<NotificationProps> {
    const res = await getAxiosConfig().delete(`${url}/offer/${id}`)
    return res.data
  }

  async getPhoneCustomer(phone: string | number): Promise<any> {
    const res = await getAxiosConfig().get(`${url_customer}/${phone}`)
    return res.data
  }
}

export default NotificationService
