import { Select, SelectProps } from 'antd'
import React from 'react'

const languageOptions = [
  {
    code: 'kh',
    name: 'Khmer',
  },
  {
    code: 'en',
    name: 'English',
  },
]

export const findLanguageByName = (name: string) => {
  return languageOptions.find(
    (item) => item.name.toLowerCase() === name.toLowerCase(),
  )
}

interface SelectLanguageProps extends SelectProps {}

const SelectLanguage: React.FC<SelectLanguageProps> = (props) => {
  return (
    <Select
      {...props}
      options={languageOptions.map((item) => {
        return {
          label: item.name,
          value: item.name,
        }
      })}
    />
  )
}

export default SelectLanguage
