import { newColumnsInterface, TableSkeleton } from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import BodyContainer from '../other/bodyContainer'
import Table from '@cubetiq/enhance-antd-table'
import { Button, Space, Checkbox, Input } from 'antd'
import swrKeys from 'src/constants/swrKey'
import useSWR from 'swr'
import { getIdentifier, getIn, getSwrDefaultConfig } from '@/utils'
import UserService from 'src/services/userService'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import Avatar from 'antd/lib/avatar/avatar'
import { UserOutlined } from '@ant-design/icons'
import { getPaginationProps } from 'src/utils/tableHelper'
import { PaginationRequestParams } from 'src/services/baseService'
import { defaultPaginationRequestParams } from 'src/constants'
import handleError from 'src/utils/errorHelper'
import { dateTimeformat, formatType } from '../../utils/dateTime'

const tableReqKey = swrKeys.user()

const { Search } = Input

const columns: newColumnsInterface[] = [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    render: (value, record) => (
      <Avatar size={'small'} icon={<UserOutlined />} src={record.profile?.avatar} />
    ),
  },
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    render: (value) => (value ? value : ''),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, record) => {
      const { profile: value } = record
      return value?.name
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    render: (_, record) => {
      const { profile: value } = record
      return value?.gender
    },
  },
  {
    title: 'DOB',
    dataIndex: 'dob',
    render: (_, record) => {
      const { profile: value } = record
      let dob = value?.dateOfBirth
      if (dob) {
        dob = dateTimeformat({ value: dob, format: formatType.date })
      }
      return dob
    },
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    render: (_, record) => {
      const data = getIn(record, 'profile.phoneNumber.number')

      return data
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (_, record) => {
      const { profile: value } = record
      return value?.email
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, record) => {
      const { profile } = record
      let locations: string[][] = []

      if (profile && profile.location) {
        const newLocation: string[] = []
        profile.location.forEach((item: any) => {
          for (const [_, value] of Object.entries(item)) {
            value && newLocation.push(value as string)
          }
          locations.push(newLocation)
        })
      }

      return locations.map((row, index) => {
        return <div key={index}>{row.join(', ')}</div>
      })
    },
  },
]

interface TableProps {
  renderCreateButton: () => React.ReactNode
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
}

const userService = new UserService()

const UserTable: React.FC<TableProps> = (props) => {
  const { data = [], error, mutate } = useSWR<any, any>(
    tableReqKey,
    null,
    getSwrDefaultConfig(),
  )

  const { refetchRef, renderOwnActionMenu } = props

  const [showTrashed, setShowTrashed] = useState(false)

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await userService.getTrashed(reqParams)
          : await userService.getAll(reqParams)
        mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [showTrashed, reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [showTrashed, reqParams])

  const renderTrashedTableActionMenu = useCallback(
    (record: any) => {
      let menus: TableActionMenuProps['menus'] = [
        {
          render: () => {
            return <div>Restore</div>
          },
          key: 'Restore',
          onClick: async () => {
            try {
              await userService.restore(record.record[getIdentifier()])
              refetch()
            } catch (e) {
              handleError(e)
            }
          },
        },
      ]

      return <TableActionMenu menus={menus} />
    },
    [showTrashed],
  )

  if (error) {
    return <div>error</div>
  }

  return (
    <BodyContainer style={{ padding: 20 }}>
      <TableSkeleton loading={!data}>
        <Table
          restProps={{
            rowKey: 'id',
            bordered: true,
            pagination: getPaginationProps(data.pages, async (page, pageSize) => {
              setReqParams({
                page,
                size: pageSize,
              })
            }),
          }}
          columnsVisibleControllerProps={{
            show: true,
          }}
          renderCreateButton={() => {
            return (
              <Space>
                {props.renderCreateButton()}
                <Button
                  onClick={() => {
                    refetch()
                  }}
                >
                  Refresh
                </Button>
              </Space>
            )
          }}
          newColumns={columns}
          newSources={data.data}
          name={tableReqKey}
          renderOwnActionMenu={(record) => {
            return showTrashed
              ? renderTrashedTableActionMenu(record)
              : renderOwnActionMenu({ record, mutate, data })
          }}
          headerStyle={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          renderOwnSearchInput={() => (
            <Checkbox
              style={{ marginLeft: 8 }}
              onChange={async (e) => {
                const { checked } = e.target
                setShowTrashed(checked)
              }}
            >
              Trash
            </Checkbox>
          )}
        />
      </TableSkeleton>
    </BodyContainer>
  )
}

export default UserTable
