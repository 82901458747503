import { getIdentifier, getStatusColor, getSwrDefaultConfig } from '@/utils'
import { dateTimeformat, formatType } from '@/utils/dateTime'
import { FileImageOutlined } from '@ant-design/icons'
import Table, {
  TableSkeleton,
  newColumnsInterface,
} from '@cubetiq/enhance-antd-table'
import { DraggableModal } from 'ant-design-draggable-modal'
import { Button, Popconfirm, Space } from 'antd'
import Search from 'antd/es/input/Search'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import styled from 'styled-components'
import useSWR from 'swr'
import { titleCase, words } from 'voca'
import DepositService, { DepositStatus } from '../../services/depositService'
import MountedModalProvider from '../modal/MountedModalProvider'
import BodyContainer from '../other/bodyContainer'
import BoldLabelValue from '../other/text'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import DepositForm, { FormData } from './DepositModalForm'

const AttachmentImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
    width: 100%;
  }
`

const depositService = new DepositService()

const DepositTable: React.FC<{
  renderCreateButton?: () => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  onRefresh?: () => void
}> = (props) => {
  const { renderCreateButton, refetchRef, onRefresh } = props
  const { data, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.deposit(),
    null,
    getSwrDefaultConfig(),
  )

  const [formData, setFormData] = useState<FormData | null>(null)

  const renderActionMenu = (args: any) => {
    const { record: currentRecord } = args

    let menus: TableActionMenuProps['menus'] =
      currentRecord.record.status === DepositStatus.PENDING
        ? [
          {
            render: () => {
              return <div>Approve</div>
            },
            key: 'approve',
            onClick: () => {
              const { record } = currentRecord
              setFormData({
                requestBalance: record.balance,
                balance: 0,
                id: record[getIdentifier()],
              })
            },
          },
          {
            render: () => {
              return (
                <Popconfirm
                  title="Are you sure?"
                  trigger={['click']}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={async () => {
                    const { record } = currentRecord
                    try {
                      await depositService.update({
                        id: record[getIdentifier()],
                        data: { ...record, status: DepositStatus.DENIED },
                      })
                      refreshAction()
                    } catch (e) {
                      handleError(e)
                    }
                  }}
                >
                  <div>Decline</div>
                </Popconfirm>
              )
            },
            key: 'denied',
          },
        ]
        : []

    return menus.length > 0 && <TableActionMenu menus={menus} />
  }

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = await depositService.getAll({
          ...reqParams,
          sorts: 'created_date:desc',
        })

        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [reqParams])

  const [attachment, setAttachment] = useState<string | null>(null)

  const columns: newColumnsInterface[] = [
    {
      dataIndex: 'code',
      title: 'Code',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value) => {
        let date = value
          ? dateTimeformat({ value: value, format: formatType.dateTime })
          : ''
        return date
      },
    },
    {
      dataIndex: 'balance',
      title: 'Balance',
      render(value, record, index) {
        return `$ ${value}`
      },
    },
    {
      dataIndex: 'attachment',
      title: 'Attachment',
      render: (value) => {
        return (
          <Button
            onClick={() => {
              setAttachment(value)
            }}
            icon={<FileImageOutlined />}
          />
        )
      },
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render: (value) => {
        return (
          <span
            style={{
              color: getStatusColor(value),
            }}
          >
            {value === DepositStatus.DENIED ? 'Declined' : value}
          </span>
        )
      },
    },
    {
      dataIndex: 'confirmBy',
      title: 'Confirm By',
      render(value) {
        return value
      },
    },
  ]

  const attachmentVisible = attachment !== null

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        <DraggableModal
          open={attachmentVisible}
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
          footer={null}
          onCancel={() => {
            setAttachment(null)
            setFormData(null)
          }}
        >
          {attachmentVisible && (
            <AttachmentImageContainer>
              <img src={attachment} alt={'Attachment'} />
            </AttachmentImageContainer>
          )}
        </DraggableModal>

        {formData && (
          <DepositForm
            onSubmit={async ({ balance }) => {
              try {
                await depositService.update({
                  id: formData.id,
                  data: { balance, status: DepositStatus.APPROVED },
                })
                setFormData(null)
                refreshAction()
              } catch (e) {
                handleError(e)
              }
            }}
            onCancel={() => {
              setFormData(null)
            }}
            defaultValues={formData}
          />
        )}
      </MountedModalProvider>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
              expandable: {
                expandedRowRender: ({
                  paymentMethod: { name, paymentType, properties },
                  user,
                }) => {
                  const renderPaymentMethod = () => {
                    return Object.keys(properties).map((key) => (
                      <BoldLabelValue
                        key={key}
                        title={titleCase(words(key).join(' '))}
                      >
                        {properties[key]}
                      </BoldLabelValue>
                    ))
                  }

                  // Render attribute response from backend for user field
                  const renderUserInfo = () => {
                    return Object.keys(user).map((key) => (
                      // check not render attribute username
                      key !== 'username' && (
                        <BoldLabelValue
                          key={key}
                          title={titleCase(words(key).join(' '))}
                        >
                          {user[key]}
                        </BoldLabelValue>
                      )
                    ))
                  }

                  return (
                    <>
                      <h3>User</h3>
                      {renderUserInfo()}
                      <br />
                      <h3>Payment Method</h3>
                      <BoldLabelValue title={'Name'}>{name}</BoldLabelValue>
                      <BoldLabelValue title={'Type'}>{paymentType}</BoldLabelValue>
                      {renderPaymentMethod().slice(0, 2)}
                    </>
                  )
                },
              },
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  {renderCreateButton && renderCreateButton()}
                  <Button
                    onClick={() => {
                      refreshAction()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={data ? data.data : []}
            name="deposit-table"
            renderOwnActionMenu={(record) => {
              return renderActionMenu({ record, mutate, data: data.data })
            }}
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            // renderOwnSearchInput={() => <React.Fragment />}
            renderOwnSearchInput={() => (
              <Search
                placeholder="Search by phone number"
                enterButton
                style={{
                  width: 350,
                }}
                loading={!data}
                onSearch={(value: any) => {
                  setReqParams((old) => ({
                    ...old,
                    q: value,
                  }))
                }}
              />
            )}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default DepositTable
