import { useEffect } from 'react'
import { Navigate } from 'react-router'

const RouteReset = () => {
  const clearAllCaches = () => {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName).then((r) => console.log('Cached', r))
      })
    })
  }
  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    clearAllCaches()
  }, [])

  return <Navigate to={'/login'} />
}

export default RouteReset
