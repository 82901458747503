import { AxiosRequestConfig } from 'axios'
import { getAxiosConfig } from '@/utils'
import BaseService, { DataResponse, PaginationDataReponse } from './baseService'

export interface Upload {
  name: string
  shortLink: string
  size: number
  uid: string
  url: string
}

const url = `/upload`

class UploadService extends BaseService<{}> {
  getAll(): Promise<PaginationDataReponse<{}>> {
    throw new Error('Method not implemented.')
  }
  
  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async upload(
    file: any,
    axiosRequestConfig?: AxiosRequestConfig,
  ): Promise<DataResponse<Upload>> {
    const bodyFormData = new FormData()

    bodyFormData.append('file', file)
    const res = await getAxiosConfig({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: undefined,
    }).post(`${url}`, bodyFormData, axiosRequestConfig)
    return res.data
  }
}

export default UploadService
