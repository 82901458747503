export const isNullOrUndefined = (value: any) =>
  value === undefined && value === null

export function tryParseJSONObject(jsonString: string) {
  try {
    var o = JSON.parse(jsonString)

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {
    console.error(e)
  }

  return false
}

export function formatJSON(val = '{}') {
  try {
    const res = JSON.parse(val)
    return JSON.stringify(res, null, 2)
  } catch {
    console.error('Cannot format json')

    const errorJson = {
      error: ``,
    }
    return JSON.stringify(errorJson, null, 2)
  }
}
