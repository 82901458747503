import React from 'react'
import {
  AutoComplete as AntdAutoComplete,
  AutoCompleteProps as AntdAutoCompleteProps,
} from 'antd'

interface AutoCompleteProps extends AntdAutoCompleteProps {}

const AutoComplete: React.FC<AutoCompleteProps> = (props) => {
  return <AntdAutoComplete {...props} />
}

export default AutoComplete
