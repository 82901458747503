import { Button, Checkbox, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import BackendUserService from 'src/services/backendUserService'
import { PaginationRequestParams } from 'src/services/baseService'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import useSWR from 'swr'
import BodyContainer from '../other/bodyContainer'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'

const tableReqKey = swrKeys.backendUser()

const columns: newColumnsInterface[] = [
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
  },
]

interface TableProps {
  renderCreateButton: () => React.ReactNode
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
}

const backendUserService = new BackendUserService()

const UserTable: React.FC<TableProps> = (props) => {
  const { data = [], error, mutate } = useSWR<any, any>(
    tableReqKey,
    null,
    getSwrDefaultConfig(),
  )

  const { refetchRef, renderOwnActionMenu } = props

  const [showTrashed, setShowTrashed] = useState(false)

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await backendUserService.getTrashed(reqParams)
          : await backendUserService.getAll(reqParams)
        mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [showTrashed, reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [showTrashed, reqParams])

  const renderTrashedTableActionMenu = useCallback(
    (record: any) => {
      let menus: TableActionMenuProps['menus'] = [
        {
          render: () => {
            return <div>Restore</div>
          },
          key: 'Restore',
          onClick: async () => {
            try {
              await backendUserService.restore(record[getIdentifier()])
              refetch()
            } catch (e) {
              handleError(e)
            }
          },
        },
      ]

      return <TableActionMenu menus={menus} />
    },
    [showTrashed],
  )

  if (error) {
    return <div>error</div>
  }

  return (
    <BodyContainer style={{ padding: 20 }}>
      <TableSkeleton loading={!data}>
        <Table
          restProps={{
            rowKey: 'id',
            bordered: true,
            pagination: getPaginationProps(data.pages, async (page, pageSize) => {
              setReqParams({
                page,
                size: pageSize,
              })
            }),
          }}
          columnsVisibleControllerProps={{
            show: true,
          }}
          renderCreateButton={() => {
            return (
              <Space>
                {props.renderCreateButton()}
                <Button
                  onClick={() => {
                    refetch()
                  }}
                >
                  Refresh
                </Button>
              </Space>
            )
          }}
          newColumns={columns}
          newSources={data.data}
          name={tableReqKey}
          renderOwnActionMenu={({ record }) => {
            return showTrashed
              ? renderTrashedTableActionMenu(record)
              : renderOwnActionMenu({ record, mutate, data })
          }}
          headerStyle={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          renderOwnSearchInput={() => (
            <Checkbox
              style={{ marginLeft: 8 }}
              onChange={async (e) => {
                const { checked } = e.target
                setShowTrashed(checked)
              }}
            >
              Trash
            </Checkbox>
          )}
        />
      </TableSkeleton>
    </BodyContainer>
  )
}

export default UserTable
