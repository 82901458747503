import {
  ChartTypeProjection,
  DashboardChartsModel,
  DashboardRowModel,
} from '@/models'
import { DashboardChartsService } from '@/services'
import { breakpointScreen, maxLengthCol } from '@/utils'
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chart from '../Chart'

const CustomRow = styled(Row)`
  height: auto;
`

const CustomCol = styled(Col)`
  padding-top: 10px;

  @media (max-width: 1200px) {
    padding: 5px;
  }
`

interface DashboardChartsProps {
  data?: DashboardChartsModel
  service?: DashboardChartsService
  params?: any
  refreshToken?: string | null
}

const DashboardCharts: React.FC<DashboardChartsProps> = (props) => {
  const { data = {}, service, params, refreshToken = undefined } = props
  const [model, setModel] = useState<DashboardChartsModel>(data)

  useEffect(() => {
    if (service) {
      service.getCharts(params).then((resp) => {
        setModel(resp || data)
      })
    }
  }, [refreshToken])

  const renderChart = (chart: ChartTypeProjection, columnLength) => {
    return (
      <CustomCol
        key={`col-${chart.type || chart.title || ''}`}
        {...chart.props?.colProps}
        span={breakpointScreen(columnLength)}
        xxl={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.xxl || 4,
        })}
        xl={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.xl || 3,
        })}
        lg={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.lg || 2,
        })}
        md={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.md || 2,
        })}
        sm={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.sm || 1,
        })}
        xs={breakpointScreen(columnLength, {
          maxCol: chart.props?.col?.xs || 1,
        })}
      >
        <Chart
          key={`chart-${chart.type || chart.title || ''}`}
          title={chart.title}
          chartProps={{ ...chart.chartOptions }}
          props={{ ...chart.props?.chartProps }}
        />
      </CustomCol>
    )
  }

  const renderRow = (row: DashboardRowModel<ChartTypeProjection>, index = 0) => {
    return (
      <CustomRow
        key={`row-${index}`}
        {...row.props?.rowProps}
        gutter={row.props?.gutter || 16}
      >
        {row.data?.map((chart) => renderChart(chart, row.data?.length))}
      </CustomRow>
    )
  }

  return (
    <>
      {model.charts &&
        model.charts?.length > 0 &&
        renderRow({
          props: {},
          data: model.charts,
        })}

      {model.rows &&
        model.rows?.length > 0 &&
        model.rows.map((row, index) => renderRow(row, index))}
    </>
  )
}

export default DashboardCharts
