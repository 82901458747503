import { APP_LOGIN_BACKGROUND_URL, APP_LOGO_URL, APP_VERSION } from '@/app.config'
import { Button, Form, Input } from 'antd'
import styled from 'styled-components'

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #efefef;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  ${APP_LOGIN_BACKGROUND_URL &&
  `background-image: url('${APP_LOGIN_BACKGROUND_URL}');`}
`

const formContainerProps = {
  width: 600,
}

const FormContainer = styled.div`
  width: ${formContainerProps.width}px;
  height: 500px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  padding: 60px 30px;
  z-index: 2;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
`

const FormArea = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  background: transparent;
`

const Header = styled.h2`
  margin: 0;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000;
  font-family: 'Kantumruy', sans-serif;
`

const StyledInput = styled(Input)`
  border-radius: 5px;
  height: 48px;
  font-size: 14px;
  width: 100%;
  border: none;
  background-color: #fff;
`

const StyledInputPassword = styled(Input.Password)`
  border-radius: 5px;
  height: 48px;
  font-size: 18px !important;
  width: 100%;
  border: none;
  background-color: #fff;
`

const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  height: 55px;
  border: 1px solid #e8e8e8;
`

const Title = styled.div`
  font-size: 15px;
  font-family: 'Kantumruy', sans-serif;
  margin-bottom: 30px;
  color: #000;
`

const FormItem = styled(Form.Item)`
  width: 80%;
  font-family: 'Kantumruy', sans-serif;
  margin-bottom: 30px;
  border: 1px solid #e8e8e8;
`

const SubmitContainer = styled.div`
  width: 80%;
  font-family: 'Kantumruy', sans-serif;
  margin-bottom: 20px;
`

const VersionFooter = styled('div')({
  marginTop: '20px',
  textAlign: 'center',
  position: 'absolute',
  bottom: '5px',
  width: '100%',
  fontSize: '12px',
  color: '#6a6a6a',
})

const ImageLogin = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`

const LoginPage: React.FC<React.PropsWithChildren<{
  onSubmit: (data: any) => void
}>> = (props) => {
  const onSubmit = async (data: any) => {
    props.onSubmit(data)
  }

  return (
    <LoginContainer>
      <FormContainer>
        <FormArea onFinish={onSubmit}>
          <ImageLogin src={APP_LOGO_URL} alt="Akara Book Logo" />
          <Header>សូមស្វាគមន៍</Header>

          <Title>
            សូមបំពេញឈ្មោះ អ្នកប្រើប្រាស់ និងពាក្យសំងាត់ដើម្បីចូលប្រើប្រាស់ប្រព័ន្ធ
          </Title>
          <FormItem
            name="username"
            rules={[
              {
                required: true,
                message: 'សូមបញ្ចូល  ឈ្មោះអ្នកប្រើប្រាស់! ',
              },
            ]}
          >
            <StyledInput placeholder={'ឈ្មោះអ្នកប្រើប្រាស់'} />
          </FormItem>

          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: 'សូមបញ្ចូល  លេខសម្ងាត់!',
              },
            ]}
          >
            <StyledInputPassword placeholder={'លេខសម្ងាត់'} />
          </FormItem>

          <SubmitContainer style={{ marginTop: 8 }}>
            <StyledButton htmlType={'submit'} type="primary" size="large">
              ចូលទៅក្នុងប្រព័ន្ធ
            </StyledButton>

            {props.children}
          </SubmitContainer>
        </FormArea>
      </FormContainer>

      <VersionFooter>កំណែកម្មវិធី៖ {APP_VERSION}</VersionFooter>
    </LoginContainer>
  )
}

export default LoginPage
