import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'

export interface TableActionMenuProps {
  menus: { render: () => React.ReactNode; onClick?: () => void; key: string }[]
}

const TableActionMenu: React.FC<TableActionMenuProps> = (props) => {
  const { menus } = props
  const menuOverlay = (
    <Menu
      items={menus.map((item) => {
        const { onClick, render } = item
        return {
          key: item.key,
          onClick: onClick || (() => {}),
          label: render(),
        }
      })}
    />
  )

  return menus.length > 0 ? (
    <Dropdown overlay={menuOverlay} trigger={['click']}>
      <Button shape="circle" icon={<MoreOutlined />} />
    </Dropdown>
  ) : (
    <div></div>
  )
}

export default TableActionMenu
