import React from 'react'
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd'

interface SelectProps extends AntdSelectProps {}

const searchFunction = (input: string, option: any) => {
  return (
    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
  )
}

const Select: React.FC<SelectProps> = (props) => {
  const defaultProps: SelectProps = {
    showSearch: true,
    optionFilterProp: 'children',
    filterOption: { searchFunction } as any,
    ...props,
  }

  return <AntdSelect {...defaultProps} />
}

export default Select
