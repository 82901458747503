import { getAxiosConfig } from '@/utils'
import { DataResponse } from './baseService'

const url = '/other'

export enum EnumType {
  PAYMENT_METHOD_TYPE = 'PAYMENT_METHOD_TYPE',
  BOOK_SHELF_READING_STATUS = 'BOOK_SHELF_READING_STATUS',
  BOOK_SUBSCRIPTION_DURATION = 'BOOK_SUBSCRIPTION_DURATION',
  GENDER = 'GENDER',
  TRANSACTION_OPERATION_TYPE = 'TRANSACTION_OPERATION_TYPE',
  ITEM_TYPE = `ITEM_TYPE`,
}

interface EnumData {
  id: string
  code: string
  name: string
}

class OtherService {
  async getEnum(args: { type: EnumType }): Promise<DataResponse<EnumData[]>> {
    const { type } = args
    const res = await getAxiosConfig({
      baseURL: process.env.REACT_APP_SERVER_URL + '/api',
      headers: undefined
    }).get(`${url}/enum_type`, {
      params: {
        type,
      },
    })
    return res.data
  }
}

export default OtherService
