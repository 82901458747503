import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'
import { AbstractDashboardStatsService } from './DashboardStatsService';

const url = '/user'

class UserService extends AbstractDashboardStatsService implements BaseService<{}>, CRUDService<{}> {
  getUrl(): string {
    return url
  }

  create(data: {}): Promise<void> {
    throw new Error('Method not implemented.')
  }

  update(args: { id: any; data: {} }): Promise<void> {
    throw new Error('Method not implemented.')
  }

  delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }

  async getTrashed(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(`${url}/all/trash`, {
      params,
    })
    return res.data as any
  }

  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/${id}`)
  }

  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  async resetPassword(data: { username: string; password: string }): Promise<{}> {
    const res = await getAxiosConfig().put(`${url}/resetPassword`, data)
    return res.data as any
  }
}

export default UserService
