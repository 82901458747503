import { DashboardChartsModel } from '@/models';
import { getAxiosConfig } from '@/utils';
import { BaseApiService } from './BaseApiService';
import { AbstractDashboardStatsService } from './DashboardStatsService';

export interface DashboardChartsService extends BaseApiService {
    getCharts(params?: any): Promise<DashboardChartsModel>;
}

export abstract class AbstractDashboardChartsService extends AbstractDashboardStatsService implements DashboardChartsService {
    async getCharts(
        params?: any
    ): Promise<DashboardChartsModel> {
        let url = this.getUrl()
        if (!url.endsWith('/')) {
            url = `${url}/`;
        }

        const res = await getAxiosConfig().get(`${url}charts`, {
            params,
        });
        return res?.data?.data;
    }
}
