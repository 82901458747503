import withChangeHeader from '@/components/hoc/withChangeHeader'

import { DashboardStats } from '@/components/DashboardStats'
import { TransactionService } from '@/services'
import TransactionTable from '@/components/transaction/transactionTable'
import { DollarCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'

const service = new TransactionService()

const TransactionPage = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  return (
    <>
      <DashboardStats
        service={service}
        defaultProps={{
          precision: 2,
          suffix: '$',
          prefix: <DollarCircleOutlined />,
        }}
        refreshToken={refreshToken}
      />

      <br />

      <TransactionTable onRefresh={onRefresh} />
    </>
  )
}

export default withChangeHeader(TransactionPage, 'Transactions')
