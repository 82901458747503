import { useAuthContext } from '@/context/AuthContext'
import { RouteObj } from '../route/routes'

const ProtectedRoute: React.FC<RouteObj> = (props) => {
  const { state } = useAuthContext()
  if (!state.user) {
    return <>Unauthorized</>
  }

  return <>{props.element}</>
}

export default ProtectedRoute
