import {
  Card,
  Statistic as AntStatistic,
  StatisticProps as AntStatisticProps,
} from 'antd'

export interface StatisticProps extends AntStatisticProps {}

export const Statistic: React.FC<StatisticProps> = (props) => {
  return (
    <Card>
      <AntStatistic {...props} />
    </Card>
  )
}
