import { getUUid } from '@/utils'
import { BellOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import { useMemo } from 'react'

import styled from 'styled-components'
import useIsAdmin from '../hooks/useIsAdmin'
import Popover from '../shared/Popover'
import NotificationsList from './notificationsList'

const NotificationContainer = styled.div`
  transition: background-color 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
  }
`

const StyledPopover = styled(Popover)``

const Container = styled.div`
  .ant-popover-inner {
    padding: 0;
  }
`

const Notification = () => {
  const id = useMemo(() => {
    return getUUid()
  }, [])

  const isAdmin = useIsAdmin()

  if (!isAdmin) {
    return null
  }

  return (
    <Container id={id}>
      <StyledPopover
        content={<NotificationsList />}
        trigger={['click']}
        showArrow={false}
        style={{
          padding: 0,
        }}
        getPopupContainer={() => document.getElementById(id)}
      >
        <NotificationContainer>
          <Badge>
            <BellOutlined />
          </Badge>
        </NotificationContainer>
      </StyledPopover>
    </Container>
  )
}

export default Notification
