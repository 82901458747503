import { useAuthContext } from '@/context/AuthContext'
import React from 'react'
import { Navigate } from 'react-router'
import useIsAuthor from '../hooks/useIsAuthor'
import { RouteTypes } from '../route/types'

const Home = () => {
  const {
    state: { loading },
  } = useAuthContext()

  const isAuthor = useIsAuthor()

  if (loading || isAuthor === undefined) {
    return <div />
  }

  return (
    <Navigate
      to={isAuthor ? `/author${RouteTypes.DASHBOARD}` : RouteTypes.DASHBOARD}
    />
  )
}

export default Home
