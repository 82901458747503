import { getAxiosBaseUrl } from '@/utils'

const swrKeys = {
  bookCategory: () => `book-category-req`,
  bookReview: () => `book-review-req`,
  bookDuration: () => `book-duration-req`,
  user: () => `user-req`,
  bookFormAuthorOptions: () => {
    return `${getAxiosBaseUrl()}-book-form-author-options`
  },
  bookFormRentDurationOptions: () => `book-form-rent-duration-options`,
  bookFormCategoryOptions: () => `book-form-category-options`,
  book: () => `book`,
  customerSubscription: () => `customer-subscription`,
  customerTransaction: () => `customer-transaction`,
  deposit: () => `deposit-req`,
  paymentMethod: () => `payment-method-req`,
  paymentMethodTypeEnum: () => `payment-method-type-enum-req`,
  backendUser: () => `backend-user-req`,
  transactionTypeEnum: () => `transaction-type-enum`,
  configuration: () => `configuration`,
  salutation: () => `salutation`,
  reportDeposit: () => `report-deposit-req`,
  deliveryFee: () => `delivery-fee-req`,
  locationPickup: () => `location-pickup-req`,
  publisherMethod: () => `publisher-req`,
  publisherOptions: () => `publisher-options`,
  notificationOffer: () => `notification-offer-req`,
}

export default swrKeys
