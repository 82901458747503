import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

export interface CategoryFormData {
  name: string
  config: {
    color?: string
  }
}

const url = '/category'

class CategoryService implements BaseService<{}>, CRUDService<{}> {
  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/${id}`)
  }

  async getTrashed(params?: PaginationRequestParams) {
    const res = await getAxiosConfig().get(`${url}/trash`, {
      params,
    })
    return res.data as any
  }

  create(data: CategoryFormData): Promise<void> {
    return getAxiosConfig().post(url, data)
  }

  update(args: { id: any; data: CategoryFormData }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }
  
  get(id: any): Promise<CategoryFormData> {
    return getAxiosConfig().get(`${url}/${id}`)
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }
}

export default CategoryService
