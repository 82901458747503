import { yupResolver } from '@hookform/resolvers/yup'
import { DraggableModalProps } from 'ant-design-draggable-modal'
import { Button, Form, Input, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import { SearchOutlined } from '@ant-design/icons'
import NotificationService from '@/services/notificationService'
interface NotificationProps {
  topic: string
  notificationType: string
  description: string
}
const service = new NotificationService()
export interface modalNotification extends DraggableModalProps {
  onSubmit: (data: NotificationProps) => Promise<void>
  formData: NotificationProps
}

const schema = yup.object().shape({
  notificationType: yup.string().required('Notification Type is required'),
  topic: yup.mixed().test('isRequired', 'Topic is required', function (value) {
    return this.parent.notificationType !== 'General' ? !!value : true
  }),
  description: yup.string().required('Description is required'),
})

const defaultValues: NotificationProps = {
  topic: '',
  notificationType: '',
  description: '',
}

const StyledForm = styled(Form)``

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const notificationTypes = [
  {
    id: 1,
    type: 'General',
  },
  {
    id: 2,
    type: 'Offer',
  },
]

const NotificationModal: React.FC<modalNotification> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const [cusId, setCusId] = useState<string>(null)
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<NotificationProps>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const handleGetUser = async () => {
    const phone = getValues('topic')
    try {
      const data = await service.getPhoneCustomer(phone)
      console.log('data cu: ', data)
      if (data?.status?.code === 200) {
        setCusId(data?.data?.id)
        setValue('topic', data?.data?.fullName)
        clearErrors('topic')
      }
    } catch (e: any) {
      setCusId(null)
      setError('topic', { type: 'deps', message: 'User Not Found.....' })
    }
  }

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  // This state for selectedNotificationType
  const [selectedNotificationType, setSelectedNotificationType] = useState<string>()

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={500}
      initialWidth={450}
      footer={null}
    >
      <StyledForm {...layout}>
        <Form.Item
          label="Notification Type"
          validateStatus={errors.notificationType && 'error'}
          help={errors.notificationType ? errors.notificationType.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  onChange={(value) => {
                    field.onChange(value) // This line is added to update the field value.
                    setSelectedNotificationType(value) // Update the selectedNotificationType state.
                    setCusId(null)
                  }}
                >
                  {notificationTypes.map((item: any) => (
                    <Select.Option key={item?.id} value={item.type}>
                      {item.type}
                    </Select.Option>
                  ))}
                </Select>
              )
            }}
            name="notificationType"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Topic"
          validateStatus={errors.topic && 'error'}
          help={errors.topic ? errors.topic.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => {
              const GENERAL = 'General'
              const isGeneralNotification = selectedNotificationType === GENERAL
              return (
                <>
                  <Input
                    value={isGeneralNotification ? GENERAL : field.value}
                    id="topic"
                    onChange={(e) => {
                      if (!isGeneralNotification) {
                        field.onChange(e.target.value)
                      }
                    }}
                    suffix={
                      <SearchOutlined
                        style={{
                          position: 'relative',
                        }}
                      />
                    }
                    disabled={isGeneralNotification}
                  />
                  <Button
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: 0,
                      background: '#2ec4ee',
                      zIndex: '10',
                      color: '#fff',
                    }}
                    disabled={getValues('notificationType') === 'General'}
                    onClick={() => handleGetUser()}
                  >
                    <SearchOutlined />
                  </Button>
                </>
              )
            }}
            name="topic"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Description"
          validateStatus={errors.description && 'error'}
          help={errors.description ? errors.description.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => <Input.TextArea {...field} />}
            name="description"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                let newData: any
                if (data.notificationType === 'General') {
                  data.topic = 'General'
                  newData = data
                } else {
                  if (cusId) {
                    newData = { ...data, topic: cusId }
                  } else {
                    setError('topic', {
                      type: 'deps',
                      message: 'Please Input Phone number.....',
                    })
                  }
                }
                
                await onSubmit(newData)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </StyledForm>
    </ModalFormContainer>
  )
}

export default NotificationModal
