import { Button, Popconfirm, Space } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
  TransactionService,
} from 'src/services'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import useSWR from 'swr'
import { defaultPaginationRequestParams } from '../../constants'
import {
  getIdentifier,
  getIn,
  getStatusColor,
  getSwrDefaultConfig,
} from '../../utils'
import { dateTimeformat, formatType } from '../../utils/dateTime'
import BodyContainer from '../other/bodyContainer'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import TransactionDetail from './transactionDetail'
import TransactionSelect from './transactionSelect'

const transactionService = new TransactionService()

interface TransactionRequestParams extends PaginationRequestParams {
  type?: string
}

const TrasactionTable: React.FC<{
  onRefresh?: () => void
}> = (props) => {
  const { onRefresh } = props
  const { data, error, mutate } = useSWR<PaginationDataReponse<any[]>, any>(
    swrKeys.customerTransaction(),
    null,
    getSwrDefaultConfig(),
  )

  const [reqParams, setReqParams] = useState<TransactionRequestParams>(
    defaultPaginationRequestParams,
  )

  const [currentRecord, setCurrentRecord] = useState<any>()

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = await transactionService.getAll(reqParams)
        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetch()
  }, [reqParams])

  if (error) {
    return <div>error</div>
  }

  if (data === undefined) {
    return <div>loading</div>
  }

  let columns: newColumnsInterface[] = [
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value) => {
        let date = value
          ? dateTimeformat({ value: value, format: formatType.dateTime })
          : ''
        return date
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balanceNormalized',
      render: (value) => {
        return value.normalized
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Withdrawal',
      dataIndex: 'withdrawal',
    },
    {
      title: 'Status',
      dataIndex: 'rejected',
      render: (value) => {
        if (reqParams.type === 'BLOCK' || reqParams.type === undefined) {
          return ''
        }
        const text = value ? 'Rejected' : 'Accepted'
        return (
          <span
            style={{
              color: getStatusColor(text),
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  const renderActionMenu = (args: any) => {
    const { record: currentRecord } = args
    const id = currentRecord[getIdentifier()]
    const subscriptions = currentRecord?.details?.subscriptions || []

    let menus: TableActionMenuProps['menus'] = []

    if (subscriptions.length > 0 || subscriptions) {
      menus = [
        {
          render: () => {
            return (
              <div
                onClick={() => {
                  setCurrentRecord({
                    ...currentRecord,
                    details: {
                      ...currentRecord.details,
                      subscriptions: [subscriptions],
                    },
                  })
                }}
              >
                Detail
              </div>
            )
          },
          key: 'Detail',
        },
      ]
    }

    if (
      currentRecord.rejected === null &&
      (reqParams.type === 'BLOCK' || reqParams.type === undefined)
    ) {
      menus = [
        ...menus,
        {
          render: () => {
            return (
              <Popconfirm
                title="Are you sure?"
                trigger={['click']}
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                  try {
                    await transactionService.accept(id)
                    refreshAction()
                  } catch (e) {
                    handleError(e)
                  }
                }}
              >
                <div>Accept</div>
              </Popconfirm>
            )
          },
          key: 'approve',
        },
        {
          render: () => {
            return (
              <Popconfirm
                title="Are you sure?"
                trigger={['click']}
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                  try {
                    await transactionService.reject(id)
                    refreshAction()
                  } catch (e) {
                    handleError(e)
                  }
                }}
              >
                <div>Decline</div>
              </Popconfirm>
            )
          },
          key: 'denied',
        },
      ]
    }

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <Modal
        title={'Transaction Detail'}
        open={Boolean(currentRecord)}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelText={'Close'}
        onCancel={() => {
          setCurrentRecord(undefined)
        }}
      >
        <TransactionDetail {...currentRecord} />
      </Modal>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: 'id',
              bordered: true,

              pagination: getPaginationProps(data.pages, async (page, pageSize) => {
                setReqParams((old) => ({
                  ...old,
                  page,
                  size: pageSize,
                }))
              }),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  <TransactionSelect
                    onChange={(value) => {
                      setReqParams((old) => ({
                        ...old,
                        type: value,
                      }))
                    }}
                  />
                  <Button
                    onClick={() => {
                      refreshAction()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={data.data}
            name={swrKeys.customerTransaction()}
            renderOwnActionMenu={renderActionMenu}
            renderOwnSearchInput={() => <React.Fragment />}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default TrasactionTable
