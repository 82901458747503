import { DashboardStats } from '@/components'
import { getIdentifier } from '@/utils'
import { DollarCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useCallback, useRef, useState } from 'react'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import PaymentMethodFormModal, {
  modalPaymentInterface,
} from 'src/components/paymentMethod/PaymentMethodFormModal'
import PaymentMethodTable from 'src/components/paymentMethod/paymentMethodTable'
import PaymentMethodService from 'src/services/paymentMethodService'
import handleError from 'src/utils/errorHelper'
import TableActionMenu, {
  TableActionMenuProps,
} from '../../components/table/TableActionMenu'

const paymentMethodService = new PaymentMethodService()

const mapDataToSubmit = (data: any) => {
  const { name, paymentType, ...properties } = data

  return {
    properties,
    name,
    paymentType,
  }
}

const PaymentMethodPage = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  const refetchRef = useRef<Function>()
  const [modalPaymentMethodState, setModalPaymentMethodState] = useState<
    modalPaymentInterface
  >({
    open: false,
    onSubmit: async () => {},
    formData: null,
  })

  const setModalForCreate = useCallback(() => {
    setModalPaymentMethodState((old) => ({
      ...old,
      open: true,
      title: 'Create Payment Method',
      onSubmit: async (data) => {
        try {
          const dataToSubmit = mapDataToSubmit(data)
          await paymentMethodService.create(dataToSubmit)
          setModalPaymentMethodState((old) => ({ ...old, open: false }))
          refetchRef.current()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalPaymentMethodState(() => {
          return { ...old, open: false }
        })
      },
      formData: null,
    }))
  }, [refetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const {
        name,
        paymentType,
        properties: { accountNumber, accountName, link, image },
      } = record
      setModalPaymentMethodState((old) => ({
        ...old,
        open: true,
        title: 'Update Payment Method',
        onSubmit: async (data) => {
          const dataToSubmit = mapDataToSubmit(data)
          try {
            await paymentMethodService.update({
              id: record[getIdentifier()],
              data: dataToSubmit,
            })
            setModalPaymentMethodState((old) => ({
              ...old,
              open: false,
            }))
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },

        onCancel: () => {
          setModalPaymentMethodState({
            ...old,
            formData: null,
            open: false,
          })
        },
        formData: {
          name,
          paymentType,
          accountName,
          accountNumber,
          link,
          image,
        },
      }))
    },
    [refetchRef],
  )

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalCategoryForUpdate(record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await paymentMethodService.delete(record[getIdentifier()])
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalPaymentMethodState.open && (
          <PaymentMethodFormModal {...modalPaymentMethodState} />
        )}
      </MountedModalProvider>

      <DashboardStats
        service={paymentMethodService}
        defaultProps={{
          precision: 2,
          suffix: '$',
          prefix: <DollarCircleOutlined />,
        }}
        refreshToken={refreshToken}
      />

      <br />

      <PaymentMethodTable
        renderOwnActionMenu={(args) => {
          return renderActionMenu(args)
        }}
        renderCreateButton={() => {
          return (
            <Button type="primary" onClick={() => setModalForCreate()}>
              Create
            </Button>
          )
        }}
        refetchRef={refetchRef}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default withChangeHeader(PaymentMethodPage, 'Payment Method')
