import { FileImageOutlined } from '@ant-design/icons'
import { useDebounceFn } from 'ahooks'
import { DraggableModal } from 'ant-design-draggable-modal'
import { Button, DatePicker, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import { DepositStatus } from 'src/services/depositService'
import ReportService from 'src/services/reportService'
import { getIdentifier, getStatusColor, getSwrDefaultConfig } from '@/utils'
import { formatType } from 'src/utils/dateTime'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import styled from 'styled-components'
import useSWR from 'swr'
import { titleCase, words } from 'voca'
import MountedModalProvider from '../modal/MountedModalProvider'
import BodyContainer from '../other/bodyContainer'
import BoldLabelValue from '../other/text'

const { RangePicker } = DatePicker

const AttachmentImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
    width: 100%;
  }
`

const reportService = new ReportService()

const ReportDepositTable = () => {
  const { data, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.deposit(),
    null,
    getSwrDefaultConfig(),
  )

  interface PaginationReportRequestParams extends PaginationRequestParams {
    [index: string]: any
  }

  const [reqParams, setReqParams] = useState<PaginationReportRequestParams>(
    defaultPaginationRequestParams,
  )
  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = await reportService.getAllReportDeposit({
          ...reqParams,
        })
        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetch()
  }, [reqParams])

  const [attachment, setAttachment] = useState<string | null>(null)

  const columns: newColumnsInterface[] = [
    {
      dataIndex: 'code',
      title: 'Code',
    },
    {
      dataIndex: 'balance',
      title: 'Balance',
    },
    {
      dataIndex: 'attachment',
      title: 'Attachment',
      render: (value) => {
        return (
          <Button
            onClick={() => {
              setAttachment(value)
            }}
            icon={<FileImageOutlined />}
          />
        )
      },
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render: (value) => {
        return (
          <span
            style={{
              color: getStatusColor(value),
            }}
          >
            {value === DepositStatus.DENIED ? 'Declined' : value}
          </span>
        )
      },
    },
  ]

  const { run: updateQInReq } = useDebounceFn(
    (data: any) => {
      data
        ? setReqParams({
            page: 0,
            size: reqParams.size,
            startDate: moment(data[0]).valueOf(),
            endDate: moment(data[1]).valueOf(),
          })
        : setReqParams({
            page: 0,
            size: reqParams.size,
          })
    },
    {
      wait: 2000,
    },
  )

  const handleDateOnChange = (date: any) => {
    updateQInReq(date)
  }

  const attachmentVisible = attachment !== null

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        <DraggableModal
          open={attachmentVisible}
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
          footer={null}
          onCancel={() => {
            setAttachment(null)
          }}
        >
          {attachmentVisible && (
            <AttachmentImageContainer>
              <img src={attachment} />
            </AttachmentImageContainer>
          )}
        </DraggableModal>
      </MountedModalProvider>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
              expandable: {
                expandedRowRender: ({
                  paymentMethod: { name, paymentType, properties },
                  user,
                }) => {
                  const renderPaymentMethod = () => {
                    return Object.keys(properties).map((key) => (
                      <BoldLabelValue
                        key={key}
                        title={titleCase(words(key).join(' '))}
                      >
                        {properties[key]}
                      </BoldLabelValue>
                    ))
                  }

                  const renderUserInfo = () => {
                    return Object.keys(user).map((key) => (
                      <BoldLabelValue
                        key={key}
                        title={titleCase(words(key).join(' '))}
                      >
                        {user[key]}
                      </BoldLabelValue>
                    ))
                  }

                  return (
                    <>
                      <h3>User</h3>
                      {renderUserInfo()}
                      <br />
                      <h3>Payment Method</h3>
                      <BoldLabelValue title={'Name'}>{name}</BoldLabelValue>
                      <BoldLabelValue title={'Type'}>{paymentType}</BoldLabelValue>
                      {renderPaymentMethod().slice(0, 2)}
                    </>
                  )
                },
              },
              footer:
                data?.extra &&
                (() => (
                  <div>
                    Total:
                    <span style={{ color: getStatusColor('accepted') }}>
                      {data?.extra?.totalDeposit?.normalized}
                    </span>
                  </div>
                )),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  <Button
                    onClick={() => {
                      refetch()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={data ? data.data : []}
            name="report-deposit-table"
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            renderOwnSearchInput={() => {
              return (
                // @ts-ignore
                <RangePicker
                  format={formatType.date}
                  onChange={handleDateOnChange}
                />
              )
            }}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default ReportDepositTable
