import { formatJSON } from '@/utils/check'
import { Button } from 'antd'
import { useRef, useState } from 'react'
import ConfigFormModal from 'src/components/configuration/ConfigFormModal'
import ListConfiguration from 'src/components/configuration/ListConfiguration'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import TableActionMenu, {
  TableActionMenuProps,
} from 'src/components/table/TableActionMenu'
import ConfigurationService from 'src/services/configurationService'
import { getIdentifier } from '@/utils'
import handleError from 'src/utils/errorHelper'
import withChangeHeader from '@/components/hoc/withChangeHeader'

const service = new ConfigurationService()

const ConfigurationPage = () => {
  const refetchRef = useRef(() => {})
  const [modalFormState, setModalFormState] = useState<{ [index: string]: any }>({
    open: false,
    formData: null,
    title: `Create Configuration`,
    onSubmit: async (data: any) => {
      await service.create(data)
    },
    onCancel: () => {
      setModalFormState((old) => {
        return {
          ...old,
          open: false,
        }
      })
    },
  })

  const setModalForCreate = () => {
    setModalFormState((old) => {
      return {
        ...old,
        open: true,
        title: `Create Configuration`,
        onSubmit: async (data: any) => {
          await service.create(data)
        },
        onCancel: () => {
          setModalFormState((old) => {
            return {
              ...old,
              open: false,
            }
          })
        },
        formData: null,
      }
    })
  }

  const setModalForUpdate = (record: any) => {
    const { value, ...rest } = record
    setModalFormState((old) => {
      return {
        ...old,
        open: true,
        title: `Update Configuration`,
        onCancel: () => {
          setModalFormState((old) => {
            return {
              ...old,
              open: false,
            }
          })
        },
        onSubmit: async (data: any) => {
          await service.update({
            data: data,
            id: record.id,
          })
        },
        formData: {
          valueKey: rest.key,
          isMultiple: rest.isMultiple,
          multipleFields: rest.isMultiple ? formatJSON(JSON.stringify(value)) : '',
          value: !rest.isMultiple ? value : '',
        },
      }
    })
  }

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalForUpdate(record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await service.delete(record[getIdentifier()])
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalFormState.open && (
          <ConfigFormModal
            afterSubmit={() => {
              refetchRef.current()
              setModalFormState((old) => {
                return {
                  ...old,
                  open: false,
                }
              })
            }}
            onSubmit={modalFormState.onSubmit}
            {...modalFormState}
          />
        )}
      </MountedModalProvider>

      <ListConfiguration
        renderOwnActionMenu={(args) => {
          return renderActionMenu(args)
        }}
        renderCreateButton={() => {
          return (
            <Button type="primary" onClick={() => setModalForCreate()}>
              Create
            </Button>
          )
        }}
        refetchRef={refetchRef}
      />
    </>
  )
}

export default withChangeHeader(ConfigurationPage, 'Configuration')
