import {
  ArrowDownOutlined,
  BookOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  HistoryOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  TeamOutlined,
} from '@ant-design/icons'

interface IconLoaderProps {
  icon: string | null
}

export const IconLoader: React.FC<IconLoaderProps> = (props) => {
  const { icon } = props
  if (!icon) {
    return <></>
  }

  switch (icon) {
    case 'ReadOutlined':
      return <ReadOutlined />
    case 'BookOutlined':
      return <BookOutlined />
    case 'FolderOpenOutlined':
      return <FolderOpenOutlined />
    case 'TeamOutlined':
      return <TeamOutlined />
    case 'CheckCircleOutlined':
      return <CheckCircleOutlined />
    case 'CheckCircleOutlined':
      return <CheckCircleOutlined />
    case 'ExclamationCircleOutlined':
      return <ExclamationCircleOutlined />
    case 'CloseCircleOutlined':
      return <CloseCircleOutlined />
    case 'HistoryOutlined':
      return <HistoryOutlined />
    case 'QuestionCircleOutlined':
      return <QuestionCircleOutlined />
    case 'DollarCircleOutlined':
      return <DollarCircleOutlined />
    case 'ArrowDownOutlined':
      return <ArrowDownOutlined />
    case 'EyeOutlined':
      return <EyeOutlined />
    default:
      return <></>
  }
}
