import { useAuthContext } from '@/context/AuthContext'
import PublisherService from '@/services/publisherService'
import { getIdentifier, getSwrDefaultConfig, repeatFnOnObj } from '@/utils'
import { tryParseJSONObject } from '@/utils/check'
import {
  BookOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Select as AntdSelect,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import currencyType from 'src/constants/currency'
import swrKeys from 'src/constants/swrKey'
import AuthorService from 'src/services/authorService'
import BookService from 'src/services/bookService'
import CategoryService from 'src/services/categoryService'
import handleError from 'src/utils/errorHelper'
import { getDuplicatedFieldNames, selectFilterFunc } from 'src/utils/formHelper'
import styled from 'styled-components'
import useSWR from 'swr'
import * as yup from 'yup'
import Select from '../controls/Select'
import StyledErrorMessage from '../form/StyledErrorMessage'
import useIsAuthor from '../hooks/useIsAuthor'
import UploadComponent from '../other/UploadAvatar'
import ConfigFormModal from '../other/configModal'
import BookUploader, { BookPDFGeneratedPage, BookPagesGallery } from './bookUploader'
import SelectLanguage, { findLanguageByName } from './selectLanguage'
import SelectRent from './selectRent'

interface BookFormProps {
  onSubmit: (data: any, resetForm: Function) => void
  initialValue?: any
  loading: boolean
}

interface FormValues {
  bookId?: string
  name?: string
  rentablePrice: number
  rentDurationType?: string
  rentDuration?: number
  rentablePriceCurrency: currencyType
  saleablePrice: number
  saleablePriceCurrency: currencyType
  paperPrice: number
  tags: string[]
  publishers: {
    publisherId: string
  }[]
  authors: {
    name: string
    authorId: any
  }[]
  categories: string[]
  languageCode?: string
  languageName?: string
  description?: string
  isbn?: string
  page?: string
  size?: string
  cover?: string
  paperBookAvailable?: boolean
  isFree?: boolean
  isRecommend?: boolean
  isRentable?: boolean
  isSaleEbook?: boolean
  isSalePaper?: boolean
  isDownloadable?: boolean
  config?: string
  [index: string]: any
}

let defaultValues: FormValues = {
  bookId: undefined,
  name: '',
  rentablePriceCurrency: currencyType.USD,
  rentablePrice: 0,
  saleablePriceCurrency: currencyType.USD,
  saleablePrice: 0,
  paperPrice: 0,
  tags: [],
  publishers: [],
  authors: [],
  categories: [],
  languageCode: '',
  languageName: '',
  description: undefined,
  isbn: undefined,
  page: undefined,
  size: undefined,
  isFree: false,
  isRecommend: false,
  paperBookAvailable: false,
  cover: undefined,
  configs: `{
    "title": "",
    "description": "",
    "og:type": "website",
    "og:url": "",
    "og:title": "",
    "og:description": "",
    "og:image": "",
    "og:imageWidth": "",
    "og:imageHeight": "",
    "twitter:card": "",
    "twitter:url": "",
    "twitter:title": "",
    "twitter:description": "",
    "twitter:image": "",
    "site_name": ""
  }`,
}

const PublisherFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-top: 8px;
`

const PublisherContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledISBNTagContainer = styled.div`
  display: flex;
  grid-gap: 16px;
`

const bookService = new BookService()

const authorService = new AuthorService()
const categoryService = new CategoryService()
const publisherService = new PublisherService()

const { Option } = AntdSelect

const mapDataToSubmit = (data: FormValues) => {
  const {
    rentablePrice,
    rentablePriceCurrency,
    saleablePriceCurrency,
    saleablePrice,
    categories,
    authors,
    releaseDate,
    configs,
    publishers,
    ...rest
  } = data

  const defaultRelease = new Date()

  let result = {
    ...rest,
    authors: authors
      ? authors.map((item: any) => ({
          id: item.authorId,
        }))
      : [],
    rentablePrice: {
      value: rentablePrice,
      currency: rentablePriceCurrency,
    },
    saleablePrice: {
      value: saleablePrice,
      currency: saleablePriceCurrency,
    },
    categories: categories.map((item: string) => ({
      id: item,
    })),
    configs: tryParseJSONObject(configs),
    releaseDate: releaseDate
      ? releaseDate.toDate().getTime()
      : defaultRelease.getTime(),
    publisher: publishers.map((item) => item.publisherId),
  }

  return result
}

const getPercentCompleted = (progressEvent: any) => {
  let percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total,
  )
  return percentCompleted
}

const StyledPreviewSrcContainer = styled.div`
  .ant-modal {
    top: 5%;
  }
`

const BookForm: React.FC<BookFormProps> = (props) => {
  const { initialValue, onSubmit } = props

  const isAuthor = useIsAuthor()
  const { state } = useAuthContext()

  const formDefaultValue = initialValue || {
    ...defaultValues,
    owner: isAuthor && state.user.id,
  }

  let { id } = useParams()

  const schema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required('Book name is required.'),
      rentablePrice: yup.number().required('Rent price is required.'),
      rentablePriceCurrency: yup.string().required('Rent currency is required.'),
      saleablePrice: yup.number().required('Sale price is required.'),
      saleablePriceCurrency: yup.string().required('Sale currency is required.'),
      tags: yup.array().of(yup.string()),
      publishers: yup.array().of(
        yup.object().shape({
          publisherId: yup.mixed().required('Publisher is required.'),
        }),
      ),
      salePrice: yup.number().nullable(),
      rentPrice: yup.number().nullable(),
      languageCode: yup.string(),
      languageName: yup.string().required('Language name is required.'),
      description: yup.string().nullable(),
      isbn: yup.string().nullable(),
      owner: id ? undefined : yup.string().required('Owner is required.'),
    })
  }, [id])

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    reset,
    watch,
  } = useForm({
    defaultValues: formDefaultValue,
    resolver: yupResolver(schema),
  })

  const {
    fields: fieldPublishers,
    append: appendPublisher,
    remove: removePublisher,
  } = useFieldArray({
    name: 'publishers',
    control,
  })

  const {
    fields: fieldAuthors,
    append: appendAuthor,
    remove: removeAuthor,
  } = useFieldArray({
    name: 'authors',
    control,
  })

  const [selectedBookPreview, setSelectedBookPreview] = useState<
    BookPDFGeneratedPage[]
  >([])

  const [pdfSrcState, setPdfSrcState] = useState<{
    src: BookPDFGeneratedPage[]
    loading: boolean
    open: boolean
    progress: number
  }>({
    src: initialValue?.allSource || [],
    loading: false,
    open: false,
    progress: 0,
  })

  const uploadText =
    pdfSrcState.progress !== 0 &&
    pdfSrcState.progress !== 100 &&
    `${pdfSrcState.progress}%`

  const authorKey = swrKeys.bookFormAuthorOptions()

  const { data: defaultAuthorOptions = [] } = useSWR<any, any>(
    [authorKey, isAuthor],
    async () => {
      if (isAuthor === undefined) {
        return []
      }

      try {
        const res = isAuthor
          ? await authorService.authorGetAll({
              paged: false,
            })
          : await authorService.getAll({
              paged: false,
            })

        return res.data
      } catch (e) {
        handleError(e)
      }
    },
    getSwrDefaultConfig(),
  )

  const { data: defaultCategoryOptions = [] } = useSWR<any, any>(
    swrKeys.bookFormCategoryOptions(),
    async () => {
      try {
        const res = await categoryService.getAll({
          paged: false,
        })

        return res.data
      } catch (e) {
        handleError(e)
      }
    },
    getSwrDefaultConfig(),
  )

  const { data: publisherOptions = [] } = useSWR<any, any>(
    swrKeys.publisherOptions(),
    async () => {
      try {
        const res = await publisherService.getAll({
          paged: false,
        })

        return res.data.map((item) => {
          return {
            ...item,
            publisherId: item.id,
          }
        })
      } catch (e) {
        handleError(e)
      }
    },
    getSwrDefaultConfig(),
  )

  const watchPublisher = watch('publishers')

  const getPublisherOption = useCallback(
    (includeId: number) => {
      return publisherOptions.filter((item) => {
        return (
          item.publisherId == includeId ||
          !watchPublisher.some((w) => w.publisherId == item.publisherId)
        )
      })
    },
    [publisherOptions, watchPublisher],
  )

  const getAuthorOptions = useCallback(() => {
    return defaultAuthorOptions
  }, [defaultAuthorOptions])

  const { loading: pLoading } = props
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(pLoading)

  useEffect(() => {
    setSubmitDisabled(pLoading)
  }, [pLoading])

  const watchRentPrice = watch('rentablePrice')

  const watchRentDuration = watch('rentDuration')

  const watchRentDurationType = watch('rentDurationType')

  return (
    <>
      <Form
        layout={'vertical'}
        onFinish={handleSubmit(async (value) => {
          let authorErrors = getDuplicatedFieldNames({
            arr: value.authors || [],
            keysToCompare: ['authorId'],
            prefix: 'authors',
          })

          if (authorErrors.length > 0) {
            authorErrors.forEach((item) => {
              setError(item, {
                type: 'Manual',
                message: 'Duplicate author',
              })
            })

            return
          }

          const resetForm = () => {
            const { bookId, page, size, isFree } = defaultValues
            const valuesToReset = { bookId, page, size, isFree }

            repeatFnOnObj(valuesToReset, ({ key, value }) => {
              setValue(key, value)
            })()

            reset(defaultValues)
            setPdfSrcState((old) => ({
              ...old,
              src: [],
            }))
            setSelectedBookPreview([])
          }
          value = {
            ...value,
            bookPreview: selectedBookPreview,
            bookSource: pdfSrcState.src,
          }
          onSubmit(mapDataToSubmit(value), resetForm)
        })}
      >
        <Controller
          render={() => {
            return null
          }}
          name="bookId"
          control={control}
          defaultValue={undefined}
        />

        <Controller
          render={() => {
            return null
          }}
          name="page"
          control={control}
          defaultValue={undefined}
        />

        <Controller
          render={() => {
            return null
          }}
          name="size"
          control={control}
          defaultValue={undefined}
        />

        <StyledPreviewSrcContainer id={`modal-src-preview`}>
          <Modal
            title={'Choose pages for preview'}
            open={pdfSrcState.open}
            okButtonProps={{
              style: {
                display: 'none',
              },
            }}
            bodyStyle={{
              height: `75vh`,
              overflow: `auto`,
            }}
            getContainer={() => document.getElementById(`modal-src-preview`)}
            centered={false}
            cancelText={'Close'}
            onCancel={() => {
              setPdfSrcState((old) => ({ ...old, open: false }))
            }}
          >
            <BookPagesGallery
              data={pdfSrcState.src}
              selected={initialValue?.previewSource || []}
              onChange={(files) => {
                setSelectedBookPreview(files)
              }}
            />
          </Modal>
        </StyledPreviewSrcContainer>
        <Form.Item label="Cover">
          <Controller
            name="cover"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => {
              return (
                <UploadComponent
                  imageUrl={value}
                  onRemove={() => {
                    onChange()
                  }}
                  onUpload={(file) => {
                    onChange(file.url)
                  }}
                />
              )
            }}
          />
        </Form.Item>

        <Form.Item label="Upload">
          <BookUploader
            uploadTrigger={() => {
              return (
                <>
                  <Button icon={<UploadOutlined />} disabled={pdfSrcState.loading}>
                    Upload book(pdf) {uploadText}
                  </Button>
                </>
              )
            }}
            onUpload={async (file) => {
              setPdfSrcState((old) => ({
                ...old,
                loading: true,
              }))

              try {
                let formBookId = getValues('bookId')

                let res: any
                let bookRes: any

                if (!formBookId) {
                  bookRes = await bookService.create({})
                  formBookId = bookRes?.data[getIdentifier()]
                }

                res = await bookService.generatePdf(formBookId, {
                  file,
                  // @ts-ignore
                  axiosOption: {
                    onUploadProgress: function (progressEvent: any) {
                      const percent = getPercentCompleted(progressEvent)

                      setPdfSrcState((old) => ({
                        ...old,
                        progress: percent,
                      }))
                    },
                  },
                })

                res = res.data.data

                const { links, totalPage: page, totalSize: size } = res

                setPdfSrcState((old) => ({
                  ...old,
                  src: links,
                  loading: false,
                }))

                const valueToUpdate = {
                  bookId: formBookId,
                  page,
                  size,
                }

                repeatFnOnObj(valueToUpdate, ({ key, value }) =>
                  setValue(key, value),
                )()

                setSubmitDisabled(false)
              } catch (e) {
                handleError(e)
                setPdfSrcState((old) => ({
                  ...old,

                  loading: false,
                }))
                setSubmitDisabled(true)
              }
            }}
          />
          <Button
            onClick={() => {
              setPdfSrcState((old) => ({ ...old, open: true }))
            }}
            icon={<BookOutlined />}
            disabled={pdfSrcState.loading || pdfSrcState.src.length === 0}
          />
        </Form.Item>

        <Form.Item label="Name" requiredMark>
          <Controller
            render={({ field }) => <Input {...field} />}
            name="name"
            control={control}
            defaultValue={''}
          />
          <StyledErrorMessage errors={errors} name={'name'} />
        </Form.Item>

        <Form.Item label="Description">
          <Controller
            render={({ field }) => <Input.TextArea {...field} />}
            name="description"
            control={control}
            defaultValue={''}
          />
        </Form.Item>

        <Form.Item label="Genres">
          <Controller
            name="categories"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  value={value}
                  mode="multiple"
                  onChange={(value) => {
                    onChange(value)
                  }}
                >
                  {defaultCategoryOptions.map((item: any) => {
                    return (
                      <Option
                        value={item[getIdentifier()]}
                        key={item[getIdentifier()]}
                      >
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              )
            }}
          />
          {/* <StyledErrorMessage errors={errors} name={'categories'} /> */}
        </Form.Item>

        <div
          style={{
            display: 'flex',
            gridGap: 16,
          }}
        >
          <Form.Item
            label="Paper Price"
            style={{
              width: '100%',
            }}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )
              }}
              name="paperPrice"
              control={control}
              defaultValue={null}
            />
          </Form.Item>

          <Controller
            render={({ field: { onChange } }) => {
              return null
            }}
            name="paperPriceCurrency"
            control={control}
            defaultValue={currencyType.USD}
          />

          <Form.Item
            label="Sale Price"
            style={{
              width: '100%',
            }}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )
              }}
              name="saleablePrice"
              control={control}
              defaultValue={null}
            />

            <Controller
              render={({ field: { onChange } }) => {
                return null
              }}
              name="saleablePriceCurrency"
              control={control}
              defaultValue={currencyType.USD}
            />
          </Form.Item>
        </div>

        <Form.Item
          label="Rent Price"
          style={{
            width: '100%',
          }}
          wrapperCol={{
            span: 12,
          }}
        >
          <div style={{ display: 'flex' }}>
            <SelectRent
              propsPrice={{
                onChange: (e) => {
                  setValue(`rentablePrice`, e.target.value)
                },
                value: watchRentPrice,
              }}
              propsDuration={{
                onChange: (e) => {
                  setValue(`rentDuration`, e.target.value)
                },
                value: watchRentDuration,
              }}
              propsDurationType={{
                onChange: (value) => {
                  setValue(`rentDurationType`, value)
                },
                value: watchRentDurationType,
              }}
            />

            <Controller
              name="rentablePriceCurrency"
              control={control}
              defaultValue={currencyType.USD}
              render={() => {
                return null
              }}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="Owner"
          wrapperCol={{
            span: 8,
          }}
          style={{
            display: isAuthor ? `none` : `block`,
          }}
        >
          <Controller
            name={`owner`}
            control={control}
            render={({ field: { onChange, value, ...props } }) => {
              let authorOptions = getAuthorOptions()

              if (authorOptions && authorOptions.length === 0) {
                return <React.Fragment />
              }
              return (
                <Select
                  onChange={(value: any) => {
                    onChange(value)
                  }}
                  defaultValue={{
                    value: value,
                  }}
                  value={value}
                  showSearch
                  filterOption={selectFilterFunc}
                >
                  {authorOptions.map((item: any, index: number) => {
                    return (
                      <Option key={index} value={item[getIdentifier()]}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              )
            }}
          />
          <StyledErrorMessage errors={errors} name={`owner`} />
        </Form.Item>

        <Form.Item label="Authors">
          <Button
            type={'dashed'}
            onClick={() => {
              appendAuthor({
                publisherId: '',
                id: null,
              })
            }}
          >
            Add Authors
          </Button>

          <PublisherFieldsContainer
            style={
              fieldAuthors.length > 0 ? { display: 'grid' } : { display: 'none' }
            }
          >
            {fieldAuthors.map((field: any, index) => {
              return (
                <Controller
                  key={field.id}
                  defaultValue={field.name}
                  name={`authors[${index}].authorId`}
                  control={control}
                  render={({ field: { onChange, value, ...props } }) => {
                    return (
                      <PublisherContainer>
                        <Form.Item label="Name">
                          <div style={{ display: 'flex' }}>
                            <Select
                              onChange={(value: any) => {
                                onChange(value)
                              }}
                              value={value}
                              showSearch
                              filterOption={selectFilterFunc}
                            >
                              {getAuthorOptions().map((item: any, index: number) => {
                                return (
                                  <Option key={index} value={item[getIdentifier()]}>
                                    {item.name}
                                  </Option>
                                )
                              })}
                            </Select>
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                removeAuthor(index)
                              }}
                            />
                          </div>

                          <StyledErrorMessage
                            errors={errors}
                            name={`authors[${index}].authorId`}
                          />
                        </Form.Item>
                      </PublisherContainer>
                    )
                  }}
                />
              )
            })}
          </PublisherFieldsContainer>
        </Form.Item>

        <Form.Item label="Publishers">
          <Button
            type={'dashed'}
            onClick={() => {
              appendPublisher({
                name: '',
              })
            }}
          >
            Add Publisher
          </Button>

          <PublisherFieldsContainer
            style={
              fieldPublishers.length > 0 ? { display: 'grid' } : { display: 'none' }
            }
          >
            {fieldPublishers.map((field: any, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Controller
                    defaultValue={field.publisherId}
                    render={(props) => {
                      return (
                        <PublisherContainer>
                          <Form.Item label="Name">
                            <div style={{ display: 'flex' }}>
                              <Select
                                onChange={(value) => {
                                  props.field.onChange(value)
                                }}
                                options={getPublisherOption(props.field.value).map(
                                  (item) => {
                                    return {
                                      label: item.name,
                                      value: item.id,
                                    }
                                  },
                                )}
                                value={field.publisherId}
                              />
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  removePublisher(index)
                                }}
                              />
                            </div>

                            <StyledErrorMessage
                              errors={errors}
                              name={`publishers.${index}.publisherId`}
                            />
                          </Form.Item>
                        </PublisherContainer>
                      )
                    }}
                    name={`publishers.${index}.publisherId`}
                    control={control}
                  />
                </React.Fragment>
              )
            })}
          </PublisherFieldsContainer>
        </Form.Item>

        {!isAuthor && (
          <StyledISBNTagContainer>
            <Form.Item label="Released Date" style={{ flexGrow: 1 }}>
              <Controller
                name="releaseDate"
                control={control}
                render={({ field: { onChange } }) => (
                  // @ts-ignore
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      onChange(value)
                    }}
                    defaultValue={
                      initialValue && (moment(initialValue.releaseDate) as any)
                    }
                  />
                )}
              />
            </Form.Item>

            <Form.Item label="ISBN" style={{ flexGrow: 1 }}>
              <Controller
                name="isbn"
                control={control}
                render={() => <Input />}
                defaultValue={''}
              />
            </Form.Item>

            <Form.Item label="Tags" style={{ flexGrow: 1 }}>
              <Controller
                name="tags"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      mode="tags"
                      onChange={(value) => {
                        onChange(value)
                      }}
                    />
                  )
                }}
              />
            </Form.Item>
          </StyledISBNTagContainer>
        )}

        <StyledISBNTagContainer>
          <Form.Item
            label={
              <div>
                Language Code &nbsp;
                <Tooltip title="The abbreviation of the language which the book is written in.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            style={{ flexGrow: 1, display: `none` }}
          >
            <PublisherContainer>
              <Controller
                name="languageCode"
                control={control}
                render={({ field }) => {
                  return <Input {...field} />
                }}
                defaultValue={''}
              />

              <StyledErrorMessage errors={errors} name={`languageCode`} />
            </PublisherContainer>
          </Form.Item>

          <Form.Item label="Language Name" style={{ flexGrow: 1 }}>
            <PublisherContainer>
              {/* <Controller
                name="languageName"
                control={control}
                render={({ field }) => <Input {...field} />}
                defaultValue={''}
              /> */}

              <Controller
                name="languageName"
                control={control}
                render={({ field }) => (
                  <SelectLanguage
                    {...field}
                    onChange={(value) => {
                      const languageObj = findLanguageByName(value)

                      setValue('languageCode', languageObj.code)

                      field.onChange(value)
                    }}
                  />
                )}
                defaultValue={''}
              />

              <StyledErrorMessage errors={errors} name={`languageName`} />
            </PublisherContainer>
          </Form.Item>
        </StyledISBNTagContainer>

        <Form.Item label="Configs">
          <Controller
            name="configs"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <ConfigFormModal
                  defaultValue={value}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              )
            }}
          />
        </Form.Item>

        <Space size={16}>
          <Form.Item label="Free">
            <Controller
              render={({ field: { onChange, value, ...rest } }) => {
                return (
                  <Checkbox
                    onChange={(e: any) => {
                      onChange(e.target.checked)
                    }}
                    checked={value}
                    {...rest}
                  />
                )
              }}
              name="isFree"
              control={control}
              defaultValue={false}
            />
          </Form.Item>

          {!isAuthor && (
            <Form.Item label="Recommend">
              <Controller
                render={({ field: { onChange, value, ...rest } }) => {
                  return (
                    <Checkbox
                      onChange={(e: any) => {
                        onChange(e.target.checked)
                      }}
                      checked={value}
                      {...rest}
                    />
                  )
                }}
                name="isRecommend"
                control={control}
                defaultValue={false}
              />
            </Form.Item>
          )}

          {!isAuthor && (
            <Form.Item
              label="Paper book avaialable"
              style={{
                display: `none`,
              }}
            >
              <Controller
                render={({ field: { onChange, value, ...rest } }) => {
                  return (
                    <Checkbox
                      onChange={(e: any) => {
                        onChange(e.target.checked)
                      }}
                      checked={value}
                      {...rest}
                    />
                  )
                }}
                name="paperBookAvailable"
                control={control}
                defaultValue={false}
              />
            </Form.Item>
          )}

          <Form.Item label="Rentable">
            <Controller
              render={({ field: { onChange, value, ...rest } }) => {
                return (
                  <Checkbox
                    onChange={(e: any) => {
                      onChange(e.target.checked)
                    }}
                    checked={value}
                    {...rest}
                  />
                )
              }}
              name="isRentable"
              control={control}
              defaultValue={false}
            />
          </Form.Item>

          <Form.Item label="Sale Ebook">
            <Controller
              render={({ field: { onChange, value, ...rest } }) => {
                return (
                  <Checkbox
                    onChange={(e: any) => {
                      onChange(e.target.checked)
                    }}
                    checked={value}
                    {...rest}
                  />
                )
              }}
              name="isSaleEbook"
              control={control}
              defaultValue={false}
            />
          </Form.Item>

          <Form.Item label="Sale Paper">
            <Controller
              render={({ field: { onChange, value, ...rest } }) => {
                return (
                  <Checkbox
                    onChange={(e: any) => {
                      onChange(e.target.checked)

                      setValue(`paperBookAvailable`, e.target.checked)
                    }}
                    checked={value}
                    {...rest}
                  />
                )
              }}
              name="isSalePaper"
              control={control}
              defaultValue={false}
            />
          </Form.Item>

          {!isAuthor && (
            <Form.Item label="Downloadable">
              <Controller
                render={({ field: { onChange, value, ...rest } }) => {
                  return (
                    <Checkbox
                      onChange={(e: any) => {
                        onChange(e.target.checked)
                      }}
                      checked={value}
                      {...rest}
                    />
                  )
                }}
                name="isDownloadable"
                control={control}
                defaultValue={false}
              />
            </Form.Item>
          )}
        </Space>

        <Form.Item>
          <Button htmlType={'submit'} type={'primary'} disabled={submitDisabled}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default BookForm
