import { getIdentifier } from '@/utils'
import { formatJSON, tryParseJSONObject } from '@/utils/check'
import {
  BellOutlined,
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Badge, Button, Dropdown, Menu, MenuProps } from 'antd'
import { useState } from 'react'
import { useAuthContext } from 'src/context/AuthContext'
import UserService from 'src/services/backendUserService'
import handleError from 'src/utils/errorHelper'
import styled from 'styled-components'
import AppInfo from '../appInfo'
import ProfileAuthorFormModal, {
  modalProfileAuthorInterface,
} from './ProfileAuthorFormModal'
import ChangeCustomerPasswordForm from '../customer/ChangeCustomerPasswordFormModal'
import useIsAuthor from '../hooks/useIsAuthor'
import MountedModalProvider from '../modal/MountedModalProvider'
import AuthorService from 'src/services/authorService'
import Notification from '../notification'

const ProfileLayout = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 16px);
  grid-template-columns: 48px auto;
  align-items: center;
`

const Container = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  & > div {
    padding: 0 12px;
    box-sizing: border-box;
  }
`

const MenuItem = `
    transition: background-color 0.5s ease;
    cursor: pointer;
    &:hover{
        background-color: rgba(0,0,0,.025);
    }
`

const DropdownArea = styled.div`
  ${MenuItem}
  height: 100%;
  display: flex;
  align-items: center;
  .ant-dropdown {
    top: 72px !important;
  }
  .ant-dropdown-menu {
    padding: 0;
  }
`

// @ts-ignore
const ProfileMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .anticon {
    margin-top: 1px;
  }
`

const ProfileContainer = styled.div`
  font-size: 13px;
`

const dropdownAreaId = 'dropdown-area'

const userService = new UserService()

const authorService = new AuthorService()

const BaseLayoutRightHeader = () => {
  const {
    logout,
    updateUser,
    state: { user },
  } = useAuthContext()

  const [changePasswordVisible, setChangePasswordVisible] = useState(false)

  const isAuthor = useIsAuthor()

  const renderProfileMenu = () => {
    const items: MenuProps['items'] = [
      {
        key: 'change-password',
        label: (
          <ProfileMenu
            onClick={() => {
              setChangePasswordVisible(true)
            }}
          >
            <KeyOutlined />
            Change Password
          </ProfileMenu>
        ),
      },

      {
        key: 'logout',
        label: (
          <ProfileMenu
            onClick={() => {
              logout()
            }}
          >
            <LogoutOutlined />
            Logout
          </ProfileMenu>
        ),
      },
    ]

    if (isAuthor) {
      const updateAuthorMenu = {
        key: 'update-author-profile',
        label: (
          <ProfileMenu
            onClick={() => {
              const { configs, profile } = user as any

              const {
                name,
                avatar,
                bio,
                email,
                phoneNumber,
                salutation,
                socialLinks,
                memo,
              } = profile || {}

              setModalAuthorState((old) => ({
                ...old,
                open: true,
                title: 'Update Author',
                onSubmit: async (data) => {
                  try {
                    delete data.image

                    const res = await authorService.updateUser({
                      data: { ...data, configs: tryParseJSONObject(data.configs) },
                    })
                    setModalAuthorState((old) => ({
                      ...old,
                      open: false,
                    }))

                    updateUser(res.data)
                    // authorRefetchRef.current()
                  } catch (e) {
                    handleError(e)
                  }
                },
                onCancel: () => {
                  setModalAuthorState(() => {
                    return { ...old, open: false }
                  })
                },
                formData: {
                  name,
                  avatar,
                  bio,
                  email,
                  phone: phoneNumber?.value,
                  salutation,
                  socialLinks,
                  memo,
                  configs: formatJSON(JSON.stringify(configs)),
                },
              }))
            }}
          >
            <UserOutlined />
            Update Profile
          </ProfileMenu>
        ),
      }

      items.splice(1, 0, updateAuthorMenu)
    }

    return items
  }

  const [modalAuthorState, setModalAuthorState] =
    useState<modalProfileAuthorInterface>({
      open: false,
      onSubmit: async () => {},
      formData: {
        name: '',
      },
    })

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        <ChangeCustomerPasswordForm
          title={'Change Password'}
          open={changePasswordVisible}
          onSubmit={async (data) => {
            const { password, confirm } = data

            let req = isAuthor
              ? userService.authorResetPassword
              : userService.resetPassword

            try {
              await req({
                password,
                confirmPassword: confirm,
              })
              setChangePasswordVisible(false)
            } catch (e) {
              handleError(e)
            }
          }}
          onCancel={() => {
            setChangePasswordVisible(false)
          }}
        />

        {modalAuthorState.open && <ProfileAuthorFormModal {...modalAuthorState} />}
      </MountedModalProvider>

      <Container>
        <Notification />

        <DropdownArea id={dropdownAreaId}>
          <Dropdown
            menu={{ items: renderProfileMenu() }}
            placement="bottomRight"
            trigger={['click']}
            arrow={{ pointAtCenter: true }}
            getPopupContainer={() => document.getElementById(dropdownAreaId)!}
          >
            <ProfileContainer>
              <ProfileLayout>
                <Avatar
                  size={36}
                  icon={
                    <img
                      src={user?.profile && user.profile.avatar}
                      alt="Profile Avatar"
                    />
                  }
                  style={{
                    gridRow: '1/3',
                  }}
                />

                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {(user?.profile &&
                    (user.profile.displayName || user.profile.name)) ||
                    user?.name ||
                    user?.username ||
                    'Anonymous'}
                </span>

                {user?.profile?.email && (
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {user.profile.email}
                  </span>
                )}
              </ProfileLayout>
            </ProfileContainer>
          </Dropdown>
        </DropdownArea>

        <AppInfo />
      </Container>
    </>
  )
}

export default BaseLayoutRightHeader
