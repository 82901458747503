import React, { FunctionComponent, useEffect, useState } from 'react'
import { Select } from 'antd'

interface SelectCustomProps {
  onChange?: (value: any) => void
  data: any[]
  placeholder?: string
  defaultValue?: any
  style?: any
  keyValue?: string
}

const { Option } = Select

const SelectCustom: FunctionComponent<SelectCustomProps> = (props) => {
  const [value, setValue] = useState()
  const { onChange, data, keyValue } = props
  useEffect(() => {
    typeof onChange === 'function' && onChange(value)
  }, [value])

  function onHandleChange(value: any) {
    setValue(value)
  }

  function onSearch(val: any) {
    console.log('search:', val)
  }
  return (
    <Select
      defaultValue={props.defaultValue}
      showSearch
      value={value}
      style={props.style}
      placeholder={typeof props.placeholder}
      optionFilterProp="children"
      onChange={onHandleChange}
      onSearch={onSearch}
      filterOption={(input, option) => {
        //@ts-ignore
        return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }}
    >
      {data.map((it, index) => {
        return (
          <Option value={it} key={index}>
            {keyValue === undefined ? it : it[keyValue]}
          </Option>
        )
      })}
    </Select>
  )
}

export default SelectCustom
