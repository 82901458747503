import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Input, Space, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { tryParseJSONObject } from 'src/utils/check'
import styled from 'styled-components'
import * as yup from 'yup'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import StyledErrorMessage from '../form/StyledErrorMessage'
import CodeEditor from '../input/CodeEditor'

const layout: any = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const schema = yup.object().shape({
  valueKey: yup.string().required('Key is required'),
})

const restValueSchema = yup.object().shape({
  value: yup.string().required('Value is required'),
})

interface FormValues {
  valueKey: string
  isMultiple: boolean
  value: string
  multipleFields: string
}

const defaultValues: FormValues = {
  valueKey: '',
  isMultiple: false,
  value: '',
  multipleFields: '',
}

interface ConfigFormProps {
  formData?: FormValues
  afterSubmit: () => void
  onSubmit: (data: any) => void
  [index: string]: any
}

const StyledSpace = styled(Space)`
  width: 100%;
  align-items: flex-start;

  .ant-space-item {
    width: 100%;
  }

  .ant-space-item:last-child {
    width: min-content;
  }
`

const ConfigFormModal: React.FC<ConfigFormProps> = (props) => {
  const { formData, afterSubmit, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const watchMultiple = watch('isMultiple', false)

  const [disabled, setDisabled] = useState(false)

  const monacoRef = useRef<any>()

  const onSubmit = async (values: FormValues) => {
    if (!values.isMultiple) {
      try {
        restValueSchema.validateSync({
          value: values.value,
        })
      } catch (e) {
        console.log(e)
        //@ts-ignore
        const errorMessage = e.errors[0]

        setError('value', {
          type: 'manual',
          message: errorMessage,
        })
        return
      }
    }
    let restValue: any
    try {
      if (values.isMultiple) {
        let json = tryParseJSONObject(values.multipleFields)
        if (!json) {
          throw new Error('')
        }
        restValue = { value: JSON.stringify(json) }
      } else {
        restValue = { value: values.value }
      }
    } catch (e) {
      console.error(e)
      setError('multipleFields', {
        type: 'manual',
        message: 'Check error in value ',
      })
      return
    }

    setDisabled(true)
    try {
      await props.onSubmit({
        ...restValue,
        key: values.valueKey,
        isMultiple: values.isMultiple,
      })

      afterSubmit()
    } catch (e) {
      console.error(e)
    }
    setDisabled(false)
  }

  useEffect(() => {
    if (formData) {
      const formValues = getValues()
      const result = {
        ...formValues,
        ...formData,
      }

      reset(result)
    }
  }, [formData])

  const renderRestForm = () => {
    return (
      <>
        <div
          style={{
            display: watchMultiple ? 'block' : 'none',
            height: '200px',
          }}
        >
          <>
            <label>Value</label>
            <Controller
              name="multipleFields"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <CodeEditor
                    value={value}
                    editorDidMount={(...monaco) => {
                      monacoRef.current = monaco
                    }}
                    language={'json'}
                    onChange={(value) => {
                      onChange(value)
                    }}
                  />
                )
              }}
            />
            <StyledErrorMessage name={'multipleFields'} errors={errors} />
          </>
        </div>
        <div
          style={{
            display: watchMultiple ? 'none' : 'block',
          }}
        >
          <Form.Item
            label="Value"
            validateStatus={errors.value && 'error'}
            help={errors.value ? errors.value.message : ''}
            requiredMark={true}
          >
            <Controller
              render={({ field }) => <Input {...field} />}
              name="value"
              control={control}
            />
          </Form.Item>
        </div>
      </>
    )
  }

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={600}
      initialWidth={window.innerWidth * 0.6}
      footer={null}
    >
      <Form {...layout}>
        <Form.Item label="Multiple" requiredMark={true}>
          <Controller
            name="isMultiple"
            render={({ field: { value } }) => {
              return (
                <Switch
                  checked={value}
                  onChange={(checked) => {
                    const values = getValues()
                    reset({
                      ...values,
                      multipleFields: '',
                      isMultiple: checked,
                    })
                  }}
                />
              )
            }}
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Key"
          validateStatus={errors.valueKey && 'error'}
          help={errors.valueKey ? errors.valueKey.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => <Input {...field} />}
            name="valueKey"
            control={control}
          />
        </Form.Item>

        {renderRestForm()}

        <ModalFooter>
          <Space>
            <Button
              disabled={disabled}
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default ConfigFormModal
