import { Card } from 'antd'
import ApexChart, { Props as ApexChartProps } from 'react-apexcharts'
import styled from 'styled-components'

const ChartContainer = styled(Card)`
  width: 100%;
  height: auto;

  /* margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 10px;
  border: 1px solid #e8e8e8;
  font-size: 14px; */
`

interface ChartProps {
  title?: string
  chartProps?: ApexChartProps
  props?: any
}

const Chart: React.FC<ChartProps> = (props) => {
  const { title, chartProps = {}, props: containerProps = {} } = props

  if (!chartProps.type) {
    return <></>
  }

  return (
    <ChartContainer title={title} {...containerProps}>
      <ApexChart {...chartProps} />
    </ChartContainer>
  )
}

export default Chart
