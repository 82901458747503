import React, { useState } from 'react'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form, Popconfirm } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const schema = yup.object().shape({})

export interface FormData {
  balance: number
  requestBalance: number
  id: any
}

const UpdateForm: React.FC<{
  defaultValues: FormData
  onSubmit: (data: any) => Promise<void>
  onCancel: () => void
}> = (props) => {
  const { defaultValues, onSubmit, onCancel, ...rest } = props
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const [confirmVisible, setConfirmVisible] = useState(false)

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const doSubmit = () => {
    handleSubmit(async (data) => {
      setSubmitDisabled(true)
      await onSubmit(data)
      setSubmitDisabled(false)
    })()
  }

  return (
    <ModalFormContainer
      {...rest}
      title={'Approve Deposit'}
      visible={defaultValues !== null}
      initialHeight={350}
      initialWidth={350}
      footer={null}
      closable={false}
    >
      <Form {...layout}>
        <Form.Item label="Request Balance">
          <Controller
            render={({ field: { value } }) => {
              return <Input value={value} disabled />
            }}
            name="requestBalance"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Balance"
          validateStatus={errors.balance && 'error'}
          help={errors.balance ? errors.balance.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => <Input {...field} />}
            name="balance"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Popconfirm
              title="Request balance and actual balance are not the same. Are you sure?"
              okText="Yes"
              cancelText="No"
              onCancel={() => {
                setConfirmVisible(false)
              }}
              open={confirmVisible}
              okButtonProps={{
                disabled: submitDisabled,
              }}
              onConfirm={doSubmit}
            >
              <Button
                type={'primary'}
                disabled={submitDisabled}
                onClick={async () => {
                  const { requestBalance, balance } = getValues()

                  if (requestBalance != balance) {
                    setConfirmVisible(true)
                  } else {
                    doSubmit()
                  }
                }}
              >
                Approve
              </Button>
            </Popconfirm>

            <Button
              onClick={(e) => {
                props.onCancel()
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default UpdateForm
