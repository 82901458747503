import { FileImageOutlined } from '@ant-design/icons'
import { useDebounceFn } from 'ahooks'
import { DraggableModal } from 'ant-design-draggable-modal'
import { Button, DatePicker, Modal, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import { DepositStatus } from 'src/services/depositService'
import ReportService from 'src/services/reportService'
import { getIdentifier, getStatusColor, getSwrDefaultConfig } from '@/utils'
import { dateTimeformat, formatType } from 'src/utils/dateTime'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import styled from 'styled-components'
import useSWR from 'swr'
import { titleCase, words } from 'voca'
import MountedModalProvider from '../modal/MountedModalProvider'
import BodyContainer from '../other/bodyContainer'
import BoldLabelValue from '../other/text'
import TransactionDetail from '../transaction/transactionDetail'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'

const { RangePicker } = DatePicker

const AttachmentImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  img {
    object-fit: contain;
    width: 100%;
  }
`

const reportService = new ReportService()

const ReportAuthorTrasactionTable = () => {
  const { data, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.deposit(),
    null,
    getSwrDefaultConfig(),
  )

  interface PaginationReportRequestParams extends PaginationRequestParams {
    [index: string]: any
  }

  const [reqParams, setReqParams] = useState<PaginationReportRequestParams>(
    defaultPaginationRequestParams,
  )
  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = await reportService.getAllAuthorReportTransaction({
          ...reqParams,
        })
        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetch()
  }, [reqParams])

  let columns: newColumnsInterface[] = [
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value) => {
        let date = value
          ? dateTimeformat({ value: value, format: formatType.dateTime })
          : ''
        return date
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balanceNormalized',
      render: (value) => {
        return value.normalized
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Withdrawal',
      dataIndex: 'withdrawal',
    },
    {
      title: 'Status',
      dataIndex: 'rejected',
      render: (value) => {
        if (reqParams.type === 'BLOCK' || reqParams.type === undefined) {
          return ''
        }
        const text = value ? 'Rejected' : 'Accepted'
        return (
          <span
            style={{
              color: getStatusColor(text),
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  const { run: updateQInReq } = useDebounceFn(
    (data: any) => {
      data
        ? setReqParams({
            page: 0,
            size: reqParams.size,
            startDate: moment(data[0]).valueOf(),
            endDate: moment(data[1]).valueOf(),
          })
        : setReqParams({
            page: 0,
            size: reqParams.size,
          })
    },
    {
      wait: 2000,
    },
  )

  const handleDateOnChange = (date: any) => {
    updateQInReq(date)
  }

  const renderActionMenu = (args: any) => {
    const { record: currentRecord } = args

    const subscriptions = currentRecord?.details?.subscriptions || []

    let menus: TableActionMenuProps['menus'] = []

    if (subscriptions.length > 0 || subscriptions) {
      menus = [
        {
          render: () => {
            return (
              <div
                onClick={() => {
                  setCurrentRecord({
                    ...currentRecord,
                    details: {
                      ...currentRecord.details,
                      subscriptions: [subscriptions],
                    },
                  })
                }}
              >
                Detail
              </div>
            )
          },
          key: 'Detail',
        },
      ]
    }

    return <TableActionMenu menus={menus} />
  }

  const [currentRecord, setCurrentRecord] = useState<any>()

  return (
    <>
      <Modal
        title={'Transaction Detail'}
        open={Boolean(currentRecord)}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelText={'Close'}
        onCancel={() => {
          setCurrentRecord(undefined)
        }}
      >
        <TransactionDetail {...currentRecord} />
      </Modal>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
              footer:
                data?.extra &&
                (() => (
                  <div>
                    Total:
                    <span style={{ color: getStatusColor('accepted') }}>
                      {data?.extra?.totalTransaction?.normalized}
                    </span>
                  </div>
                )),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  <Button
                    onClick={() => {
                      refetch()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={data ? data.data : []}
            name="report-author-transaction-table"
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            renderOwnSearchInput={() => {
              return (
                // @ts-ignore
                <RangePicker
                  format={formatType.date}
                  onChange={handleDateOnChange}
                />
              )
            }}
            renderOwnActionMenu={renderActionMenu}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default ReportAuthorTrasactionTable
