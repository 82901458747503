import React from 'react'
import styled from 'styled-components'
import { DraggableModal } from 'ant-design-draggable-modal'

const StyledContainer = styled.div`
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 32px);
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-end;
  background: white;
`

const ModalFormContainer = styled(DraggableModal)<{ gap?: number }>`
  & .ant-modal-content {
    position: relative;
  }

  & .ant-modal-body {
    margin-bottom: ${({ gap = 65 }) => gap}px;
  }
`

interface ModalFooterProps {
  [index: string]: any
}

export const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return (
    <>
      <StyledContainer {...props} />
    </>
  )
}

export default ModalFormContainer
