import { useAuthorize } from './useAuthorize'

const useIsAuthor = () => {
  const isAuthor = useAuthorize({
    or: {
      roles: ['AUTHOR'],
    },
  })

  return isAuthor
}

export default useIsAuthor
