export const selectFilterFunc = (input: string, option: any) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const getDuplicatedFieldNames = (args: {
  arr: any[]
  keysToCompare: string[]
  prefix?: string
}) => {
  const { arr, keysToCompare, prefix = '' } = args
  let result: string[] = []
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      if (i !== j) {
        const duplicated = keysToCompare.every((key) => arr[i][key] === arr[j][key])

        if (duplicated) {
          result.push(
            ...keysToCompare.map(
              (key) => `${prefix ? prefix + '.' : ''}[${i}].${key}`,
            ),
          )
        }
      }
    }
  }

  return result
}
