import React from 'react'
import Icon from '@ant-design/icons'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import {
  BUILD_DATE,
  BUILD_NUMBER,
  COMMIT_ID,
  PACKAGE_VERSION,
} from '../../app.config'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const Align = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px 20px;
`

const ButtonAlign = styled.div`
  text-align: right;
`

const AppInfo = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <>
      <Icon
        onClick={() => setIsModalVisible(true)}
        component={() => (
          <QuestionCircleOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
        )}
      />
      <Modal
        title="Platform information"
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Align>
          <p>Version</p>
          <p>{': ' + PACKAGE_VERSION}</p>
          <p>Build Number</p>
          <p>{': ' + BUILD_NUMBER}</p>
          <p>Build Date</p>
          <p>{': ' + BUILD_DATE}</p>
          <p>Commit ID</p>
          <p>{': ' + COMMIT_ID}</p>
        </Align>
        <ButtonAlign>
          <Link to={`/reset`}>
            <Button type="primary">Reset</Button>
          </Link>
        </ButtonAlign>
      </Modal>
    </>
  )
}

export default AppInfo
