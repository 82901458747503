import DashboardPage from '@/pages/dashboard'
import Login from '@/components/login'
import { useRouterStore } from '@/store'
import handleError from '@/utils/errorHelper'
import {
  AreaChartOutlined,
  BookOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import ReportDepositPage from 'src/pages/report/ReportDeposit'
import RouteReset from 'src/pages/reset'
import BackEndUserPage from '../../pages/backendUser'
import BookPage from '../../pages/book'
import CreateBookPage from '../../pages/book/BookFormPage'
import ConfigurationPage from '../../pages/configuration'
import CustomerPage from '../../pages/customer'
import DepositPage from '../../pages/deposit'
import PaymentMethodPage from '../../pages/paymentMethod'
import TransactionPage from '../../pages/transaction'
import BookDetail from '../book/BookDetail'
import BookLayout from '../book/BookLayout'
import BaseLayout from '../layouts/BaseLayout'
import SecurityLayout from '../layouts/SecurityLayout'
import ProtectedRoute from '../protected'
import ReportLayout from '../report/ReportLayout'
import { RouteTypes } from './types'
import AuthorLogin from '@/pages/login/AuthorLogin'
import MainLogin from '@/pages/login/MainLogin'
import { useAuthContext } from '@/context/AuthContext'
import { useLocation } from 'react-router-dom'
import { setAxiosBaseUrl } from '@/utils'
import useIsAuthor from '../hooks/useIsAuthor'
import { RouteObject } from 'react-router'
import Home from '../home'
import ReportAuthorTrasaction from '@/pages/report/ReportAuthorTrasaction'
import DeliveryFeePage from '@/pages/deliveryFee'
import LocationPickupPage from '@/pages/locationPickup'
import Test from '@/pages/test/pov'
import PublisherPage from '@/pages/publisher'
import NotificationOfferPage from '@/pages/notification'

export interface RouteObj {
  key?: string | null
  headerLabel?: string
  isMenu?: boolean
  icon?: any
  label?: string
  children?: RouteObj[]
  hideFor?: 'AUTHOR' | 'ADMIN'
  path?: string
  element?: React.ReactNode
  optionalPrefix?: string
}

interface SideMenuRouteObj extends RouteObj {}

const mainRoutes: SideMenuRouteObj[] = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
    icon: <DashboardOutlined />,
    label: 'Dashboard',
    key: 'dashboard',
    headerLabel: 'Dashboard',
    isMenu: true,
    optionalPrefix: '/author',
  },
  {
    path: '/deposit',
    element: <DepositPage />,
    icon: <DollarOutlined />,
    label: 'Deposit',
    key: 'Deposit',
    headerLabel: 'Deposit Management',
    isMenu: true,
  },
  {
    path: '/payment-method',
    element: <PaymentMethodPage />,
    icon: <WalletOutlined />,
    label: 'Payment Method',
    key: 'payment-method',
    headerLabel: 'Payment Method Management',
    isMenu: true,
  },
  {
    path: '/books',
    element: <BookLayout />,
    icon: <BookOutlined />,
    label: 'Books',
    key: 'books',
    headerLabel: 'Book Management',
    isMenu: true,
    optionalPrefix: '/author',
    children: [
      {
        path: '',
        element: <BookPage />,
        key: 'view-book',
        headerLabel: 'View Books',
        label: 'View Books',
        isMenu: true,
      },     
      {
        path: 'publisher',
        element: <PublisherPage />,
        label: 'Publisher',
        key: 'publisher',
        headerLabel: 'Publisher',
        isMenu: true,
      },
      {
        path: 'create/new-book',
        element: <CreateBookPage />,
        key: 'create-book',
        headerLabel: 'Create Book',
        isMenu: false,
      },
      {
        path: ':id',
        element: <CreateBookPage />,
        key: 'edit-book',
        headerLabel: 'Edit Book',
        isMenu: false,
      },
      {
        path: 'book-detail/:id',
        element: <BookDetail />,
        key: 'book-detail',
        headerLabel: 'Book Detail',
        isMenu: false,
      },

    ],
  },
  {
    path: '/users',
    element: <CustomerPage />,
    icon: <UserOutlined />,
    label: 'Users',
    key: 'users',
    headerLabel: 'Users',
    isMenu: true,
  },
  {
    path: '/transactions',
    element: <TransactionPage />,
    icon: <DatabaseOutlined />,
    label: 'Transactions',
    key: 'transactions',
    headerLabel: 'Transactions',
    isMenu: true,
    optionalPrefix: '/author',
  },
  {
    path: '/backend-users',
    element: <BackEndUserPage />,
    icon: <SecurityScanOutlined />,
    label: 'Backend Users',
    key: 'backend-user',
    headerLabel: 'Backend Users',
    isMenu: true,
  },
  {
    path: '/delivery-fee',
    element: <DeliveryFeePage />,
    icon: <SecurityScanOutlined />,
    label: 'Delivery Fee',
    key: 'delivery-fee',
    headerLabel: 'Delivery Fee',
    isMenu: true,
  },
  {
    path: '/pickup-location',
    element: <LocationPickupPage />,
    icon: <SecurityScanOutlined />,
    label: 'Location Pickup',
    key: 'pickup-location',
    headerLabel: 'Location Pickup',
    isMenu: true,
  },
  {
    icon: <AreaChartOutlined />,
    label: 'Reports',
    key: 'reports',
    path: '/reports',
    element: <ReportLayout />,
    optionalPrefix: '/author',
    children: [
      {
        path: 'deposit',
        element: <ReportDepositPage />,
        icon: <AreaChartOutlined />,
        label: 'Deposit',
        key: 'deposit',
        headerLabel: 'Deposit',
        isMenu: true,
        hideFor: 'AUTHOR',
      },
      {
        path: 'transaction',
        element: <ReportAuthorTrasaction />,
        icon: <AreaChartOutlined />,
        label: 'Transaction',
        key: 'Transaction',
        headerLabel: 'Transaction',
        isMenu: true,
        hideFor: 'ADMIN',
      },
    ],
    isMenu: true,
  },
  {
    path: '/notification/offer',
    element: <NotificationOfferPage />,
    icon: <AreaChartOutlined />,
        label: 'Notification Offer',
        key: 'NotificationOffer',
        headerLabel: 'Notification Offer',
        isMenu: true,
        hideFor: 'ADMIN',
  },
  {
    path: '/configuration',
    element: <ConfigurationPage />,
    icon: <SettingOutlined />,
    label: 'Configuration',
    key: 'configuration',
    headerLabel: 'Configuration',
    isMenu: true,
  },
]

// No need authorized
const defaultRoutes: SideMenuRouteObj[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        key: 'notfound',
        path: '*',
        element: <>Not Found</>,
      },
      {
        key: 'home',
        path: '',
        element: <Home />,
      },
      {
        key: 'reset',
        path: 'reset',
        element: <RouteReset />,
      },
    ],
  },
  {
    path: '/login',
    element: <SecurityLayout />,
    children: [
      {
        key: 'login',
        path: '',
        element: <MainLogin />,
      },
    ],
  },
  {
    path: '/author/login',
    element: <SecurityLayout />,
    children: [
      {
        key: 'authorLogin',
        path: '',
        element: <AuthorLogin />,
      },
    ],
  },
]

export const filterRoute = (
  item?: SideMenuRouteObj,
  parent?: SideMenuRouteObj,
): any => {
  if (!item) {
    return undefined
  }

  let path: string

  if (parent) {
    path = `${parent?.path || ''}/${item.path}`
  } else {
    path = item.path
  }

  return {
    ...item,
    path,
    key: `${parent?.key || ''}/${item.key}`,
    children:
      item.children
        ?.map((sub: any) => filterRoute(sub, item))
        ?.filter((sub: any) => sub !== undefined) || undefined,
    element: undefined,
  }
}

const middlewareRoutes = mainRoutes
  .filter((item) => {
    let newItem = {
      ...item,
      children: item.children?.filter((item) => item.hideFor !== 'ADMIN'),
    }

    return newItem
  })
  .map((child) => {
    return {
      ...child,

      element: <ProtectedRoute {...child} />,
    }
  })

const basedRoutes = {
  path: '/',
  element: <BaseLayout />,
  children: middlewareRoutes,
}

const authorRoutes = mainRoutes
  .filter((item) => {
    return item.optionalPrefix
  })
  .map((item) => {
    return {
      ...item,
      path: `${item.optionalPrefix}${item.path}`,
      children: item.children
        ?.filter((item) => item.hideFor !== 'AUTHOR')
        .map((c) => {
          return {
            ...c,
            path: `${c.path}`,
            key: `${item.optionalPrefix}/${c.key}`,
          }
        }),
    }
  })
  .map((child) => {
    return {
      ...child,
      element: <ProtectedRoute {...child} />,
    }
  })

const authorBasedRoutes = {
  path: '/author',
  element: <BaseLayout />,
  children: authorRoutes,
}

const loginRoutes = ['/login', '/author/login']

const RouterView: React.FC<any> = (props) => {
  const isAuthor = useIsAuthor()

  const routerStore = useRouterStore((state) => state)
  let routes = useMemo(() => {
    let userRoutes = isAuthor ? authorBasedRoutes : basedRoutes

    return [...defaultRoutes, userRoutes].map((route: RouteObject) => {
      return {
        path: route.path,
        element: route.element,
        children: route.children,
      }
    })
  }, [isAuthor])

  const routers = useRoutes([...routes])

  const { state } = useAuthContext()

  const location = useLocation()

  const [disable, setDisable] = useState(true)

  useEffect(() => {
    let routeIsAuthor = isAuthor || location.pathname.startsWith('/author')

    let newAxiosBaseUrl = routeIsAuthor
      ? `${process.env.REACT_APP_SERVER_URL}/api/author`
      : `${process.env.REACT_APP_SERVER_URL}/api/backend`
    setAxiosBaseUrl(newAxiosBaseUrl)
    setDisable(false)
  }, [isAuthor, location.pathname])

  useEffect(() => {
    let result = state?.user?.roles.includes('AUTHOR')
      ? authorRoutes
      : mainRoutes.map((item) => {
          let newItem = {
            ...item,
            children: item.children?.filter((item) => item.hideFor !== 'ADMIN'),
          }

          return newItem
        })

    const filtered = result.map((route) => filterRoute(route)) || []

    routerStore.setRoutes(filtered)
  }, [state])

  if (disable) {
    return <div />
  }

  if (isAuthor === undefined && !loginRoutes.includes(location.pathname)) {
    return <div />
  }

  return <>{routers}</>
}

export default RouterView
