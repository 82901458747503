import { AbstractDashboardChartsService } from "./DashboardChartsService";

const url = '/dashboard/'

class BackendDashboardService extends AbstractDashboardChartsService {
    constructor() {
        super()
    }

    getUrl(): string {
        return url
    }
}

export default BackendDashboardService