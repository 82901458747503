import { DraggableModalProps } from 'ant-design-draggable-modal'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form } from 'antd'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import styled from 'styled-components'
import SelectCustom from '../controls/SelectCustom'
import { useIsDeveloper } from '../hooks/useIsAdmin'

interface FormData {
  username: string
  password?: string
  role?: string
}

export interface modalUserInterface extends DraggableModalProps {
  onSubmit: (data: FormData) => Promise<void>
  formData: FormData
}

const defaultSchema = {
  username: yup.string().required('Username is required.'),
}

const defaultValues: FormData = {
  username: '',
  password: '',
  role: 'USER',
}

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 16px;
`

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const BackendUserFormModal: React.FC<modalUserInterface> = (props) => {
  const isDeveloper = useIsDeveloper()

  const { onSubmit, onOk, formData, ...rest } = props

  const formSchema =
    formData === null
      ? {
          ...defaultSchema,
          password: yup.string().required('Password is required.'),
        }
      : defaultSchema

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(yup.object().shape(formSchema)),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={500}
      initialWidth={450}
      footer={null}
    >
      <StyledForm {...layout}>
        <Form.Item
          label="Username"
          validateStatus={errors.username && 'error'}
          help={errors.username ? errors.username.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="username"
            control={control}
          />
        </Form.Item>

        {formData === null && (
          <Form.Item
            label="Password"
            validateStatus={errors.password && 'error'}
            help={errors.password ? errors.password.message : ''}
            requiredMark={true}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    type={'password'}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )
              }}
              name="password"
              control={control}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Role"
          validateStatus={errors.username && 'error'}
          help={errors.username ? errors.username.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <SelectCustom
                  defaultValue={'USER'}
                  onChange={(e) => onChange(e)}
                  data={
                    isDeveloper
                      ? ['USER', 'ADMIN']
                      : ['USER']
                  }
                />
              )
            }}
            name="role"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </StyledForm>
    </ModalFormContainer>
  )
}

export default BackendUserFormModal
