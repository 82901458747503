import { useAuthContext } from '@/context/AuthContext'
import { useSiteStore } from '@/store'
import { isMobile } from '@/utils'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout } from 'antd'
import React from 'react'
import { Outlet } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../assets/images/logo.png'
import useLayoutHeaderContext from '../../hooks/useLayoutHeaderContext'
import useIsAuthor from '../hooks/useIsAuthor'
import BaseLayoutRightHeader from './BaseLayoutRightHeader'
import MenuApp from './menu'

const { Header, Sider, Content } = Layout

interface BaseLayoutProps {}

const componentDimensions = {
  header: 64,
}

const ContentContainer = styled.div`
  height: calc(100vh - ${componentDimensions.header}px);
  padding: 16px 16px 0;
  overflow-y: auto;
`

const StyledHeader = styled(Header)`
  padding: 0 32px !important;
  background: white !important;
  display: grid;
  grid-template-columns: 40px auto auto;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 9999;
`

const StyledLayout = styled(Layout)`
  grid-template-rows: 64px auto;
  display: grid;
  position: relative;
  height: 100vh;
  z-index: 1;
`

const LogoContainer = styled.div<{ isCollapsed: boolean }>`
  color: white;
  padding: 20px 20px 24px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  & img {
    transition: width 0.1s ease-in;
    width: ${({ isCollapsed }) => (isCollapsed ? '100%' : '100px')};
  }
`

const StyledSider = styled(Sider)`
  position: relative;
  z-index: 2;
`

const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const theme = useSiteStore((state) => state.theme)
  const menuMode = useSiteStore((state) => state.menuMode)
  const collapsed = useSiteStore((state) => state.collapsed)
  const setCollapsed = useSiteStore((state) => state.setCollapsed)

  const navigate = useNavigate()
  const { headerLabel } = useLayoutHeaderContext()
  const { state } = useAuthContext()

  const SidebarCollapsedWidth = isMobile() ? 0 : 80

  const isAuthor = useIsAuthor()

  return (
    state?.user && (
      <Layout
        style={{
          height: '100vh',
        }}
      >
        <StyledSider
          trigger={null}
          collapsible
          collapsed={collapsed}
          theme={theme}
          style={{
            height: '100vh',
          }}
          collapsedWidth={SidebarCollapsedWidth}
        >
          <LogoContainer
            className="logo"
            onClick={() => {
              let url = isAuthor ? '/author/dashboard' : '/'
              navigate(url)
            }}
            isCollapsed={collapsed}
          >
            <img src={logo} alt="Logo" />
          </LogoContainer>
          <MenuApp theme={theme} mode={menuMode} inlineCollapsed={collapsed} />
        </StyledSider>

        <StyledLayout>
          <StyledHeader>
            <div>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed),
                },
              )}
            </div>
            <h3 style={{ margin: 0 }}>{headerLabel}</h3>

            <BaseLayoutRightHeader />
          </StyledHeader>
          {/* <Content
          className="site-layout-background"
          style={{
            display: 'grid',
            gridTemplateRows: '100%',
          }}
        > */}
          <ContentContainer>
            <Outlet />
          </ContentContainer>
          {/* </Content> */}
        </StyledLayout>
      </Layout>
    )
  )
}

export default BaseLayout
