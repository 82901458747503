export interface ResponseState {
  message: string
  code: number
}

export interface DataResponse<T> {
  data: T
  status: ResponseState
}

export interface PaginationInterface {
  page: number
  pageSize: number
  totalCount: number
  totalPage: number
}

export interface PaginationDataReponse<T> extends DataResponse<T> {
  pages: PaginationInterface
}

export interface PaginationRequestParams {
  page?: number
  size?: number
  paged?: boolean
  q?: string
  sort?: string
  sorts?: string | null
}

export abstract class CRUDService<T> {
  abstract create(data: T): Promise<void>
  abstract update(args: { id: any; data: T }): Promise<void>
  abstract delete(id: any): Promise<void>
  abstract getTrashed(): Promise<PaginationDataReponse<T>>
  abstract restore(id: any): Promise<void>
}

abstract class BaseService<T> {
  abstract get(id: any): Promise<T>
  abstract getAll(): Promise<PaginationDataReponse<T>>
}

export default BaseService
