import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'
import { AbstractDashboardStatsService } from './DashboardStatsService'

const url = '/deposit'

export enum DepositStatus {
  APPROVED = 'Approved',
  DENIED = 'Denied',
  PENDING = 'Pending',
}

class DepositService extends AbstractDashboardStatsService implements BaseService<any>, CRUDService<any> {
  getUrl(): string {
    return url
  }

  get(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<any>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  create(data: any): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async update(args: {
    id: any
    data: {
      [index: string]: any
      status: DepositStatus
    }
  }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  delete(id: any): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async getTrashed(params?: PaginationRequestParams) {
    const res = await getAxiosConfig().get(`${url}/trash`, {
      params,
    })
    return res.data as any
  }

  restore(id: any): Promise<void> {
    throw new Error('Method not implemented.')
  }
}

export default DepositService
