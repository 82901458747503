import { getAxiosConfig } from '@/utils'
import { DataResponse } from './baseService'

const url = '/auth'

class AuthService {
  async login(data: {
    username: string
    password: string
  }): Promise<DataResponse<{ token: string }>> {
    const res = await getAxiosConfig().post(`${url}/login`, data)
    return res.data as any
  }

  async verify(): Promise<DataResponse<any>> {
    const res = await getAxiosConfig().post(`${url}/me`, null)
    return res.data
  }
}

export default AuthService
