import React, { useRef, useState, useCallback } from 'react'
import UserTable from 'src/components/backendUser/BackendUserTable'
import BackendUserService from 'src/services/backendUserService'
import { getIdentifier } from '@/utils'
import handleError from 'src/utils/errorHelper'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import BackendUserFormModal, {
  modalUserInterface,
} from 'src/components/backendUser/BackendUserFormModal'
import { Button } from 'antd'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import TableActionMenu, {
  TableActionMenuProps,
} from 'src/components/table/TableActionMenu'

const userService = new BackendUserService()

const mapDataToSubmit = (data: any) => {
  return data
}

const Index = () => {
  const refetchRef = useRef<Function>()

  const [modalUserState, setModalUserState] = useState<modalUserInterface>({
    open: false,
    onSubmit: async () => {},
    formData: null,
  })

  const setModalForCreate = useCallback(() => {
    setModalUserState((old) => ({
      ...old,
      open: true,
      title: 'Create Backend User',
      onSubmit: async (data) => {
        try {
          const dataToSubmit = mapDataToSubmit(data)
          await userService.create(dataToSubmit)
          setModalUserState((old) => ({ ...old, open: false }))
          refetchRef.current()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalUserState(() => {
          return { ...old, open: false }
        })
      },
      formData: null,
    }))
  }, [modalUserState, refetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const { username } = record

      setModalUserState((old) => ({
        ...old,
        open: true,
        title: 'Update backend user',
        onSubmit: async (data) => {
          const dataToSubmit = mapDataToSubmit(data)
          try {
            await userService.update({
              id: record[getIdentifier()],
              data: dataToSubmit,
            })
            setModalUserState((old) => ({
              ...old,
              open: false,
            }))
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },

        onCancel: () => {
          setModalUserState({
            ...old,
            formData: null,
            open: false,
          })
        },
        formData: {
          username,
        },
      }))
    },
    [modalUserState, refetchRef],
  )

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      // {
      //   render: () => {
      //     return <div>Edit</div>
      //   },
      //   key: 'Edit',
      //   onClick: () => {
      //     setModalCategoryForUpdate(record)
      //   },
      // },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await userService.delete(record.id)
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalUserState.open && <BackendUserFormModal {...modalUserState} />}
      </MountedModalProvider>
      <UserTable
        refetchRef={refetchRef}
        renderCreateButton={() => (
          <Button
            onClick={() => {
              setModalForCreate()
            }}
          >
            Create
          </Button>
        )}
        renderOwnActionMenu={renderActionMenu}
      />
    </>
  )
}

export default withChangeHeader(Index, 'User Management')
