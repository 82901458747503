import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from 'antd'
import styled from 'styled-components'

const StyledPopover = styled(AntdPopover)``

interface PopoverProps extends AntdPopoverProps {}

const Popover: React.FC<PopoverProps> = (props) => {
  return <StyledPopover {...props} />
}

export default Popover
