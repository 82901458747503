import { DraggableModalProps } from 'ant-design-draggable-modal'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form, Select, InputNumber } from 'antd'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import styled from 'styled-components'

interface FormData {
  name: string
  nameKh: string
  description: {
    phoneNumber: string
    en: {
      address: string
    }
    kh: {
      address: string
    }
    googleMapUrl: string
  }
}

export interface modalLocationPickupInterface extends DraggableModalProps {
  onSubmit: (data: FormData) => Promise<void>
  formData: FormData
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  nameKh: yup.string().required('Name kh is required'),
})

const defaultValues: FormData = {
  name: '',
  nameKh: '',
  description: {
    phoneNumber: '',
    en: {
      address: '',
    },
    kh: {
      address: '',
    },
    googleMapUrl: '',
  },
}

const StyledForm = styled(Form)``

const LocationPickupFormModal: React.FC<modalLocationPickupInterface> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={500}
      initialWidth={450}
      footer={null}
    >
      <StyledForm layout={'vertical'}>
        <div
          style={{
            display: 'flex',
            gap: 16,
            width: `100%`,
          }}
        >
          <Form.Item
            label="Name"
            validateStatus={errors.name && 'error'}
            help={errors.name ? errors.name.message : ''}
            requiredMark={true}
            style={{
              width: `100%`,
            }}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )
              }}
              name="name"
              control={control}
            />
          </Form.Item>
          <Form.Item
            label="Name Kh"
            validateStatus={errors.nameKh && 'error'}
            help={errors.nameKh ? errors.nameKh.message : ''}
            requiredMark={true}
            style={{
              width: `100%`,
            }}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                  />
                )
              }}
              name="nameKh"
              control={control}
            />
          </Form.Item>
        </div>

        <Form.Item
          label="Phone Number"
          validateStatus={errors.description?.phoneNumber && 'error'}
          help={
            errors.description?.phoneNumber
              ? errors.description?.phoneNumber.message
              : ''
          }
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="description.phoneNumber"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Address en"
          validateStatus={errors.description?.en?.address && 'error'}
          help={errors.description?.en?.address.message || ''}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="description.en.address"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Address kh"
          validateStatus={errors.description?.kh?.address && 'error'}
          help={errors.description?.kh?.address.message || ''}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="description.kh.address"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Google Map url"
          validateStatus={errors.description?.googleMapUrl && 'error'}
          help={errors.description?.googleMapUrl.message || ''}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="description.googleMapUrl"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </StyledForm>
    </ModalFormContainer>
  )
}

export default LocationPickupFormModal
