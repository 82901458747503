import { create } from 'zustand'

interface Store {
  notification: any[]
  setNotification: (notification: any[]) => void
}

export const useGlobalStore = create<Store>((set, get) => ({
  notification: [],
  setNotification: (notification) => {
    return set(() => ({ notification }))
  },
}))
