import { Tooltip } from 'antd'
import React from 'react'
import { TransactionHistory } from '../../models/balance'
import styled from 'styled-components'
import { prune, titleCase } from 'voca'
import { getIn } from '../../utils'

const TransactionDetailsContainer = styled.div`
  & > div {
    margin-bottom: 13px;
  }

  &>div: last-child {
    margin-bottom: 0px;
  }
`

const ItemContainer = styled.div`
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  position: relative;
`

const ItemInfoContainer = styled.div`
  padding: 20px;
  width: 200px;
  position: relative;
`

const ItemTitle = styled.h6`
  height: 36px;
  font-size: 16px;
`

const ItemType = styled.h6`
  margin-bottom: 0;
  height: 18px;
`

const PriceText = styled.h6`
  margin-left: 8px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
`

const StyledText = styled.span``

const TransactionItem = (props) => {
  const { book, qty, price, itemType } = props

  return (
    <ItemContainer>
      <ItemInfoContainer>
        {book?.name && (
          <Tooltip title={book.name}>
            <ItemTitle>{prune(titleCase(book.name), 42)}</ItemTitle>
          </Tooltip>
        )}
        <StyledText>x {qty}</StyledText>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledText>{price?.currency}</StyledText>
          <PriceText>{price?.value}</PriceText>
        </div>
        <ItemType>{itemType}</ItemType>
      </ItemInfoContainer>
    </ItemContainer>
  )
}

const TransactionDetail: React.FC<{} & TransactionHistory> = (props) => {
  const { details } = props
  const { subscriptions } = details

  return (
    <TransactionDetailsContainer>
      {subscriptions.length > 0 ? (
        details.subscriptions.map((sub: any) => {
          if (!Array.isArray(sub)) {
            return <TransactionItem {...sub} />
          }

          return sub.map((item, index) => {
            return <TransactionItem {...item} key={index} />
          })
        })
      ) : (
        <div>No Detail</div>
      )}
    </TransactionDetailsContainer>
  )
}

export default TransactionDetail
