import { DashboardStats } from '@/components/DashboardStats'
import { DepositService } from '@/services'
import { useRef, useState } from 'react'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import DepositTable from '../../components/deposit/depositTable'

const service = new DepositService()

const DepositPage = () => {
  const refetchRef = useRef<Function>()
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  return (
    <>
      <DashboardStats
        service={service}
        defaultProps={{
          precision: 2,
          suffix: '$',
        }}
        refreshToken={refreshToken}
      />

      <br />

      <DepositTable
        renderCreateButton={() => {
          return null
        }}
        refetchRef={refetchRef}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default withChangeHeader(DepositPage, 'Deposit Management')
