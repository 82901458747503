export const config = {
  defaultColorPicker: '#000000',
}

export const tokenKey = 'token'

export const defaultPaginationRequestParams = {
  page: 0,
  size: 10,
}

const acceptedImageExts = ['jpg', 'jpeg', 'png']

export const getAcceptedImageExt = () => {
  return acceptedImageExts.map((item) => `.${item}`).join(',')
}

export const isAcceptedImage = (imageExt: string) => {
  return acceptedImageExts.some((item) => imageExt.endsWith(item))
}
