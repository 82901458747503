import { Upload, Empty, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

type fileType = UploadChangeParam<UploadFile<any>>['file']

interface BookUploaderProps {
  onUpload: (file: fileType) => void
  renderUploaded?: (file: fileType) => React.ReactNode
  uploadTrigger: () => React.ReactNode
}

const checkIfFilePdf = (file: { type: string; [index: string]: any }) => {
  return file.type === 'application/pdf'
}

const BookUploader: React.FC<BookUploaderProps> = (props) => {
  const { onUpload, renderUploaded, uploadTrigger } = props
  const [file, setFile] = useState<fileType | null>(null)

  return (
    <>
      <Upload
        beforeUpload={(file: any) => {
          if (!checkIfFilePdf(file)) {
            message.error(`${file.name} is not a pdf file`)
          }

          return false
        }}
        accept={'.pdf'}
        action=""
        multiple={false}
        showUploadList={false}
        onChange={async (info: any) => {
          const { file } = info
          if (checkIfFilePdf(file as any)) {
            await onUpload(file)
            setFile(file)
          }
        }}
      >
        {uploadTrigger()}
      </Upload>

      {renderUploaded && renderUploaded(file)}
    </>
  )
}

const PagesGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 150px;
  grid-gap: 16px;
`

export interface BookPDFGeneratedPage {
  link: string
  pageIndex: any
  size: number
  order: any
  uri: string
}

interface BookPagesGalleryProps {
  data: BookPDFGeneratedPage[]
  onChange?: (files: BookPDFGeneratedPage[]) => void
  selected?: BookPDFGeneratedPage[]
  setSelectedRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<SelectedInterface[]>>
  >
}

const GalleryCard = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const GalleryCardTag = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

interface SelectedInterface extends BookPDFGeneratedPage {}

export const BookPagesGallery: React.FC<BookPagesGalleryProps> = (props) => {
  const { data, onChange } = props
  const [selected, setSelected] = useState<SelectedInterface[]>(props.selected || [])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  return data.length > 0 ? (
    <PagesGallery>
      {data.map((item) => {
        const selectedIndex = selected.findIndex((s) => {
          return s.pageIndex === item.pageIndex
        })

        return (
          <GalleryCard
            key={item.uri}
            onClick={() => {
              if (selectedIndex >= 0) {
                setSelected((old) =>
                  old.filter((o) => o.pageIndex !== item.pageIndex),
                )
              } else {
                setSelected((old) => [...old, item])
              }
            }}
          >
            {selectedIndex >= 0 && (
              <GalleryCardTag>{selectedIndex + 1}</GalleryCardTag>
            )}
            <img src={item.link} alt=''/>
          </GalleryCard>
        )
      })}
    </PagesGallery>
  ) : (
    <Empty />
  )
}

export default BookUploader
