import { getAxiosConfig } from '@/utils'
import { AbstractDashboardStatsService } from '@/services'
import BaseService, {
  PaginationDataReponse,
  PaginationRequestParams
} from './baseService'

export interface SubscriptionUpdate {
  memo: string
}

const url = '/transaction-history'

class TransactionService extends AbstractDashboardStatsService implements BaseService<{}> {
  constructor() {
    super()
  }

  getUrl(): string {
    return url
  }

  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  async accept(id: any): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().post(`${url}/accept/${id}`)
    return res.data as any
  }

  async reject(id: any): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().post(`${url}/reject/${id}`)
    return res.data as any
  }
}

export default TransactionService
