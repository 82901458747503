import { useAuthorize } from '@/components/hooks/useAuthorize'
import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { tokenKey } from 'src/constants'
import handleError from 'src/utils/errorHelper'
import AuthService from '../services/authService'

interface AuthContextState {
  state: {
    user: UserAuth | null
    loading: boolean
  }
}

interface AuthContextAddOn {
  login: (args: { username: string; password: string }) => Promise<UserAuth | null>
  getToken: () => string | undefined
  logout: () => void
  isLogin: () => boolean
  isAuthorized: (roles?: string | string[]) => boolean
  updateUser: (args: UserAuth) => void
}

export interface UserAuth {
  username: string
  name?: string | undefined
  id: string
  getToken: () => string | null
  roles: string[]
  privileges: string[]
  authorities: string[]
  profile: any
  aliasName?: string | null
}

enum AuthActionType {
  'LOGIN' = 'LOGIN',
  'LOGOUT' = 'LOGOUT',
  'TOGGLE_LOADING' = 'TOGGLE_LOADING',
}

const AuthReducer: (
  state: AuthContextState['state'],
  action: {
    type: AuthActionType
    payload?: any
  },
) => AuthContextState['state'] = (state, action) => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return {
        ...state,
        user: action.payload,
      }
    case AuthActionType.LOGOUT:
      return {
        ...state,
        user: null,
      }
    case AuthActionType.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export const AuthContext = React.createContext<AuthContextState & AuthContextAddOn>({
  login: async () => null,
  state: {
    user: null,
    loading: true,
  },
  getToken: () => {
    return undefined
  },
  logout: () => {},
  isLogin: () => {
    return false
  },
  isAuthorized: (roles) => {
    return false
  },
  updateUser: () => {},
})

const authService = new AuthService()

const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, {
    user: null,
    loading: true,
  })

  const login = async (args: {
    username: string
    password: string
    isAuthor?: boolean
  }) => {
    const { isAuthor, ...data } = args
    let userAuthInfo: UserAuth | null = null
    try {
      localStorage.setItem(tokenKey, '')

      const res = await authService.login(data)

      localStorage.setItem(tokenKey, res.data.token)

      userAuthInfo = await verify()
    } catch (e) {
      handleError(e)
    }

    return userAuthInfo
  }

  const verify = async () => {
    const token = localStorage.getItem(tokenKey)
    let userAuthInfo: UserAuth | null = null
    try {
      let res = await authService.verify()
      if (res.status.code >= 400) {
        return null
      }

      userAuthInfo = {
        ...res.data,
        getToken: () => localStorage.getItem(token) || null,
      }

      dispatch({
        type: AuthActionType.LOGIN,
        payload: userAuthInfo,
      })
    } catch (e) {
      console.error(e)
    }

    return userAuthInfo
  }

  const location = useLocation()
  const navigate = useNavigate()

  const doVerify = async () => {
    console.log('Do verify...')
    const userAuthInfo = await verify()

    if (
      userAuthInfo === null &&
      location.pathname !== '/login' &&
      location.pathname !== '/author/login'
    ) {
      await navigate('/login')
    } else if (userAuthInfo !== null && location.pathname === '/login') {
      await navigate('/')
    }

    dispatch({
      type: AuthActionType.TOGGLE_LOADING,
      payload: false,
    })
  }

  const updateUser = (args: any) => {
    dispatch({
      type: AuthActionType.LOGIN,
      payload: args,
    })
  }

  useEffect(() => {
    doVerify()
  }, [])

  const logout = useCallback(() => {
    let url = state.user.roles.includes(`AUTHOR`) ? `/author/login` : `/login`
    localStorage.setItem(tokenKey, '')
    dispatch({
      type: AuthActionType.LOGOUT,
    })

    navigate(url)
  }, [state])

  const isAuthorized = (authority?: string | string[]) => {
    if (!authority) {
      return true
    }

    const authorities = Array.isArray(authority) ? authority : [authority]

    if (authorities[0] && authorities[0] === '*') {
      return true
    }

    const userAuthorities = state.user?.authorities || []

    if (userAuthorities.some((u) => u === 'ROLE_SUPER_ADMIN')) {
      return true
    }

    return userAuthorities.some((u) => authorities.some((r) => u === r))
  }

  const getToken = () => {
    return localStorage.getItem(tokenKey)
  }

  const isLogin = (): boolean => {
    return getToken() != null
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        state,
        logout,
        getToken,
        isAuthorized,
        isLogin,
        updateUser,
      }}
    >
      {state.loading === false && props.children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export default AuthProvider
