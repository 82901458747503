import styled from 'styled-components'

const BodyContainer = styled.div`
  width: 100%;
  background: white;
  padding: 20px 20px 0 20px;
  border-radius: 8px;
`

export default BodyContainer
