import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

const SpanStyle = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface TextVerifyIconProps {
  text: string
  isVerify: boolean
}

const TextVerifyIcon: React.FC<TextVerifyIconProps> = (props) => {
  const { text, isVerify } = props

  return (
    <SpanStyle>
      {text} {isVerify ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
    </SpanStyle>
  )
}

interface IconProps {
  title?: string
  color?: string
}

export const CheckOutlinedIcon: React.FC<IconProps> = (props) => {
  const { title = 'Verified', color = '#389e0d' } = props
  return <CheckOutlined title={title} style={{ color: color }} />
}

export const CloseOutlinedIcon: React.FC<IconProps> = (props) => {
  const { title = 'Not verified', color = '#d9363e' } = props
  return <CloseOutlined title={title} style={{ color: color }} />
}

export default TextVerifyIcon
