import moment from 'moment'

export enum formatType {
  default = 'DD/MM/YYYY HH:mm',
  date = 'DD/MM/YYYY',
  dateTime = 'DD/MM/YYYY hh:mm:ssA',
}

export const dateTimeformat = (args: { value: number | string | null | undefined; format: formatType }) => {
  const { value, format } = args
  
  if (!value) {
    return ''
  }
  
  const result = moment(value).format(format)

  return result
}

export const momentHumanize = (args: { value: number; past?: boolean }) => {
    const { value, past = true } = args;

  const now = moment();

  let diff = now.diff(value);

  if (past) {
    diff = diff * -1;
  }

  const result = moment.duration(diff).humanize(true);

  return result;
};
