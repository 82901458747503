import { defaultPaginationRequestParams } from '@/constants'

import { hasMore } from '@/utils/tableHelper'
import { useRef } from 'react'
import { useGlobalStore } from '../global'
import useInViewportFn from '../hooks/useInViewportFn'
import NotificationService from '@/services/notificationService'

const service = new NotificationService()

interface NotificationFetcherProps {}

const ThatAll = () => {
  return (
    <div
      style={{
        padding: `10px 0 10px`,
      }}
    >
      That's all
    </div>
  )
}

const Loading = () => {
  return (
    <div
      style={{
        padding: `20px 0 10px`,
      }}
    >
      Loading...
    </div>
  )
}

const NotificationFetcher: React.FC<NotificationFetcherProps> = (props) => {
  const setNotification = useGlobalStore((state) => state.setNotification)
  const notification = useGlobalStore((state) => state.notification)

  const reqParamsRef = useRef(defaultPaginationRequestParams)

  const hasMoreRef = useRef(true)

  const { component } = useInViewportFn({
    onInViewPort: async ({ setChildren, inViewPort }) => {
      const setFetcherFinishChildren = () => {
        setChildren(<ThatAll />)
      }

      if (inViewPort && hasMoreRef.current) {
        setChildren(<Loading />)
        const res = await service.getAll({
          ...reqParamsRef.current,
        })

        const hasMoreData = hasMore(res.pages)

        setNotification([...notification, ...res.data])
        if (hasMoreData) {
          reqParamsRef.current = {
            ...reqParamsRef.current,
            //@ts-ignore
            page: reqParamsRef.current.page + 1,
          }
        } else {
          hasMoreRef.current = false
          setFetcherFinishChildren()
        }
      } else if (!hasMoreRef.current) {
        setFetcherFinishChildren()
      } else {
        setChildren(<Loading />)
      }
    },
  })

  return component
}

export default NotificationFetcher
