import React from 'react'
import { useAuthorize } from './useAuthorize'

const useIsAdmin = () => {
  const isAdmin = useAuthorize({
    or: {
      roles: ['ADMIN', 'SUPER_ADMIN'],
    },
  })

  return isAdmin
}

export const useIsDeveloper = () => {
  const isDeveloper = useAuthorize({
    or: {
      roles: ['DEVELOPER'],
    },
  })

  return isDeveloper
}

export default useIsAdmin
