import { Button, Space } from 'antd'
import React, { useRef, useState } from 'react'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import CodeEditor, { CodeEditorProps } from '../input/CodeEditor'
import MountedModalProvider from '../modal/MountedModalProvider'

interface ConfigModalProps extends CodeEditorProps {}

const ConfigModal: React.FC<ConfigModalProps> = (props) => {
  const { ...rest } = props
  const [visible, setVisible] = useState(false)
  const monacoRef = useRef<any>()

  return (
    <div>
      <MountedModalProvider shouldMounted={true}>
        <ModalFormContainer
          initialHeight={600}
          initialWidth={window.innerWidth * 0.6}
          footer={null}
          open={visible}
          onCancel={() => {
            setVisible(false)
          }}
        >
          <CodeEditor
            editorDidMount={(...monaco) => {
              monacoRef.current = monaco
            }}
            language={'json'}
            {...rest}
          />

          <ModalFooter>
            <Space>
              <Button
                type={'primary'}
                onClick={() => {
                  setVisible(false)
                }}
              >
                Confirm
              </Button>

              <Button
                onClick={(e) => {
                  setVisible(false)
                }}
              >
                Cancel
              </Button>
            </Space>
          </ModalFooter>
        </ModalFormContainer>
      </MountedModalProvider>

      <Button
        type={'dashed'}
        onClick={() => {
          setVisible(true)
        }}
      >
        Configs
      </Button>
    </div>
  )
}

export default ConfigModal
