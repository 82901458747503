import { message } from 'antd'
import { AxiosError } from 'axios'
import { ResponseState } from '../services/baseService'

export const getAxiosErrorMessage = (
  e: AxiosError<{
    status: ResponseState
  }>,
) => {
  //@ts-ignore
  const message = e.response ? e.response.data.status.message : e.message

  return message
}

const handleError = (e: any) => {
  message.error(getAxiosErrorMessage(e))
  console.error(e)
}

export default handleError
