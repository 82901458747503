import swrKeys from '@/constants/swrKey'
import EnumService, { EnumType } from '@/services/enumService'
import { getSwrDefaultConfig } from '@/utils'
import handleError from '@/utils/errorHelper'
import { Input, InputProps, Select, SelectProps } from 'antd'

import React from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

interface SelectRentInterface {
  propsPrice: InputProps
  propsDuration: InputProps
  propsDurationType: SelectProps
}

const enumService = new EnumService()

const { Option } = Select

const StyledPriceInput = styled(Input)`
  .ant-input-group-addon {
    padding-left: 0;
  }
`

const SelectRent: React.FC<SelectRentInterface> = (props) => {
  const { propsDuration, propsDurationType, propsPrice } = props

  const { data: rentDurationTypeOptions = [] } = useSWR<any, any>(
    swrKeys.bookFormRentDurationOptions(),
    async () => {
      try {
        const res = await enumService.getByType(EnumType.BOOK_SUBSCRIPTION_DURATION)

        return res.data
      } catch (e) {
        handleError(e)
      }
    },
    getSwrDefaultConfig(),
  )

  return (
    <StyledPriceInput
      {...propsPrice}
      addonAfter={
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
          }}
        >
          <Input {...propsDuration} />

          <Select
            style={{
              width: 150,
            }}
            bordered={false}
            {...propsDurationType}
          >
            {rentDurationTypeOptions.map((item: any) => (
              <Option value={item.code} key={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      }
    />
  )
}

export default SelectRent
