import React, { useState, useEffect } from 'react'
import BookForm from 'src/components/bookForm/bookForm'
import FormPageContainer, {
  FormPageHeaderText,
} from 'src/components/other/FormPageContainer'
import BookService from 'src/services/bookService'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getIdentifier } from '@/utils'
import moment from 'moment'
import handleError from 'src/utils/errorHelper'
import { message } from 'antd'
import currencyType from 'src/constants/currency'
import { formatJSON } from '@/utils/check'

const bookService = new BookService()

const mapDataToEdit = (data: any) => {
  let {
    publishers,
    authors,
    releaseDate,
    language,
    saleSubscription,
    rentSubscription,
    categories,
    paperBookSubscription,
    isHavePaperBook: isEnabled,
    configs,
    ...rest
  } = data

  let price = paperBookSubscription
    ? {
        value: paperBookSubscription.price.value,
        currency: paperBookSubscription.price.currency,
      }
    : {
        value: 0,
        currency: currencyType.USD,
      }
  let result = {
    ...rest,
    authors: authors
      ? authors.map((item: any) => ({
          ...item,
          authorId: item[getIdentifier()],
        }))
      : [],
    publishers: publishers.map((item) => {
      return {
        publisherId: Number(item),
      }
    }),
    releaseDate: moment(releaseDate),
    languageCode: language && language.languageCode,
    languageName: language && language.languageName,
    saleablePrice: saleSubscription?.price.value,
    rentablePrice: rentSubscription?.price.value,
    rentDurationType: rentSubscription?.type,
    rentDuration: rentSubscription?.duration,
    categories: categories.map((item: any) => item[getIdentifier()]),
    paperBookAvailable: isEnabled,
    paperPrice: price.value,
    paperPriceCurrency: price.currency,
    configs: formatJSON(JSON.stringify(configs)),
    owner: rest.owner,
  }

  return result
}

const CreateBook = () => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(true)

  const navigate = useNavigate()

  let { id } = useParams()

  const header = id ? 'Edit Book' : 'Create Book'

  const [formState, setFormState] = useState<{
    loading: boolean
    initialValues?: any
  }>({
    loading: true,
  })

  useEffect(() => {
    const fetchData = async () => {
      if (id && id !== 'create') {
        const res = await bookService.get(id)
        let data = res.data
        data = mapDataToEdit({ bookId: id, ...data })

        setSubmitLoading(false)
        setFormState({
          loading: false,
          initialValues: data,
        })
      } else {
        setFormState({
          loading: false,
        })
      }
    }

    fetchData()
  }, [])

  if (formState.loading) {
    return <div>loading...</div>
  }

  return (
    <FormPageContainer
      headerText={
        <FormPageHeaderText
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowLeftOutlined
            onClick={() => {
              navigate(-1)
            }}
          />
          <div style={{ marginLeft: 16 }}>{header}</div>
        </FormPageHeaderText>
      }
    >
      <BookForm
        initialValue={formState.initialValues}
        onSubmit={async (data, resetForm) => {
          let { bookId, ...rest } = data
          if (id !== 'create' && id) {
            bookId = id
          }

          setSubmitLoading(false)
          setSubmitLoading(true)
          const {
            languageCode,
            languageName,
            paperPrice,
            paperPriceCurrency,
            paperBookAvailable,
            ...restData
          } = rest
          const dataToSubmit = {
            ...restData,
            language: {
              languageName,
              languageCode,
            },
            paperBook: {
              price: {
                value: paperPrice,
                currency: paperPriceCurrency,
              },
              enabled: paperBookAvailable,
            },
          }
          try {
            await bookService.update({
              id: bookId,
              data: dataToSubmit,
            })

            if (id === undefined && id !== 'create') {
              resetForm()
            }
            message.info('Book Updated')
          } catch (e) {
            handleError(e)
          }
          setSubmitLoading(false)
        }}
        loading={submitLoading}
      />
    </FormPageContainer>
  )
}

export default CreateBook
