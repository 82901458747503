import React from 'react'
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'
import styled from 'styled-components'

const StyledButton = styled(AntdButton)``

interface ButtonProps extends AntdButtonProps {}

const Button: React.FC<ButtonProps> = (props) => {
  return <StyledButton {...props} />
}

export default Button
