import React, { useCallback, useState, useEffect } from 'react'
import {
  defaultPaginationRequestParams,
  getAcceptedImageExt,
  isAcceptedImage,
} from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  DataResponse,
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import BookService from 'src/services/bookService'
import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import handleError from 'src/utils/errorHelper'
import useSWR from 'swr'
import BodyContainer from '../other/bodyContainer'
import {
  Button,
  Pagination,
  Typography,
  Popover,
  Upload,
  message,
  Popconfirm,
  Checkbox,
} from 'antd'
import { UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import TableHeader from '../other/TableHeader'
import { getPaginationProps } from 'src/utils/tableHelper'
import { useNavigate } from 'react-router-dom'
import LoadingComponent from '../other/LoadingComponent'
import { dateTimeformat, formatType } from '@/utils/dateTime'
import useIsAuthor from '../hooks/useIsAuthor'

interface TableProps {
  renderCreateButton: () => React.ReactNode
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  onRefresh?: () => void
}

const bookService = new BookService()

const Record = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 16px;
`

const BookCover = styled.img`
  object-fit: cover;
  width: 120px;
  height: 200px;
  margin-right: 16px;
  cursor: pointer;
`

const RecordContainer = styled.div`
  & > * {
    margin-bottom: 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  }

  & > *:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`

const BookInfoContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const BookMetaContainer = styled(Typography.Title)`
  margin-top: 0 !important;
  font-weight: 400 !important;
`

const RecordAction = styled.div`
  display: flex;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  padding-top: 8px;
  grid-gap: 8px;
  align-items: center;
`

const ActionButton = styled(Button)`
  padding: 0;
  height: min-content;
`

const ActionDivider = styled.div`
  min-height: 32px;
  width: 0.5px;
  background: rgba(0, 0, 0, 0.2);
`

const BookChangeCover: React.FC<{
  bookId: any
  afterSubmit: (res: DataResponse<any>) => void
}> = (props) => {
  const { bookId, afterSubmit } = props
  return (
    <Upload
      beforeUpload={(file) => {
        if (!isAcceptedImage(file.type)) {
          message.error(`${file.name} is not a valid image.`)
        }

        return false
      }}
      action=""
      multiple={false}
      showUploadList={false}
      onChange={async (info: any) => {
        const { file } = info
        if (isAcceptedImage(file.type)) {
          try {
            const res = await bookService.updateBookCover(bookId, { file })
            afterSubmit(res)
          } catch (e) {
            handleError(e)
          }
        }
      }}
      accept={getAcceptedImageExt()}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  )
}

const BookTable = (props: TableProps) => {
  const { onRefresh } = props

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const { data, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    'book',
    null,
    getSwrDefaultConfig(),
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = await bookService.getAll(reqParams)

        if (res.data) {
          mutate(res)
        }
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams])

  useEffect(() => {
    refetch()
  }, [reqParams])

  const navigate = useNavigate()

  const getCoverMenus = (bookId: any) => {
    return (
      <BookChangeCover
        bookId={bookId}
        afterSubmit={(res) => {
          const newData = data.data.map((item: any) => {
            if (item[getIdentifier()] === bookId) {
              return {
                ...item,
                cover: res.data.link,
              }
            }

            return item
          })

          mutate({ ...data, data: newData })
        }}
      />
    )
  }

  const isAuthor = useIsAuthor()

  if (!data) {
    return <LoadingComponent />
  }

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <BodyContainer style={{ padding: 20 }}>
      <TableHeader style={{ marginBottom: 16 }}>
        {props.renderCreateButton()}
        <Button
          onClick={() => {
            refreshAction()
          }}
        >
          Refresh
        </Button>
      </TableHeader>
      <RecordContainer>
        {data?.data?.map((item: any) => {
          return (
            <Record key={item[getIdentifier()]}>
              <Popover
                content={getCoverMenus(item[getIdentifier()])}
                trigger={'click'}
              >
                <BookCover src={item.cover} />
              </Popover>
              <BookInfoContainer>
                <div>
                  <Typography.Title level={4}>{item.name}</Typography.Title>

                  <BookMetaContainer level={5}>
                    <b>Authors</b>:{' '}
                    {item.authors && item.authors.map((a: any) => a.name).join(', ')}
                  </BookMetaContainer>

                  <BookMetaContainer level={5}>
                    <b>Genres</b>:{' '}
                    {item.categories &&
                      item.categories.map((c: any) => c.name).join(', ')}
                  </BookMetaContainer>
                  <BookMetaContainer level={5}>
                    <b>Created Date</b>:{' '}
                    {dateTimeformat({
                      value: item.createdDate,
                      format: formatType.dateTime,
                    })}
                  </BookMetaContainer>
                </div>
                <RecordAction>
                  <Typography.Text>
                    <b>Rent</b>: {item.rentSubscription?.price.normalized}
                  </Typography.Text>
                  <Typography.Text>
                    <b>Sale</b>: {item.saleSubscription?.price.normalized}
                  </Typography.Text>

                  {item.isHavePaperBook && (
                    <Typography.Text>
                      <b>Paper book</b>:{' '}
                      {item.paperBookSubscription?.price.normalized}
                    </Typography.Text>
                  )}

                  <ActionDivider />
                  <ActionButton
                    type={'link'}
                    onClick={() => {
                      let url = isAuthor
                        ? '/author/books/book-detail/'
                        : '/books/book-detail/'
                      navigate(url + item[getIdentifier()])
                    }}
                  >
                    Detail
                  </ActionButton>
                  <ActionButton
                    type={'link'}
                    onClick={() => {
                      if (item.isPublish) {
                        message.error('Please unpublish book before update')
                      } else {
                        let url = isAuthor ? `/author/books/` : `/books/`
                        navigate(url + item[getIdentifier()])
                      }
                    }}
                    icon={<EditOutlined />}
                  />

                  <Checkbox
                    checked={item.isPublish}
                    onClick={async (e: any) => {
                      try {
                        await bookService.publishBook(item[getIdentifier()], {
                          published: e.target.checked,
                        })
                        refreshAction()
                      } catch (e) {
                        handleError(e)
                      }
                    }}
                  >
                    Published
                  </Checkbox>

                  <Popconfirm
                    title="Are you sure?"
                    trigger={'click'}
                    onConfirm={async () => {
                      try {
                        await bookService.delete(item[getIdentifier()])
                        const newData = await bookService.getAll(reqParams)
                        mutate(newData)
                        onRefresh && onRefresh?.()
                      } catch (e) {
                        handleError(e)
                      }
                    }}
                  >
                    <ActionButton type={'link'} icon={<DeleteOutlined />} />
                  </Popconfirm>
                </RecordAction>
              </BookInfoContainer>
            </Record>
          )
        })}
      </RecordContainer>

      <Pagination
        defaultCurrent={1}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        {...getPaginationProps(data?.pages, async (page, pageSize) => {
          setReqParams({
            page,
            size: pageSize,
          })
        })}
      />
    </BodyContainer>
  )
}

export default BookTable
