import { useContext } from 'react'
import {
  BaseLayoutHeaderContext,
  BaseLayoutHeaderContextState,
} from '../context/BaseLayoutHeaderContext'

const useLayoutHeaderContext = (): BaseLayoutHeaderContextState => {
  return useContext(BaseLayoutHeaderContext)
}
export default useLayoutHeaderContext
