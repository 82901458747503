import { getIdentifier, getSwrDefaultConfig, repeatFnOnObj } from '@/utils'
import { Button, Input, Space } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import swrKeys from 'src/constants/swrKey'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import useSWR from 'swr'
import { titleCase, words } from 'voca'

import BodyContainer from '../other/bodyContainer'
import BoldLabelValue from '../other/text'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import PublisherService from '@/services/publisherService'

interface TableProps {
  renderCreateButton?: () => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  onRefresh?: () => void
}

const columns: newColumnsInterface[] = [
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'description',
    title: 'Description',
  },
]

const service = new PublisherService()
const PublisherTable: React.FC<TableProps> = (props) => {
  const { onRefresh } = props

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const { renderCreateButton, refetchRef, renderOwnActionMenu } = props

  const { data, error, mutate } = useSWR<any, PaginationDataReponse<[]>>(
    swrKeys.publisherMethod(),
    null,
    getSwrDefaultConfig(),
  )

  const [showTrashed, setShowTrashed] = useState(false)

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await service.getTrashed(reqParams)
          : await service.getAll(reqParams)

        res.data && mutate(res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [reqParams, showTrashed])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [reqParams, showTrashed])

  const renderTrashedTableActionMenu = useCallback(
    (record: any) => {
      let menus: TableActionMenuProps['menus'] = [
        {
          render: () => {
            return <div>Restore</div>
          },
          key: 'Restore',
          onClick: async () => {
            try {
              await service.restore(record[getIdentifier()])
              refreshAction()
            } catch (e) {
              handleError(e)
            }
          },
        },
      ]

      return <TableActionMenu menus={menus} />
    },
    [showTrashed],
  )

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <>
      <BodyContainer style={{ padding: 20 }}>
        <TableSkeleton loading={!data}>
          <Table
            restProps={{
              rowKey: getIdentifier(),
              bordered: true,
              pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
                setReqParams({
                  page,
                  size: pageSize,
                })
              }),
            }}
            columnsVisibleControllerProps={{
              show: true,
            }}
            renderCreateButton={() => {
              return (
                <Space>
                  {renderCreateButton && renderCreateButton()}
                  <Button
                    onClick={() => {
                      refreshAction()
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              )
            }}
            newColumns={columns}
            newSources={data ? data.data : []}
            name="publisher-table"
            renderOwnActionMenu={({ record }) => {
              return showTrashed
                ? renderTrashedTableActionMenu(record)
                : renderOwnActionMenu({ record, mutate, data: data.data })
            }}
            headerStyle={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            renderOwnSearchInput={() => <React.Fragment />}
            // renderOwnSearchInput={() => (
            //   <SearchContainter>
            //     <Checkbox
            //       style={{ marginLeft: 8 }}
            //       onChange={async (e) => {
            //         const { checked } = e.target
            //         setShowTrashed(checked)
            //       }}
            //     >
            //       Trash
            //     </Checkbox>

            //     <Search
            //       enterButton
            //       style={{
            //         width: 350,
            //       }}
            //       loading={!data}
            //       onSearch={(value) => {
            //         setReqParams((old) => ({
            //           ...old,
            //           q: value,
            //         }))
            //       }}
            //     />
            //   </SearchContainter>
            // )}
          />
        </TableSkeleton>
      </BodyContainer>
    </>
  )
}

export default PublisherTable
