import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import React, { useState, useEffect } from 'react'
import UploadService, { Upload as UploadModel } from 'src/services/uploadService'
import styled from 'styled-components'

const StyledUpload = styled(Upload)`
  & > .ant-upload {
    width: 128px;
    height: 128px;
  }
`

const uploadService = new UploadService()
const UploadComponent: React.FC<{
  onUpload: (file: UploadModel) => void
  onRemove: (file: UploadModel) => void
  imageUrl?: string
}> = (props) => {
  const { onUpload, onRemove, imageUrl: pImageUrl, ...rest } = props

  const [loading, setLoading] = useState(false)
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const [imageUrl, setImageUrl] = useState(pImageUrl)

  useEffect(() => {
    setImageUrl(pImageUrl)
    return () => {}
  }, [pImageUrl])

  return (
    <StyledUpload
      accept={'image/*'}
      listType="picture-card"
      {...rest}
      showUploadList={false}
      customRequest={async ({ file, onProgress, onSuccess, onError }) => {
        setLoading(true)
        try {
          const res = await uploadService.upload(file, {
            //@ts-ignore
            onUploadProgress: ({ total, loaded }) => {
              //@ts-ignore
              onProgress({
                percent: Number(Math.round((loaded / total) * 100).toFixed(2)),
              })
            },
            headers: undefined
          })

          //@ts-ignore
          onSuccess(res.data, file)
          onUpload(res.data)
        } catch (e) {
          onError(e)
        }
        setLoading(false)
        return {
          abort() {
            console.log('upload progress is aborted.')
          },
        }
      }}
      onRemove={(file: any) => {
        onRemove(file.response)
      }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: '80px', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        uploadButton
      )}
    </StyledUpload>
  )
}

export default UploadComponent
