import { Menu, Switch } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRouterStore, useSiteStore } from '@/store'
import styled from 'styled-components'

interface MenuProps {
  theme: 'light' | 'dark'
  mode: 'vertical' | 'inline'
  inlineCollapsed?: boolean
}

const SwitchContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 5px;
  cursor: pointer;
  & svg {
    transition: width 0.1s ease-in;
    width: ${({ theme }) => (theme === 'light' ? '100px' : '100%')};
  }
`

const MenuApp: React.FC<MenuProps> = (props) => {
  const { theme, mode } = props
  const setTheme = useSiteStore((state) => state.setTheme)
  const routes = useRouterStore((state) => state.routes)
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (item: any) => {
    navigate(item.path || '')
  }

  const renderItem = (item: any) => {
    if (item.isMenu === false) return undefined
    return {
      key: item.key,
      label: item.label,
      icon: item.icon,
      path: item.path,
      children: item?.children?.map((sub: any) => renderItem(sub)),
      onClick: item?.children ? undefined : () => handleClick(item),
    }
  }

  const renderSideMenuLinks = () => {
    const defaultSelectedKey = routes.find((item: any) => {
      return '/' + item.path === location.pathname
    })

    return (
      <div
        style={{
          // height: 'calc(100vh - 64px)',
          overflow: 'hidden auto',
        }}
      >
        <Menu
          theme={theme}
          mode={mode}
          defaultSelectedKeys={defaultSelectedKey ? [defaultSelectedKey] : []}
          items={routes.map((item) => renderItem(item))}
        />

        <SwitchContainer>
          <Switch
            checked={theme === 'dark'}
            onChange={(value) => {
              setTheme(value ? 'dark' : 'light')
            }}
            checkedChildren="Dark"
            unCheckedChildren="Light"
          />
        </SwitchContainer>
      </div>
    )
  }

  return <>{renderSideMenuLinks()}</>
}

export default MenuApp
