import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

const ThemeProvider: React.FC<any> = (props) => {
  return (
    <StyledThemeProvider
      theme={{
        colorPrimary: '#27AAE1',
        colorSuccess: '#0DDA84',
        colorError: '#FF3B3B',
        colorGray: '#ddd',
      }}
    >
      {props.children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
