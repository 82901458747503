import React from 'react'

const BoldLabelValue: React.FC<{
  title: string
  [index: string]: any
}> = ({ title, children, ...rest }) => {
  return (
    <div {...rest}>
      <b>{title}</b>: {children}
    </div>
  )
}

export default BoldLabelValue
