import React from 'react'
import { Outlet } from 'react-router'

const SecurityLayout: React.FC = (props) => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default SecurityLayout
