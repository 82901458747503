import DeliveryFeeService from '@/services/deliveryFeeService'
import { getIdentifier } from '@/utils'
import { Button } from 'antd'
import { useCallback, useRef, useState } from 'react'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import DeliveryFeeTable from 'src/components/deliveryFee/deliveryFeeTable'
import handleError from 'src/utils/errorHelper'
import TableActionMenu, {
  TableActionMenuProps,
} from '../../components/table/TableActionMenu'
import DeliveryFormModal, {
  modalDeliveryInterface,
} from 'src/components/deliveryFee/DeliveryFeeFormModal'

const deliveryFeeService = new DeliveryFeeService()

const DeliveryFeePage = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  const refetchRef = useRef<Function>()
  const [modalDeliveryFeeState, setModalPaymentMethodState] =
    useState<modalDeliveryInterface>({
      open: false,
      onSubmit: async () => {},
      formData: null,
    })

  const setModalForCreate = useCallback(() => {
    setModalPaymentMethodState((old) => ({
      ...old,
      open: true,
      title: 'Create Delivery Fee',
      onSubmit: async (data) => {
        try {
          const dataToSubmit = data
          await deliveryFeeService.create(dataToSubmit)
          setModalPaymentMethodState((old) => ({ ...old, open: false }))
          refetchRef.current()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalPaymentMethodState(() => {
          return { ...old, open: false }
        })
      },
      formData: null,
    }))
  }, [refetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const { name, nameKh, fee } = record
      setModalPaymentMethodState((old) => ({
        ...old,
        open: true,
        title: 'Update Delivery Fee',
        onSubmit: async (data) => {
          const dataToSubmit = data
          try {
            await deliveryFeeService.update({
              id: record[getIdentifier()],
              data: dataToSubmit,
            })
            setModalPaymentMethodState((old) => ({
              ...old,
              open: false,
            }))
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },

        onCancel: () => {
          setModalPaymentMethodState({
            ...old,
            formData: null,
            open: false,
          })
        },
        formData: {
          name,
          nameKh,
          fee: fee.value,
        },
      }))
    },
    [refetchRef],
  )

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalCategoryForUpdate(record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await deliveryFeeService.delete(record[getIdentifier()])
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalDeliveryFeeState.open && (
          <DeliveryFormModal {...modalDeliveryFeeState} />
        )}
      </MountedModalProvider>

      <DeliveryFeeTable
        renderOwnActionMenu={(args) => {
          return renderActionMenu(args)
        }}
        renderCreateButton={() => {
          return (
            <Button type="primary" onClick={() => setModalForCreate()}>
              Create
            </Button>
          )
        }}
        refetchRef={refetchRef}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default withChangeHeader(DeliveryFeePage, 'Delivery Fee')
