import React, { useEffect } from 'react'
import MonacoEditor, { MonacoEditorProps } from 'react-monaco-editor'

export interface CodeEditorProps extends MonacoEditorProps {
  [index: string]: any
}

const CodeEditor: React.FC<CodeEditorProps> = (props) => {
  const { ...rest } = props

  return (
    <MonacoEditor
      theme="vs-dark"
      width="800"
      height="600"
      options={{
        automaticLayout: true,
        minimap: { enabled: false },
      }}
      {...rest}
    />
  )
}

export default CodeEditor
