import { DashboardStats } from '@/components'
import { getIdentifier } from '@/utils'
import { DollarCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useCallback, useRef, useState } from 'react'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'

import PaymentMethodService from 'src/services/paymentMethodService'
import handleError from 'src/utils/errorHelper'
import TableActionMenu, {
  TableActionMenuProps,
} from '../../components/table/TableActionMenu'
import PublisherTable from '@/components/publisher/PublisherTable'
import PublisherTableFormModal, {
  modalPubliserInterface,
} from '@/components/publisher/PublisherFormModal'
import PublisherService from '@/services/publisherService'

const publisherService = new PublisherService()

const PublisherPage = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  const refetchRef = useRef<Function>()
  const [modalPublisherState, setModalPublisherState] =
    useState<modalPubliserInterface>({
      open: false,
      onSubmit: async () => {},
      formData: null,
    })

  const setModalForCreate = useCallback(() => {
    setModalPublisherState((old) => ({
      ...old,
      open: true,
      title: 'Create Publisher',
      onSubmit: async (data) => {
        try {
          const dataToSubmit = data
          await publisherService.create(dataToSubmit)
          setModalPublisherState((old) => ({ ...old, open: false }))
          refetchRef.current()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalPublisherState(() => {
          return { ...old, open: false }
        })
      },
      formData: null,
    }))
  }, [refetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const { name, description } = record
      setModalPublisherState((old) => ({
        ...old,
        open: true,
        title: 'Update Publisher',
        onSubmit: async (data) => {
          const dataToSubmit = data
          try {
            await publisherService.update({
              id: record[getIdentifier()],
              data: dataToSubmit,
            })
            setModalPublisherState((old) => ({
              ...old,
              open: false,
            }))
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },

        onCancel: () => {
          setModalPublisherState({
            ...old,
            formData: null,
            open: false,
          })
        },
        formData: {
          name,
          description,
        },
      }))
    },
    [refetchRef],
  )

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalCategoryForUpdate(record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await publisherService.delete(record[getIdentifier()])
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalPublisherState.open && (
          <PublisherTableFormModal {...modalPublisherState} />
        )}
      </MountedModalProvider>

      <PublisherTable
        renderOwnActionMenu={(args) => {
          return renderActionMenu(args)
        }}
        renderCreateButton={() => {
          return (
            <Button type="primary" onClick={() => setModalForCreate()}>
              Create
            </Button>
          )
        }}
        refetchRef={refetchRef}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default withChangeHeader(PublisherPage, 'Publiser')
