import { DashboardStats } from '@/components'
import DashboardCharts from '@/components/DashboardCharts'
import withChangeHeader from '@/components/hoc/withChangeHeader'
import { DashboardChartsModel } from '@/models'
import { BackendDashboardService } from '@/services'
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div``

const service = new BackendDashboardService()

const DashboardPage: React.FC = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  return (
    <Container>
      <DashboardStats
        service={service}
        defaultProps={{}}
        refreshToken={refreshToken}
      />

      <DashboardCharts service={service} refreshToken={refreshToken} />
    </Container>
  )
}

export default withChangeHeader(DashboardPage, 'Dashboard')
