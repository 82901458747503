import React from 'react'
import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

const { TextArea } = Input

interface TextEditorProps extends TextAreaProps {}

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const { ...rest } = props
  return <TextArea {...rest} />
}

export default TextEditor
