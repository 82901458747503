import { useAuthContext } from '@/context/AuthContext'
import handleError from '@/utils/errorHelper'
import LoginPage from '../../components/login'
import { useNavigate, Link } from 'react-router-dom'
import { Button } from 'antd'

const AuthorLogin = () => {
  const { login } = useAuthContext()
  const navigate = useNavigate()

  return (
    <LoginPage
      onSubmit={async (data) => {
        try {
          const dataSubmit = await login({ ...data, isAuthor: true })
          if (dataSubmit !== null) {
            navigate('/author/dashboard')
          }
        } catch (e) {
          handleError(e)
        }
      }}
    >
      <div
        style={{
          textAlign: `center`,
        }}
      >
        <Link to={'/login'}>
          <Button type="link">ចូលជា Admin</Button>
        </Link>
      </div>
    </LoginPage>
  )
}

export default AuthorLogin
