import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
// import { RenderHtml } from "../shared/RenderHtml";
import { useDebounceFn } from 'ahooks'
import { ReloadOutlined } from '@ant-design/icons'
import { momentHumanize } from '@/utils/dateTime'
import Button from '../shared/Button'
import handleError from '@/utils/errorHelper'
import NotificationFetcher from './NotificationFetcher'
import { useGlobalStore } from '@/components/global'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 12px;
  height: 300px;
  overflow: auto;

  &::before {
    display: block;
    content: '';
    height: 24px;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
`

const Layout = styled.div`
  padding: 20px;
  &:hover {
    background: #dddddd;
  }
`

const TitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h4`
  font-size: 15px;
  font-weight: 600;
`

const Description = styled.h6`
  font-weight: 600;
  font-size: 12px;
  margin: 0;
`

const Status = styled.p`
  font-size: 12px;
  color: gray;
`

const NotificationsList: React.FC<{}> = (props) => {
  const { notification: data } = useGlobalStore()

  const handleRefresh = async () => {}

  const { run: seen } = useDebounceFn(
    async () => {
      try {
      } catch (e) {
        handleError(e)
      }
    },
    {
      wait: 1000,
    },
  )

  const renderAllNotificationContent = () => {
    let result: any

    if (data.length !== 0) {
      result = (
        <>
          {data.map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Layout>
                  <TitleLayout>
                    {item.isSeen === false ? (
                      <Title style={{ fontWeight: 700 }}>{item.description}</Title>
                    ) : (
                      <Title>{item.description}</Title>
                    )}
                    <Status>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                        }}
                      >
                        {momentHumanize({
                          value: item.date,
                        })}
                        {item.isSeen === false && (
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: `#1890ff`,
                              borderRadius: '50%',
                            }}
                          ></div>
                        )}
                      </div>
                    </Status>
                  </TitleLayout>
                  <div></div>
                  <Description dangerouslySetInnerHTML={{ __html: item.titleWeb }} />
                </Layout>
              </React.Fragment>
            )
          })}
        </>
      )
    }

    return result
  }

  return (
    <Container>
      <div
        style={{
          backgroundColor: 'transparent',
          paddingLeft: 290,
          borderBottom: 'none',
          position: 'fixed',
          marginTop: '-17px',
        }}
      >
        <Button size={'small'} onClick={handleRefresh}>
          <ReloadOutlined /> Refresh
        </Button>
      </div>
      {renderAllNotificationContent()}
      <NotificationFetcher />
      {data.length === 0 && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Empty
        </div>
      )}
      <br />
    </Container>
  )
}

export default NotificationsList
