import { getAxiosConfig } from '@/utils'
import { PaginationDataReponse, PaginationRequestParams } from './baseService'

const url = '/report'

class ReportService {
  async getAllReportDeposit(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(`${url}/deposit`, {
      params,
    })
    return res.data as any
  }

  async getAllAuthorReportTransaction(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(`${url}/transaction`, {
      params,
    })
    return res.data as any
  }
}

export default ReportService
