import { useAuthContext } from 'src/context/AuthContext'
import { useState, useEffect } from 'react'
import { useDebounceEffect } from 'ahooks'

interface authorizeObj {
  roles?: string[]
  privileges?: string[]
}

const defaultAuthorizeObj: {
  roles: string[]
  privileges: string[]
} = { roles: [], privileges: [] }

export interface useAuthorizeProps {
  or?: authorizeObj
  and?: authorizeObj
}

type checkAuthorizeArgs = useAuthorizeProps & {
  privileges: string[]
  roles: string[]
}

export const checkAuthorize = (args: checkAuthorizeArgs) => {
  const { privileges, roles } = args

  const and = {
    ...defaultAuthorizeObj,
    ...args.and,
  }

  const or = {
    ...defaultAuthorizeObj,
    ...args.or,
  }

  const passAndPriv =
    and.privileges.length > 0
      ? and.privileges.every((item) => privileges.some((a: string) => a === item))
      : undefined

  const passAndRole =
    and.roles.length > 0
      ? and.roles.every((item) => roles.some((a: string) => a === item))
      : undefined

  const passOrPriv =
    or.privileges.length > 0
      ? or.privileges.some((item) => privileges.some((a: string) => a === item))
      : undefined

  const passOrRole =
    or.roles.length > 0
      ? or.roles.some((item) => {
          let result = roles.some((a: string) => a === item)
          return result
        })
      : undefined

  let andPass
  if (passAndPriv === undefined && passAndRole === undefined) {
    andPass = false
  } else if (passAndPriv !== undefined && passAndRole !== undefined) {
    andPass = passAndPriv && passAndRole
  } else if (passAndRole !== undefined) {
    andPass = passAndRole
  } else if (passAndPriv !== undefined) {
    andPass = passAndPriv
  }

  let orPass
  if (passOrPriv === undefined && passOrRole === undefined) {
    orPass = false
  } else {
    orPass = passOrPriv || passOrRole
  }

  let pass = andPass ? andPass : orPass

  // const allowedRole = 'SUPER_ADMIN'

  //  const passAllowRole = roles.some((item) => {
  //   let result = item === allowedRole

  //   return result
  // })

  // let result = passAllowRole || pass

  let result = pass
  return Boolean(result)
}

export const useAuthorize: (props: useAuthorizeProps) => boolean | undefined = (
  props,
) => {
  const { state } = useAuthContext()

  const privileges = state?.user?.privileges || []
  const roles = state?.user?.roles || []
  const loading = state?.loading

  const [allowAccess, setAllowAccess] = useState<boolean | undefined>(
    checkAuthorize({
      ...props,
      privileges,
      roles,
    }),
  )

  useEffect(() => {
    if (!loading) {
      let result = checkAuthorize({
        ...props,
        privileges,
        roles,
      })

      setAllowAccess(result)
    }
  }, [privileges, roles, loading, props.and, props.or])

  return allowAccess
}
