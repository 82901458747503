import { getAxiosConfig } from '@/utils'

const url = '/other/enum_type'

export enum EnumType {
  BOOK_SUBSCRIPTION_DURATION = 'BOOK_SUBSCRIPTION_DURATION',
  BOOK_SHELF_READING_STATUS = 'BOOK_SHELF_READING_STATUS',
  SALUTATION = 'SALUTATION',
}

class EnumService {
  async getByType(enumType: EnumType) {
    const res = await getAxiosConfig({
      baseURL: process.env.REACT_APP_SERVER_URL + '/api',
      headers: undefined
    }).get(`${url}`, {
      params: {
        type: enumType,
      },
    })
    return res.data as any
  }
}

export default EnumService
