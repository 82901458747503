import { AxiosRequestConfig } from 'axios'
import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  DataResponse,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'
import { AbstractDashboardStatsService } from './DashboardStatsService'

export interface FormData { }

const url = '/book'

class BookService extends AbstractDashboardStatsService implements BaseService<FormData>, CRUDService<FormData> {
  getUrl(): string {
    return url
  }

  async create(data: FormData): Promise<any> {
    const res = await getAxiosConfig().post(url, data)
    return res.data
  }

  update(args: { id: any; data: FormData }): Promise<void> {
    const { id, data } = args

    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    const res = await getAxiosConfig().delete(`${url}/${id}`)
    return res.data as any
  }

  getTrashed(): Promise<PaginationDataReponse<FormData>> {
    throw new Error('Method not implemented.')
  }

  restore(id: any): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async get(id: any): Promise<any> {
    const res = await getAxiosConfig().get(`${url}/${id}`)
    return res.data as any
  }

  async updateBookCover(id: any, data: { file: any }): Promise<DataResponse<any>> {
    const bodyFormData = new FormData()
    const { file } = data
    bodyFormData.append('file', file)
    const res = await getAxiosConfig().patch(`${url}/bookcover/${id}`, bodyFormData)
    return res.data
  }

  async generatePdf(
    id: any,
    data: { file: any; axiosOption?: AxiosRequestConfig },
  ): Promise<any> {
    const bodyFormData = new FormData()
    const { file, axiosOption } = data
    bodyFormData.append('file', file)

    return getAxiosConfig({
      timeout: 100000,
      ...axiosOption,
    }).post(`${url}/pdf/${id}`, bodyFormData)
  }

  async publishBook(id: any, data: { published: boolean }): Promise<any> {
    return getAxiosConfig().patch(`${url}/publish/${id}`, null, {
      params: data,
    })
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<FormData>> {
    const res = await getAxiosConfig().get(this.getUrl(), {
      params,
    })
    return res.data as any
  }
}

export default BookService
