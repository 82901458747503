import React, { createContext, useState } from 'react'

export interface BaseLayoutHeaderContextState {
  headerLabel: React.ReactNode
  setHeaderLabel: React.Dispatch<React.SetStateAction<string>>
}

export const BaseLayoutHeaderContext = createContext<BaseLayoutHeaderContextState>({
  headerLabel: 'Administrator',
  setHeaderLabel: () => {},
})

type props = {
  children: React.ReactNode
}

export const BaseLayoutHeaderProvider = ({ children }: props) => {
  const [headerLabel, setHeaderLabel] = useState('Administrator')
  const contextState: BaseLayoutHeaderContextState = {
    headerLabel,
    setHeaderLabel,
  }

  return (
    <BaseLayoutHeaderContext.Provider value={contextState}>
      {children}
    </BaseLayoutHeaderContext.Provider>
  )
}
