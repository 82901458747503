import { DraggableModalProps } from 'ant-design-draggable-modal'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Space, Form } from 'antd'
import ModalFormContainer, { ModalFooter } from '../form/ModalForm'
import styled from 'styled-components'

interface FormData {
  name: string
  description: string
}

export interface modalPubliserInterface extends DraggableModalProps {
  onSubmit: (data: FormData) => Promise<void>
  formData: FormData
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
})

const defaultValues: FormData = {
  name: '',
  description: '',
}

const StyledForm = styled(Form)``

const layout: any = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
}

const PublisherTableFormModal: React.FC<modalPubliserInterface> = (props) => {
  const { onSubmit, onOk, formData, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset({
      ...defaultValues,
      ...formData,
    })
  }, [formData])

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={500}
      initialWidth={450}
      footer={null}
    >
      <StyledForm {...layout}>
        <Form.Item
          label="Name"
          validateStatus={errors.name && 'error'}
          help={errors.name ? errors.name.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
              )
            }}
            name="name"
            control={control}
          />
        </Form.Item>

        <Form.Item
          label="Description"
          validateStatus={errors.description && 'error'}
          help={errors.description ? errors.description.message : ''}
          requiredMark={true}
        >
          <Controller
            render={({ field }) => <Input.TextArea {...field} />}
            name="description"
            control={control}
          />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </StyledForm>
    </ModalFormContainer>
  )
}

export default PublisherTableFormModal
