import { getIdentifier, getSwrDefaultConfig } from '@/utils'
import { Avatar, Button, Space } from 'antd'
import Table, {
  newColumnsInterface,
  TableSkeleton,
} from '@cubetiq/enhance-antd-table'
import React, { useCallback, useEffect, useState } from 'react'
import { defaultPaginationRequestParams } from 'src/constants'
import AuthorService from 'src/services/authorService'
import {
  PaginationDataReponse,
  PaginationRequestParams,
} from 'src/services/baseService'
import handleError from 'src/utils/errorHelper'
import { getPaginationProps } from 'src/utils/tableHelper'
import useSWR from 'swr'
import BodyContainer from '../other/bodyContainer'
import BoldLabelValue from '../other/text'
import TableActionMenu, { TableActionMenuProps } from '../table/TableActionMenu'
import { CheckOutlinedIcon, CloseOutlinedIcon } from '../other/TextVerifyIcon'

const columns: newColumnsInterface[] = [
  {
    title: 'Profile',
    dataIndex: 'avatar',
    render: (_, { avatar }) => {
      return avatar && <Avatar src={avatar} />
    },
  },
  {
    title: 'Salutation',
    dataIndex: 'salutation',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
  },
  {
    title: 'Bio',
    dataIndex: 'bio',
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Has Password',
    dataIndex: 'hasPassword',
    render: (value) => {
      return value ? (
        <CheckOutlinedIcon title={'Has Password'} />
      ) : (
        <CloseOutlinedIcon title={'No Password'} />
      )
    },
  },
]

const excludeColumns = ['salutation', 'memo', 'bio', 'username']

const defaultVisibleColumns = columns
  .filter((item) => !excludeColumns.includes(item.dataIndex))
  .map((item) => item.dataIndex)

const authorService = new AuthorService()

interface TableProps {
  renderCreateButton: () => React.ReactNode
  renderOwnActionMenu: (args: {
    record: any
    mutate: Function
    data: any[]
  }) => React.ReactNode
  refetchRef: React.MutableRefObject<Function>
  onRefresh?: () => void
}

export const tableReqKey = `author-req`

const AuthorTable = (props: TableProps) => {
  const { onRefresh } = props

  const { renderOwnActionMenu, refetchRef } = props
  const { data, error, mutate } = useSWR<PaginationDataReponse<any[]>, any>(
    tableReqKey,
    null,
    getSwrDefaultConfig(),
  )

  const [showTrashed] = useState(false)

  const [reqParams, setReqParams] = useState<PaginationRequestParams>(
    defaultPaginationRequestParams,
  )

  const refetch = useCallback(() => {
    const getData = async () => {
      try {
        let res: any = showTrashed
          ? await authorService.getTrashed(reqParams)
          : await authorService.getAll(reqParams)
        res.data && mutate(showTrashed ? res.data.content : res)
      } catch (e) {
        handleError(e)
      }
    }

    getData()
  }, [showTrashed, reqParams])

  useEffect(() => {
    refetchRef.current = refetch
  }, [])

  useEffect(() => {
    refetch()
  }, [showTrashed, reqParams])

  const renderTrashedTableActionMenu = useCallback(
    (record: any) => {
      let menus: TableActionMenuProps['menus'] = [
        {
          render: () => {
            return <div>Restore</div>
          },
          key: 'Restore',
          onClick: async () => {
            try {
              await authorService.restore(record.record[getIdentifier()])
              refreshAction()
            } catch (e) {
              handleError(e)
            }
          },
        },
      ]

      return <TableActionMenu menus={menus} />
    },
    [showTrashed],
  )

  if (error) {
    return <div>error</div>
  }

  const refreshAction = () => {
    refetch()
    onRefresh && onRefresh?.()
  }

  return (
    <BodyContainer style={{ padding: 20 }}>
      <TableSkeleton loading={!data}>
        <Table
          restProps={{
            rowKey: 'id',
            bordered: true,
            pagination: getPaginationProps(data?.pages, async (page, pageSize) => {
              setReqParams({
                page,
                size: pageSize,
              })
            }),
            expandable: {
              rowExpandable: ({ socialLinks }) => {
                return socialLinks.length > 0
              },
              expandedRowRender: ({ socialLinks }) => {
                const render = () => {
                  return socialLinks.map((item: { type: string; link: string }) => (
                    <BoldLabelValue key={item.type} title={item.type}>
                      {item.link}
                    </BoldLabelValue>
                  ))
                }

                return (
                  <>
                    <h3>Social Links</h3>

                    {render().slice(0, 2)}
                  </>
                )
              },
            },
          }}
          columnsVisibleControllerProps={{
            show: true,
          }}
          defaultVisibleColumns={defaultVisibleColumns}
          renderCreateButton={() => {
            return (
              <Space>
                {props.renderCreateButton()}
                <Button
                  onClick={() => {
                    refreshAction()
                  }}
                >
                  Refresh
                </Button>
              </Space>
            )
          }}
          newColumns={columns}
          newSources={data ? data.data : []}
          name={tableReqKey}
          renderOwnActionMenu={(record) => {
            return showTrashed
              ? renderTrashedTableActionMenu(record)
              : renderOwnActionMenu({ record, mutate, data: data.data })
          }}
          renderOwnSearchInput={() => {
            return <React.Fragment />
          }}
          // renderOwnSearchInput={() => (
          //   <Search
          //     enterButton
          //     style={{
          //       width: 350,
          //     }}
          //     loading={!data}
          //     onSearch={(value) => {
          //       setReqParams((old) => ({
          //         ...old,
          //         q: value,
          //       }))
          //     }}
          //   />
          // )}
        />
      </TableSkeleton>
    </BodyContainer>
  )
}

export default AuthorTable
