import create from 'zustand'

interface RouterState {
  routes: any[]
  setRoutes: (routes: any[]) => void
}

const useRouterStore = create<RouterState>((set) => ({
  routes: [],
  setRoutes: (routes: any[]) => {
    set({ routes })
  },
}))

export default useRouterStore
