import { Statistic } from '@/components'
import { DashboardStatsModel } from '@/models'
import { DashboardStatsService } from '@/services'
import { breakpointScreen, maxLengthCol } from '@/utils'
import { Col, Row } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconLoader } from '../IconLoader'

export interface DashboardStatsProps {
  data?: DashboardStatsModel
  service?: DashboardStatsService
  params?: any
  defaultProps?: {
    title?: string | null
    color?: string | null
    precision?: number | null
    prefix?: ReactNode | null
    suffix?: ReactNode | null
  }
  refreshToken?: string | null
}

const CustomRow = styled(Row)``

const CustomCol = styled(Col)`
  @media (max-width: 1200px) {
    padding: 5px;
  }
`

export const DashboardStats: React.FC<DashboardStatsProps> = (props) => {
  const {
    data = {},
    service,
    params,
    defaultProps: statisticProps,
    refreshToken = undefined,
  } = props
  const [model, setModel] = useState<DashboardStatsModel>(data)

  useEffect(() => {
    if (service) {
      service.getStats(params).then((resp) => {
        setModel(resp || data)
      })
    }
  }, [refreshToken])

  const prefixResolver = (icon: string | null) => {
    if (!icon) return null
    return <IconLoader icon={icon} />
  }

  return (
    <CustomRow gutter={16}>
      {model.stats?.map((stats) => {
        return (
          <CustomCol
            span={breakpointScreen(model.stats?.length)}
            xxl={breakpointScreen(model.stats?.length, { maxCol: 8 })}
            xl={breakpointScreen(model.stats?.length, { maxCol: 6 })}
            lg={breakpointScreen(model.stats?.length, { maxCol: 3 })}
            md={breakpointScreen(model.stats?.length, { maxCol: 2 })}
            sm={breakpointScreen(model.stats?.length, { maxCol: 2 })}
            xs={breakpointScreen(model.stats?.length, { maxCol: 1 })}
            key={`col-${stats.type || ''}`}
          >
            <Statistic
              key={`card-${stats.type || ''}`}
              title={stats.title || stats.type || statisticProps?.title || ''}
              value={stats.amount || 0}
              precision={stats.precision || statisticProps?.precision || 0}
              valueStyle={{
                color: stats.color || statisticProps?.color || '#219fff',
              }}
              prefix={
                prefixResolver(stats.icon) || stats.prefix || statisticProps?.prefix
              }
              suffix={stats.suffix || statisticProps?.suffix}
            />
          </CustomCol>
        )
      })}
    </CustomRow>
  )
}
