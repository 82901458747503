const RouteTypes = {
  HOME: '/',
  RESET: '/reset',
  ADMIN_USER: '/users',
  CONFIGURATION: '/configurations',
  DASHBOARD: '/dashboard',
  ROLE: '/roles',
  PERMISSION: '/permissions',
  // Auth
  LOGIN: '/login',

  // Errors
  ERROR_404: '*',
}

export { RouteTypes }
