import { yupResolver } from '@hookform/resolvers/yup'
import { DraggableModalProps } from 'ant-design-draggable-modal'
import { Button, Space, Form, Input } from 'antd'
import React from 'react'
import ModalFormContainer, { ModalFooter } from 'src/components/form/ModalForm'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import StyledErrorMessage from '../form/StyledErrorMessage'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

export interface modalChangePasswordInterface extends DraggableModalProps {
  onSubmit: (data: any) => Promise<void>
  onCancel: (e: any) => void
}

interface FormData {
  password: string
  confirm: string
}

const defaultValues: FormData = {
  password: '',
  confirm: '',
}

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirm: yup.string().oneOf([yup.ref('password')], 'Password must match'),
})

const ChangeCustomerPasswordForm: React.FC<modalChangePasswordInterface> = (
  props,
) => {
  const { onSubmit, onOk, ...rest } = props
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  return (
    <ModalFormContainer
      {...rest}
      initialHeight={350}
      initialWidth={400}
      footer={null}
    >
      <Form {...layout}>
        <Form.Item label="Password" requiredMark>
          <Controller
            render={({ field }) => <Input.Password {...field} />}
            name="password"
            control={control}
          />
          <StyledErrorMessage errors={errors} name={'password'} />
        </Form.Item>

        <Form.Item label="Confirm Password" requiredMark>
          <Controller
            render={({ field }) => <Input.Password {...field} />}
            name="confirm"
            control={control}
          />
          <StyledErrorMessage errors={errors} name={'confirm'} />
        </Form.Item>

        <ModalFooter>
          <Space>
            <Button
              type={'primary'}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data)
              })}
            >
              Submit
            </Button>

            <Button
              onClick={(e) => {
                props.onCancel(e)
              }}
            >
              Cancel
            </Button>
          </Space>
        </ModalFooter>
      </Form>
    </ModalFormContainer>
  )
}

export default ChangeCustomerPasswordForm
