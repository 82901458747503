import { DraggableModalProvider } from 'ant-design-draggable-modal'
import React, { useState, useEffect } from 'react'

interface MountedModalProviderProps {
  shouldMounted: boolean
}

const MountedModalProvider: React.FC<React.PropsWithChildren<
  MountedModalProviderProps
>> = (props) => {
  const { shouldMounted } = props

  return shouldMounted ? (
    <DraggableModalProvider>{props.children}</DraggableModalProvider>
  ) : null
}

export default MountedModalProvider
