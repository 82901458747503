import React from 'react'
import BodyContainer from './bodyContainer'
import { Divider } from 'antd'
import styled from 'styled-components'

interface Props {
  headerText?: React.ReactNode
}

export const FormPageHeaderText = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`

const FormPageContainer: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { headerText } = props

  return (
    <BodyContainer style={{ padding: '6px 0' }}>
      {headerText && (
        <>
          <div style={{ paddingLeft: 16 }}>{headerText}</div>
          <Divider style={{ margin: '18px 0' }} />
        </>
      )}

      <div style={{ padding: '0 16px' }}>{props.children}</div>
    </BodyContainer>
  )
}

export default FormPageContainer
