import { useInViewport } from 'ahooks'
import React, { useEffect, useRef, useState } from 'react'

const useInViewportFn = (args: {
  onInViewPort: (args: {
    inViewPort?: boolean
    setChildren: React.Dispatch<React.SetStateAction<React.ReactNode>>
  }) => void
}) => {
  const ref = useRef(null)
  const [inViewPort] = useInViewport(ref)
  const { onInViewPort } = args
  const [children, setChildren] = useState<React.ReactNode>()

  useEffect(() => {
    onInViewPort({
      setChildren,
      inViewPort,
    })
  }, [inViewPort])

  return {
    component: <div ref={ref}>{children}</div>,
    setChildren,
  }
}

export default useInViewportFn
