import { useAuthContext } from '@/context/AuthContext'
import handleError from '@/utils/errorHelper'
import { Button } from 'antd'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import LoginPage from '../../components/login'

const MainLogin = () => {
  const { login } = useAuthContext()
  const navigate = useNavigate()

  return (
    <LoginPage
      onSubmit={async (data) => {
        try {
          const dataSubmit = await login({ ...data })
          if (dataSubmit !== null) {
            navigate('/')
          }
        } catch (e) {
          handleError(e)
        }
      }}
    >
      <div
        style={{
          textAlign: `center`,
        }}
      >
        <Link to={'/author/login'}>
          <Button type="link">ចូលជា Author</Button>
        </Link>
      </div>
    </LoginPage>
  )
}

export default MainLogin
