import MountedModalProvider from '@/components/modal/MountedModalProvider'
import NotificationModal, {
  modalNotification,
} from '@/components/notification/NotificationModal'
import NotificationOfferTable from '@/components/notification/NotificationOfferTable'
import TableActionMenu, {
  TableActionMenuProps,
} from '@/components/table/TableActionMenu'
import NotificationService, {
  NotificationProps,
} from '@/services/notificationService'
import handleError from '@/utils/errorHelper'
import { Button } from 'antd'
import { useCallback, useRef, useState } from 'react'
import withChangeHeader from 'src/components/hoc/withChangeHeader'

const notificationService = new NotificationService()

const NotificationOfferPage = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  const refetchRef = useRef<Function>()
  const [modalNotificationState, setModalNotificationState] =
    useState<modalNotification>({
      open: false,
      onSubmit: async () => {},
      formData: null,
    })
  const setModalForCreate = useCallback(() => {
    setModalNotificationState((old) => ({
      ...old,
      open: true,
      title: 'Create Notification Offer',
      onSubmit: async (data: any) => {
        try {
          await notificationService.createOffer(data)
          setModalNotificationState((old) => ({ ...old, open: false }))
          refetchRef.current()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalNotificationState(() => {
          return { ...old, open: false }
        })
      },
      formData: null,
    }))
  }, [refetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const { topic, notificationType, description } = record
      setModalNotificationState((old: any) => ({
        ...old,
        open: true,
        title: 'Update Notification Offer',
        onSubmit: async (data) => {
          console.log('data edit: ', data, record)
          try {
            // await notificationService.updateOffer(record.id,data)
            setModalNotificationState((old) => ({
              ...old,
              open: false,
            }))
            refetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
        onCancel: () => {
          setModalNotificationState({
            ...old,
            formData: null,
            open: false,
          })
        },
        formData: {
          topic: topic,
          notificationType: notificationType,
          description: description,
        },
      }))
    },
    [refetchRef],
  )

  const renderActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalCategoryForUpdate(record)
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  return (
    <>
      <MountedModalProvider shouldMounted={true}>
        {modalNotificationState.open && (
          <NotificationModal {...modalNotificationState} />
        )}
      </MountedModalProvider>

      <NotificationOfferTable
        renderOwnActionMenu={(args) => {
          return renderActionMenu(args)
        }}
        renderCreateButton={() => {
          return (
            <Button type="primary" onClick={() => setModalForCreate()}>
              Create
            </Button>
          )
        }}
        refetchRef={refetchRef}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default withChangeHeader(NotificationOfferPage, 'Notification Offer')
