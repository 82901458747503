import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

export interface FormData {}

const url = '/configuration'

class ConfigurationService implements BaseService<FormData>, CRUDService<FormData> {
  get(id: any): Promise<FormData> {
    throw new Error('Method not implemented.')
  }

  async getTrashed(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<FormData>> {
    const res = await getAxiosConfig().get(`${url}/all/trash`, {
      params,
    })
    return res.data as any
  }

  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/${id}`)
  }

  async create(data: FormData): Promise<any> {
    const res = await getAxiosConfig().post(url, data)
    return res.data
  }

  update(args: { id: any; data: FormData }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    const res = await getAxiosConfig().delete(`${url}/${id}`)
    return res.data as any
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<FormData>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }
}

export default ConfigurationService
