import { DashboardStatsModel } from '@/models';
import { getAxiosConfig } from '@/utils';
import { BaseApiService } from './BaseApiService';

export interface DashboardStatsService extends BaseApiService {
    getStats(params?: any): Promise<DashboardStatsModel>;
}

export abstract class AbstractDashboardStatsService implements DashboardStatsService {
    constructor() { }

    abstract getUrl(): string;

    async getStats(
        params?: any
    ): Promise<DashboardStatsModel> {
        let url = this.getUrl();
        if (!url.endsWith('/')) {
            url = `${url}/`;
        }

        const res = await getAxiosConfig().get(`${url}stats`, {
            params,
        });
        return res?.data?.data;
    }
}
