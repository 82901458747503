import { Space } from 'antd'
import React, { CSSProperties } from 'react'

const TableHeader: React.FC<React.PropsWithChildren<{
  style?: CSSProperties
}>> = (props) => {
  return <Space {...props}>{props.children}</Space>
}

export default TableHeader
