import { getAxiosConfig } from '@/utils'
import BaseService, {
  CRUDService,
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'
import { AbstractDashboardStatsService } from './DashboardStatsService'

const url = '/payment-method'

interface DataSubmit {
  name: string
  paymentType: string
  properties: {
    [index: string]: any
  }
}

class PaymentMethodService extends AbstractDashboardStatsService implements BaseService<{}>, CRUDService<{}> {
  getUrl(): string {
    return url
  }

  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<{}>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  create(data: DataSubmit): Promise<void> {
    return getAxiosConfig().post(url, data)
  }

  update(args: { id: any; data: DataSubmit }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }

  async getTrashed(params?: PaginationRequestParams) {
    const res = await getAxiosConfig().get(`${url}/trash/all`, {
      params,
    })
    return res.data as any
  }

  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/trash/${id}`)
  }
}

export default PaymentMethodService
