import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface TempState {
  error: any
  setError: (error: any) => void

  loading: boolean
  setLoading: (loading: boolean) => void
}

const useTempStore = create<TempState>()(
  devtools((set) => ({
    loading: true,
    setLoading: (loading: boolean) => {
      set({
        loading,
      })
    },
    error: null,
    setError: (error: any) => {
      set({
        error,
      })
    },
  })),
)

export default useTempStore
