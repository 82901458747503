import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type ThemeType = 'light' | 'dark'
type MenuModeType = 'vertical' | 'inline'

interface SiteState {
  theme: ThemeType
  setTheme: (theme: ThemeType) => void
  menuMode: MenuModeType
  setMenuMode: (mode: MenuModeType) => void
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

const useSiteStore = create<SiteState>()(
  devtools(
    persist(
      (set) => ({
        theme: 'dark',
        setTheme: (theme: ThemeType) => set((state) => ({ ...state, theme })),
        menuMode: 'vertical',
        setMenuMode: (mode: MenuModeType) => set((state) => ({ ...state, mode })),
        collapsed: false,
        setCollapsed: (collapsed: boolean) =>
          set((state) => ({ ...state, collapsed })),
      }),
      {
        name: 'site-config',
      },
    ),
  ),
)

export default useSiteStore
