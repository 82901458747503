import { getAxiosConfig } from '@/utils'
import BaseService, {
  PaginationDataReponse,
  PaginationRequestParams,
} from './baseService'

const url = '/publisher'

export interface PublisherDataSubmit {
  name: string
  description: string
}

class PublisherService implements BaseService<{}> {
  get(id: any): Promise<{}> {
    throw new Error('Method not implemented.')
  }

  async getAll(
    params?: PaginationRequestParams,
  ): Promise<PaginationDataReponse<any>> {
    const res = await getAxiosConfig().get(url, {
      params,
    })
    return res.data as any
  }

  create(data: PublisherDataSubmit): Promise<void> {
    return getAxiosConfig().post(url, data)
  }

  update(args: { id: any; data: PublisherDataSubmit }): Promise<void> {
    const { id, data } = args
    return getAxiosConfig().put(`${url}/${id}`, data)
  }

  async delete(id: any): Promise<void> {
    return getAxiosConfig().delete(`${url}/${id}`)
  }

  async getTrashed(params?: PaginationRequestParams) {
    const res = await getAxiosConfig().get(`${url}/trash/all`, {
      params,
    })
    return res.data as any
  }

  restore(id: any): Promise<void> {
    return getAxiosConfig().post(`${url}/trash/${id}`)
  }
}

export default PublisherService
