import { PaginationInterface } from 'src/services/baseService'

export const getPaginationProps = (
  props: PaginationInterface = {
    pageSize: 0,
    page: 0,
    totalCount: 0,
    totalPage: 0,
  },
  onChange?: (page: number, pageSize: number) => void,
) => {
  return {
    showSizeChanger: true,
    total: props.totalCount,
    onChange: (page: number, pageSize: number) =>
      onChange && onChange(page - 1, pageSize),
  }
}

export const hasMore = (
    args: { page: number; pageSize?: number; totalCount?: number; totalPage: number } = {
        page: 0,
        totalPage: 0
    }
) => {
    const { totalPage, page } = args;
    const result =  page + 1 < totalPage;

    
    return result
};