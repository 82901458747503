import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import styled from 'styled-components'

interface StyledErrorMessageProps {
  errors: { [index: string]: any }
  name: string
}

const RedMessage = styled.p`
  color: red;
  margin-bottom: 0;
  margin-top: 8px;
`

const StyledErrorMessage: React.FC<StyledErrorMessageProps> = (props) => {
  return (
    <ErrorMessage
      {...props}
      render={({ message }) => {
        console.log(message)
        return <RedMessage>{message}</RedMessage>
      }}
    />
  )
}

export default StyledErrorMessage
